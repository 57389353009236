import * as React from "react";
import { AccordionPanel, Checkbox, HStack, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import useSearchContext from "../../../hooks/useSearchContext";

type AvailableFilterStats = [string, number];

const TRANSLATABLE_PREFIX = "t_";

export const FilterPanel: React.FC<any> = (props: { name: string, availableFilters: AvailableFilterStats[] }) => {

  const {t} = useTranslation();
  const {filterBy, removeFilterBy, isFetching, isLoading, isFilterApplied} = useSearchContext();

  const handleToggleFilter = (evt: React.ChangeEvent<HTMLInputElement>, param: string, value: string) => {
    if(evt.target.checked) {
      filterBy(param, value);
    } else {
      removeFilterBy(param, value);
    }
  }

  return (
    <AccordionPanel pb={4}>
      {props.availableFilters.map((f: AvailableFilterStats, i: number) =>
        <HStack key={i} textAlign="right">
          <Checkbox
            iconColor="black"
            isChecked={isFilterApplied(props.name, f[0])}
            isDisabled={isFetching || isLoading}
            onChange={(evt) => handleToggleFilter(evt, props.name, f[0])}>{f[0].startsWith(TRANSLATABLE_PREFIX) ? t(f[0]) : f[0]}</Checkbox>
          <Text fontSize="xs" color="gray.500">({f[1]})</Text>
        </HStack>)
      }
    </AccordionPanel>
  )
}