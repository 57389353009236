import {WireProps} from "../index";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import * as d3 from "d3";
import {useBoardContext} from "../context/BoardContext";
import Utils from "../../../../util/utils";
import {LABEL_FONT_SIZE, LABEL_PADDING_X, LABEL_PADDING_Y, TEXT_HEIGHT, WIRE_STROKE_WIDTH} from "../constants";

const generator = d3.line();

const WireComponent = ({
                          id,
                          sourceX,
                          sourceY,
                          targetX,
                          targetY,
                          label,
                          onClick
                       }: WireProps) => {

   const ref = useRef<SVGGElement>(null);
   const textRef = useRef<SVGTextElement>(null);
   const [textWidth, setTextWidth] = useState(LABEL_PADDING_X);

   const onTextWidthChange = useCallback(() => setTextWidth((textRef.current?.getBBox().width || 0) + LABEL_PADDING_X), [textRef]);

   // keep svg text width actual
   useEffect(() => {

      const resizeObserver = new ResizeObserver(onTextWidthChange)

      if (textRef.current) {
         resizeObserver.observe(textRef.current)
      }

      return () => {
         resizeObserver.disconnect();
      }
   }, [textRef, onTextWidthChange])

   const {
      color,
      bgColor
   } = useBoardContext();

   const pathCenterX = useMemo(() => (sourceX + targetX) / 2, [sourceX, targetX]);
   const pathCenterY = useMemo(() => (sourceY + targetY) / 2, [sourceY, targetY]);

   const rotation = useMemo(() => Utils.rad2deg(Math.atan((targetY - sourceY) / (targetX - sourceX))), [sourceX, targetX, sourceY, targetY]);
   const path = useMemo(() => generator([[sourceX, sourceY], [targetX, targetY]]) || undefined, [sourceX, targetX, sourceY, targetY]);

   const transformOrigin = useMemo(() => `${pathCenterX}px ${pathCenterY}px`, [pathCenterX, pathCenterY]);

   return useMemo(() =>
         <g id={String(id)} ref={ref}>
            <path d={path}
                  markerStart={"url(#arrow-start)"}
                  markerEnd={"url(#arrow-end)"}
                  style={{
                     fill: "none",
                     stroke: `${color}`,
                     strokeWidth: WIRE_STROKE_WIDTH
                  }}></path>
            {label && <>
               <rect rx={5} ry={5} fill={`${bgColor || "#fff"}`}
                     x={(pathCenterX - textWidth / 2)}
                     y={(pathCenterY - TEXT_HEIGHT / 2)}
                     width={`${textWidth}px`}
                     height={`${TEXT_HEIGHT}px`}
                     transform={`rotate(${rotation})`}
                     style={{transformOrigin: transformOrigin}}

               />
               <text ref={textRef}
                     cursor={"pointer"}
                     onClick={() => onClick(id)}
                     x={(pathCenterX)}
                     y={(pathCenterY + LABEL_PADDING_Y)}
                     fontSize={`${LABEL_FONT_SIZE}px`}
                     fill={`${color}`} textAnchor="middle"
                     transform={`rotate(${rotation})`}
                     style={{
                        transformOrigin: transformOrigin,
                        textTransform: "uppercase"
                     }}
               >{label}</text>
            </>
            }
         </g>, [pathCenterX, pathCenterY, transformOrigin, label, color, rotation, textWidth, path, textRef, bgColor, id])
};

export default WireComponent;

