/**
 * Theme extensions should follow the structure given by the default theme.
 *
 * See https://chakra-ui.com/docs/styled-system/theme;
 * and https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/foundations
 *
 * @author Otavio Biasutti, PRODYNA AG
 * @since 2022-07-19
 */
import Badge from "./badge";
import Form from "./form";
import Button from "./button";
import Link from "./link";
import Input from "./input";
import Tabs from "./tabs";
import Menu from "./menu";

const components = {
  Menu,
  Badge,
  Form,
  Button,
  Link,
  Input,
  Tabs,
};

export { components };