import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, IconButton, Link } from "@chakra-ui/react";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import * as React from "react";

export const ExternalLinks = (props: { hideHome?: boolean }) => {
  const {t} = useTranslation();
  return <ButtonGroup alignItems={"center"} isAttached variant={"ghost"} size={["sm", "sm", "lg", "lg", "lg"]}>
    {!props.hideHome &&
    <Button as={Link} aria-label={t("Labirinto")} title={t("Labirinto")} fontWeight={"thin"} letterSpacing={4}
            href={"https://www.labirinto.art/"}>LABIRINTO</Button>}
    <IconButton as={Link} target={"_blank"} aria-label={"Twitter"} title={"Twitter"} icon={<BsTwitter/>}
                href={"https://twitter.com/art_labirinto"}/>
    <IconButton as={Link} target={"_blank"} aria-label={"Instagram"} title={"Instagram"} icon={<BsInstagram/>}
                href={"https://www.instagram.com/art_labirinto/"}/>
  </ButtonGroup>;
};