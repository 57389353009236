import { Text, Center, CircularProgress } from '@chakra-ui/react'
import React from 'react'
import { useAuth } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { UserState } from '../../../types';

export const OAuth2Callback: React.FC<any> = (props) => {
    const navigate = useNavigate();
    const auth = useAuth();
 
    if (auth.isAuthenticated && auth.user) {
        var nextPage = '/workspace';
        if (auth.user.state) {
            const { targetPage } = auth.user.state as UserState;
            nextPage = targetPage;
        }
        setTimeout(()=> {
            navigate(nextPage);
        }, 0);
        return <></>
    }

    if (auth.error) {
        return <Text>{auth.error.message}</Text>
    }

    return <Center height="100vh" width="100%"><CircularProgress isIndeterminate/></Center>
  }