import {createSearchParams, useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import { AnyAsset, AssetType } from "../../types";
import { SearchScope } from "../../api";
import Utils from "../../util/utils";

/**
 * Helps navigating to the correct route based on the SearchScope, AssetType and eventually selected GuardedAsset.
 */
const useNavigation = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const {searchScopePath, assetTypePath, uuid} = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  const isHome = location.pathname === "" || location.pathname === "/";

  const toScopeUrl = (scope: SearchScope | undefined, preserveSearchParams: boolean | undefined) => {
    const paths = [];
    const params = preserveSearchParams && searchParams.toString();

    paths.push(Utils.searchScopeToPath(scope || "DEFAULT"));
    paths.push(params && "?" + params);

    return "/" + paths.filter(p => p).join("/");
  };

  const navigateToScope = (scope?: SearchScope, preserveSearchParams?: boolean) => {
    const url = toScopeUrl(scope, preserveSearchParams);
    navigate(url);
  }

  const navigateToQuery = (query: string | undefined) => {

    const paths = [];

    paths.push(searchScopePath || Utils.searchScopeToPath("DEFAULT"));
    paths.push(query && ("?query=" + query));
    navigate("/" + paths.filter(p => p).join("/"));
  }

  const resetQueryNavigation = () => {
    navigateToQuery("");
  }

  const resetAssetTypeNavigation = () => {
    searchParams.delete("pageNumber");
    searchParams.delete("pageSize");
    navigateToScope(Utils.pathToSearchScope(searchScopePath) || "DEFAULT", true);
  }

  const navigateToAssetType = (type: AssetType, preserveSearchParams?: boolean) => {

    const paths = [];

    searchParams.delete("pageNumber");
    searchParams.delete("pageSize");

    const params = preserveSearchParams && searchParams.toString();

    paths.push(searchScopePath || Utils.searchScopeToPath("DEFAULT"));
    paths.push(Utils.assetTypeToPath(type));
    paths.push(params && "?" + params);

    navigate("/" + paths.filter(p => p).join("/"));
  }

  const toAssetUrl = (asset: AnyAsset | string, preserveSearchParams?: boolean) => {
    const paths = [];
    const params = preserveSearchParams && searchParams.toString();

    paths.push(searchScopePath || Utils.searchScopeToPath("DEFAULT"));

    if(typeof asset === "string") {

      paths.push(assetTypePath);
      paths.push(asset);

    } else {

      paths.push(Utils.assetTypeToPath(asset.assetType as AssetType));
      paths.push(asset.uuid);
    }

    paths.push(params && "?" + params);

    return "/" + paths.filter(p => p).join("/");
  };

  const navigateToAsset = (asset: AnyAsset | string) => {
    const url = toAssetUrl(asset, false);
    navigate(url);
  }

  const navigateToCanvas = (threadUuid?: string) => {
    if(!threadUuid) {
      throw Error("Cannot open the board for undefined thread uuid");
    }
    navigate("/board/" + (searchScopePath || Utils.searchScopeToPath("DEFAULT")) + "/" + threadUuid);
  }

  const navigateToTag = (tag: string) => {

    if(searchScopePath && !uuid) {

      searchParams.append("tags", tag);
      setSearchParams(searchParams);
    } else {

      const paths = [];
      paths.push(searchScopePath || Utils.searchScopeToPath("DEFAULT"));
      paths.push("?" + createSearchParams([["tags", tag]]).toString());
      navigate("/" + paths.filter(p => p).join("/"));
    }
  }

  const navigateToFeaturedContent = (fc: any) => {

    const paths = [];
    paths.push(searchScopePath || Utils.searchScopeToPath("DEFAULT"));


    if(fc.Domain in AssetType) {
      paths.push(Utils.assetTypeToPath(fc.Domain));
    }

    const newSearchParams = createSearchParams([]);

    if(fc.categories && fc.categories.length) {

      fc.categories.forEach((c: any) => newSearchParams.append("categories", c.Slug))
    }

    if(fc.SortBy) {
      newSearchParams.append("sortBy", Utils.cmsSortByToField(fc.SortBy)!);
    }
    if(fc.SortDescending) {
      newSearchParams.append("descending", fc.SortDescending);
    }

    const query = newSearchParams.toString();
    if(query) {
      paths.push("?" + query);
    }


    navigate("/" + paths.filter(p => p).join("/"));
  }

  return {
    isHome,
    toScopeUrl,
    toAssetUrl,
    navigateToCanvas,
    navigateToTag,
    navigateToAsset,
    navigateToAssetType,
    navigateToScope,
    navigateToQuery,
    resetQueryNavigation,
    resetAssetTypeNavigation,
    navigateToFeaturedContent
  }
}

export default useNavigation;
