import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const {defineMultiStyleConfig} =
  createMultiStyleConfigHelpers(menuAnatomy.keys)

const variants = {
  exploded: {
    list: {
      border: 0,
      boxShadow: "none"
    }
  }
}

// export the base styles in the component theme
const Menu = defineMultiStyleConfig({variants})
export default Menu