import * as React from "react";
import useSearchContext from "../../hooks/useSearchContext";
import { Box, Button, ButtonGroup, Center, Collapse, Hide, Menu, MenuButton, MenuItem, MenuList, Show, Text } from "@chakra-ui/react";
import { AssetIcon } from "../../components/icon/AssetIcon";
import { useTranslation } from "react-i18next";
import { SearchFilterPanel } from "./SearchFilterPanel";
import { MdClear } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import useNavigation from "../../hooks/useNavigation";
import { useParams } from "react-router-dom";
import Utils from "../../../util/utils";
import ToolbarDivider from "../../components/ToolbarDivider";
import { ALL_ASSET_TYPES } from "../../../types";


/**
 * <h3>Active FilterPanel are scoped in a single-filter context, or as a global active filter catcher.</h3>
 * <dl>
 *   <dt> Single Filter</dt>
 *   <dd> Active FilterPanel are only aware to changes made to one type of filtered data only.</dd>
 *   <dt> Global Filter</dt>
 *   <dd> Any filter change of any context type must be reflected here.</dd>
 * </dl>
 * @param props
 * @constructor
 */


export const SearchFilterToolbar: React.FC<any> = (props: {}) => {

  const {t} = useTranslation();
  const {assets, isLoading, isFetching} = useSearchContext();
  const {navigateToAssetType, resetAssetTypeNavigation} = useNavigation();
  const {assetTypePath} = useParams();

  const {filters, total} = assets;

  const assetTypes: any[] =
    Object.entries(filters || [])
          .filter(e => e[0] === "assetType")
          .map(e => Object.entries(e[1] as {}).filter(at => ALL_ASSET_TYPES.includes(at[0]))).flat()
          .sort((at1, at2) => ALL_ASSET_TYPES.indexOf(at1[0]) - ALL_ASSET_TYPES.indexOf(at2[0]));

  const isFilterActive: boolean = !!assetTypePath;

  const assetType = Utils.pathToAssetType(assetTypePath);

  const renderClearFilterButton = () =>
    assetTypes.filter(at => assetTypePath && at[0] === assetType).map((at, i) =>
      <React.Fragment key={at[0]}>
        <ToolbarDivider/>
        <Button
          isActive
          leftIcon={<Center boxSize={4}><AssetIcon assetType={at[0]} iconOnly/></Center>}
          rightIcon={<Box pr={2}><MdClear/></Box>}
          size={"sm"}
          onClick={() => resetAssetTypeNavigation()}>
          {t(at[0])}&nbsp;{renderCount(at[1])}
        </Button>
        <ToolbarDivider/>
        <SearchFilterPanel/>
      </React.Fragment>);

  const renderCount = (count: number) =>
    <Text as="span" fontSize={"xs"} color="grey.500">({count})</Text>;

  const renderMobileMenu = () =>
    <>
      <ToolbarDivider/>
      <Menu autoSelect={false}>
        <MenuButton
          as={Button}
          aria-label={t('Asset type filter menu')}
          rightIcon={<IoMdArrowDropdown/>}
          variant="toolbar"
          colorScheme={"secondary"}
        >{t("Results")}&nbsp;{renderCount(total)}</MenuButton>
        <MenuList bgColor="blackAlpha.700" rounded="base">
          {assetTypes.map((at, i) =>
            <MenuItem color="white"
                      _focus={{color: "black"}}
                      key={at[0]}
                      icon={<Center boxSize={4}><AssetIcon assetType={at[0]} iconOnly/></Center>}
                      onClick={() => navigateToAssetType(at[0], true)}>
              {t(at[0])}&nbsp;{renderCount(at[1])}
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <ToolbarDivider/>
      <SearchFilterPanel/>
    </>;

  const renderFilterButtons = () =>
    <>
      {assetTypes.map((at, i) =>
        <React.Fragment key={at[0]}>
          <ToolbarDivider/>
          <Button
            leftIcon={<Center boxSize={4}><AssetIcon assetType={at[0]} iconOnly/></Center>}
            onClick={() => navigateToAssetType(at[0], true)}>
            {t(at[0])}&nbsp;{renderCount(at[1])}
          </Button>
        </React.Fragment>
      )}
      <ToolbarDivider/>
      <SearchFilterPanel/>
    </>;

  return (
    <Box width="100%" position={"sticky"} top={"0"} zIndex="sticky">
      <Collapse animateOpacity in={total > 0 && !isFetching && !isLoading} delay={2000}>
        <ButtonGroup
          bg="blackAlpha.700"
          backdropFilter='auto'
          backdropBlur='4px'
          alignItems={"center"}
          justifyContent={"center"}
          width="100%"
          height="40px"
          isDisabled={isLoading || isFetching}
          isAttached
          variant="toolbar"
          size="sm" gap={2}>
          {isFilterActive ?
            renderClearFilterButton()
            :
            <>
              <Show below="xl">
                {renderMobileMenu()}
              </Show>
              <Hide below="xl">
                {renderFilterButtons()}
              </Hide>
            </>
          }
        </ButtonGroup>
      </Collapse>
    </Box>
  )
}
