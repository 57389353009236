import {useToast} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {useCallback} from "react";

const useToasts = () => {

   const {t} = useTranslation();
   const toast = useToast();

   const assetCreationSuccess = useCallback((assetType: string) => toast({
      title: t("Hurray!"),
      description: t("Your {{assetType}} was created. Let's jump in there right away!", {assetType: t(assetType)}),
      status: "success",
      duration: 5000,
      isClosable: true,
   }), [t, toast]);

   const linkCreationSuccess = useCallback(() => {
      if (!toast.isActive("linkCreationSuccess")) {
         toast({
            id: "linkCreationSuccess",
            title: t("Wow, we did not know that! Big Thanks!"),
            description: t("We just took care of saving this awesome connection for you."),
            status: "success",
            duration: 5000,
            isClosable: true,
         })
      }
   }, [t, toast]);

   const linkUpdateSuccess = useCallback(() => {
      if (!toast.isActive("linkUpdateSuccess")) {
         toast({
            id: "linkUpdateSuccess",
            title: t("Nice, thank you for clearing that up!"),
            description: t("We just took care of updating your connection for you."),
            status: "success",
            duration: 5000,
            isClosable: true,
         })
      }
   }, [t, toast]);

   const linkDeletionSuccess = useCallback(() => {
      if (!toast.isActive("linkDeletionSuccess")) {
         toast({
            id: "graphAlreadyContainsNodeWarning",
            title: t("Okay then, we shall erase that!"),
            description: t("The relation between those contents is gone... Puff!.\n\nThank you!"),
            status: "success",
            duration: 5000,
            isClosable: true,
         })
      }
   }, [t, toast]);

   const assetMutationError = useCallback((e: any, assetType: string) => toast({
      title: t("Oops!"),
      description: t(e?.message || "A problem occurred saving this {{assetType}}", {assetType: t(assetType)}),
      status: "error",
      duration: 5000,
      isClosable: true,
   }), [t, toast]);

   const assetDeletionError = useCallback((e: any, assetType: string) => toast({
      title: t("Oops!"),
      description: t(e?.message || "A problem occurred trying to delete this {{assetType}}", {assetType: t(assetType)}),
      status: "error",
      duration: 5000,
      isClosable: true,
   }), [t, toast]);

   const stageCreationError = useCallback((e: any) => toast({
      title: t("Oops!"),
      description: t(e?.message || "A problem occurred trying to add this content."),
      status: "error",
      duration: 5000,
      isClosable: true,
   }), [t, toast]);

   const graphAlreadyContainsNodeWarning = useCallback(() => {
      if (!toast.isActive("graphAlreadyContainsNodeWarning")) {
         toast({
            id: "graphAlreadyContainsNodeWarning",
            title: t("Oops!"),
            description: t("It seems that this is already in the graph, so no change has been made."),
            status: "warning",
            duration: 5000,
            isClosable: true,
         })
      }
   }, [t, toast]);

   return {
      assetCreationSuccess,
      linkCreationSuccess,
      assetMutationError,
      assetDeletionError,
      linkDeletionSuccess,
      linkUpdateSuccess,
      stageCreationError,
      graphAlreadyContainsNodeWarning
   }
}

export default useToasts;
