import * as React from "react"
import {AppRoutes} from "./router/AppRoutes";
import {ConfirmationContextProvider} from "./hooks/useConfirmationContext";
import {RelationshipEditorProvider} from "./hooks/useRelationshipEditorContext";
import {CmsCookieConsent} from "./components/CmsCookieConsent";

export const App = () =>
      (
            <ConfirmationContextProvider>
               <RelationshipEditorProvider>
                  <AppRoutes/>
                  <CmsCookieConsent/>
               </RelationshipEditorProvider>
            </ConfirmationContextProvider>
      )

