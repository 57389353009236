import * as React from "react";
import {Logo} from "./icon/Logo";
import {Flex, HStack, Link, Show, Spacer} from "@chakra-ui/react";
import {Navigation} from "./Navigation";
import useSearchContext from "../hooks/useSearchContext";
import {AssetCreator} from "../features/asset/AssetCreator";
import usePermissions from "../hooks/usePermissions";
import {NavigationLinks} from "./NavigationLinks";
import {AssetSearchBar} from "../features/asset/AssetSearchBar";
import {NavLink} from "react-router-dom";
import useNavigation from "../hooks/useNavigation";

export const Header: React.FC<any> = (props: {}) => {

   const {isAuthor} = usePermissions();
   const {
      query,
      assets,
      isUninitialized
   } = useSearchContext();
   const {contents} = assets;
   const {isHome} = useNavigation()

   return (
         <Flex p={2} width="100%" gap={[2, 2, 6]} bg="primary.500" overflow="hidden" boxShadow={"md"} dropShadow={"xl"}
               alignItems={"center"}>
            <Link as={NavLink} to={"/"}>
               <Logo/>
            </Link>
            <Navigation/>
            {!isHome ?
                  <HStack gap={1} visibility={isUninitialized && (!contents || !query) ? "hidden" : "visible"}>
                     <AssetSearchBar/>
                     {isAuthor && <AssetCreator/>}
                  </HStack>
                  : <Spacer flex={"auto"} w="35%"></Spacer>
            }

            <Show above={"md"}>
               <NavigationLinks direction={"row"}/>
            </Show>

         </Flex>
   )
}
