import * as React from "react"
import { Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Status } from "../../api";

const HIDDEN_STATUS = ["APPROVED", "FINAL"];

export const StatusLabel: React.FC<any> = (props: { status: Status }) => {

  const {t} = useTranslation();

  if(HIDDEN_STATUS.includes(props.status)) {
    return <></>;
  }

  return (
    <Text fontSize="xs" title={t("Status")} fontWeight="bold" casing="uppercase">{t(props.status) || "UNKNOWN"}</Text>
  )
}

