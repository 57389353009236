import {AnyAsset, AssetType} from "../../types";
import {
   useAccessControlApiResourceApproveMutation,
   useAccessControlApiResourceDenyMutation,
   useAccessControlApiResourceExposeMutation,
   useAccessControlApiResourceHideMutation,
   useAccessControlApiResourceLockMutation,
   useAccessControlApiResourceMarkAsAbuseMutation,
   useAccessControlApiResourceProtectMutation,
   useAccessControlApiResourcePublishMutation,
   useAccessControlApiResourceShareMutation,
   useCollectionApiResourceCreateMutation,
   useCollectionApiResourceDeleteMutation,
   useCollectionApiResourceFetchQuery,
   useCollectionApiResourceUpdateMutation,
   useThreadApiResourceCreateMutation,
   useThreadApiResourceDeleteMutation,
   useThreadApiResourceFetchQuery,
   useThreadApiResourceUpdateMutation,
   useUserProfileApiResourceFetchUserProfileQuery,
   useUserProfileApiResourceUpdateMutation,
   useWebLinkApiResourceCreateMutation,
   useWebLinkApiResourceDeleteMutation,
   useWebLinkApiResourceFetchQuery,
   useWebLinkApiResourceUpdateMutation,
   useWebSiteApiResourceCreateMutation,
   useWebSiteApiResourceDeleteMutation,
   useWebSiteApiResourceFetchQuery,
   useWebSiteApiResourceUpdateMutation,
   useWhatApiResourceCreateMutation,
   useWhatApiResourceDeleteMutation,
   useWhatApiResourceFetchQuery,
   useWhatApiResourceUpdateMutation,
   useWhenApiResourceCreateMutation,
   useWhenApiResourceDeleteMutation,
   useWhenApiResourceFetchQuery,
   useWhenApiResourceUpdateMutation,
   useWhereApiResourceCreateMutation,
   useWhereApiResourceDeleteMutation,
   useWhereApiResourceFetchQuery,
   useWhereApiResourceUpdateMutation,
   useWhoApiResourceCreateMutation,
   useWhoApiResourceDeleteMutation,
   useWhoApiResourceFetchQuery,
   useWhoApiResourceUpdateMutation
} from "../../api";
import {KnotBody} from "../features/knots/KnotBody";
import {WebLinkBody} from "../features/weblinks/WebLinkBody";
import {WebSiteBody} from "../features/websites/WebSiteBody";
import {ThreadBody} from "../features/threads/ThreadBody";
import {CollectionBody} from "../features/collections/CollectionBody";
import * as React from "react";
import {useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";

const AUTO_COMMENT = "Not commented.";

const toParamName = (assetType: AssetType | string | undefined): string =>
      (assetType ? assetType[0].toLowerCase() + assetType.substring(1, assetType.length) : "asset") + "MutationRequest"

const loading = (result: any) => {
   return result && result.isLoading
}

type RestContextType = {
   get: any,
   put: Function,
   _delete: Function,
   post: Function,
   AssetBody?: React.FC<any>,
   isLoading: boolean
}

const useAsset = ({
                     asset,
                     assetUuid,
                     assetType
                  }: { asset?: AnyAsset, assetType?: AssetType, assetUuid?: string }) => {

   const {i18n} = useTranslation();

   const type = useMemo(() => (asset ? asset.assetType : assetType) || AssetType.Asset, [asset, assetType]);
   const uuid = useMemo(() => (asset ? asset.uuid : assetUuid) || "", [asset, assetUuid]);

   const putWhat = useWhatApiResourceUpdateMutation();
   const deleteWhat = useWhatApiResourceDeleteMutation();
   const postWhat = useWhatApiResourceCreateMutation();
   const putWho = useWhoApiResourceUpdateMutation();
   const deleteWho = useWhoApiResourceDeleteMutation();
   const postWho = useWhoApiResourceCreateMutation();
   const putWhere = useWhereApiResourceUpdateMutation();
   const deleteWhere = useWhereApiResourceDeleteMutation();
   const postWhere = useWhereApiResourceCreateMutation();
   const putWhen = useWhenApiResourceUpdateMutation();
   const deleteWhen = useWhenApiResourceDeleteMutation();
   const postWhen = useWhenApiResourceCreateMutation();
   const putWebLink = useWebLinkApiResourceUpdateMutation();
   const deleteWebLink = useWebLinkApiResourceDeleteMutation();
   const postWebLink = useWebLinkApiResourceCreateMutation();
   const putWebSite = useWebSiteApiResourceUpdateMutation();
   const deleteWebSite = useWebSiteApiResourceDeleteMutation();
   const postWebSite = useWebSiteApiResourceCreateMutation();
   const putThread = useThreadApiResourceUpdateMutation();
   const deleteThread = useThreadApiResourceDeleteMutation();
   const postThread = useThreadApiResourceCreateMutation();
   const putCollection = useCollectionApiResourceUpdateMutation();
   const deleteCollection = useCollectionApiResourceDeleteMutation();
   const postCollection = useCollectionApiResourceCreateMutation();
   const putUserProfile = useUserProfileApiResourceUpdateMutation();
   const [ac_approve] = useAccessControlApiResourceApproveMutation();
   const [ac_deny] = useAccessControlApiResourceDenyMutation();
   const [ac_expose] = useAccessControlApiResourceExposeMutation();
   const [ac_hide] = useAccessControlApiResourceHideMutation();
   const [ac_lock] = useAccessControlApiResourceLockMutation();
   const [ac_markAsAbuse] = useAccessControlApiResourceMarkAsAbuseMutation();
   const [ac_protect] = useAccessControlApiResourceProtectMutation();
   const [ac_publish] = useAccessControlApiResourcePublishMutation();
   const [ac_share] = useAccessControlApiResourceShareMutation();

   const what = useWhatApiResourceFetchQuery({uuid}, {skip: !!asset || type !== AssetType.What || !uuid})
   const who = useWhoApiResourceFetchQuery({uuid}, {skip: !!asset || type !== AssetType.Who || !uuid});
   const where = useWhereApiResourceFetchQuery({uuid}, {skip: !!asset || type !== AssetType.Where || !uuid});
   const when = useWhenApiResourceFetchQuery({uuid}, {skip: !!asset || type !== AssetType.When || !uuid});
   const webLink = useWebLinkApiResourceFetchQuery({uuid}, {skip: !!asset || type !== AssetType.WebLink || !uuid});
   const webSite = useWebSiteApiResourceFetchQuery({uuid}, {skip: !!asset || type !== AssetType.WebSite || !uuid});
   const thread = useThreadApiResourceFetchQuery({uuid}, {skip: !!asset || type !== AssetType.Thread || !uuid});
   const collection = useCollectionApiResourceFetchQuery({uuid}, {skip: !!asset || type !== AssetType.Collection || !uuid});
   const userProfile = useUserProfileApiResourceFetchUserProfileQuery({uuid}, {skip: !!asset || type !== AssetType.UserProfile || !uuid});

   const restReq: { [key: string]: RestContextType } = useMemo(() => ({
      "What": {
         get: what,
         put: putWhat[0],
         _delete: deleteWhat[0],
         post: postWhat[0],
         AssetBody: KnotBody,
         isLoading: loading(putWhat[1]) || loading(deleteWhat[1]) || loading(postWhat[1])
      },
      "Who": {
         get: who,
         put: putWho[0],
         _delete: deleteWho[0],
         post: postWho[0],
         AssetBody: KnotBody,
         isLoading: loading(putWho[1]) || loading(deleteWho[1]) || loading(postWho[1])
      },
      "Where": {
         get: where,
         put: putWhere[0],
         _delete: deleteWhere[0],
         post: postWhere[0],
         AssetBody: KnotBody,
         isLoading: loading(putWhere[1]) || loading(deleteWhere[1]) || loading(postWhere[1])
      },
      "When": {
         get: when,
         put: putWhen[0],
         _delete: deleteWhen[0],
         post: postWhen[0],
         AssetBody: KnotBody,
         isLoading: loading(putWhen[1]) || loading(deleteWhen[1]) || loading(postWhen[1])
      },
      "WebLink": {
         get: webLink,
         put: putWebLink[0],
         _delete: deleteWebLink[0],
         post: postWebLink[0],
         AssetBody: WebLinkBody,
         isLoading: loading(putWebLink[1]) || loading(deleteWebLink[1]) || loading(postWebLink[1])
      },
      "WebSite": {
         get: webSite,
         put: putWebSite[0],
         _delete: deleteWebSite[0],
         post: postWebSite[0],
         AssetBody: WebSiteBody,
         isLoading: loading(putWebSite[1]) || loading(deleteWebSite[1]) || loading(postWebSite[1])
      },
      "Thread": {
         get: thread,
         put: putThread[0],
         _delete: deleteThread[0],
         post: postThread[0],
         AssetBody: ThreadBody,
         isLoading: loading(putThread[1]) || loading(deleteThread[1]) || loading(postThread[1])
      },
      "Collection": {
         get: collection,
         put: putCollection[0],
         _delete: deleteCollection[0],
         post: postCollection[0],
         AssetBody: CollectionBody,
         isLoading: loading(putCollection[1]) || loading(deleteCollection[1]) || loading(postCollection[1])
      },
      "UserProfile": {
         get: userProfile,
         put: putUserProfile[0],
         _delete: () => {
         },
         post: () => {
         },
         isLoading: loading(putUserProfile[1])
      },
      "Asset": {
         get: {},
         put: () => {
         },
         _delete: () => {
         },
         post: () => {
         },
         isLoading: false
      }
   }), [collection, deleteCollection, deleteThread, deleteWebLink, deleteWebSite, deleteWhat, deleteWhen, deleteWhere, deleteWho, postCollection, postThread, postWebLink, postWebSite, postWhat, postWhen, postWhere, postWho, putCollection, putThread, putUserProfile, putWebLink, putWebSite, putWhat, putWhen, putWhere, putWho, thread, userProfile, webLink, webSite, what, when, where, who]);

   const paramName = useMemo(() => toParamName(type), [type]);

   const {
      data,
      ...rest
   } = useMemo(() => type in restReq ? restReq[type].get : {data: {}}, [type, restReq]);

   const merger = useCallback((prop: string, value: any) => restReq[type].put({
      uuid: uuid,
      [paramName]: {
         ...(asset || data),
         [prop]: value
      }
   }), [asset, data, uuid, restReq, type, paramName]);

   const updater = useCallback(() => restReq[type].put({
      uuid: uuid,
      [paramName]: asset
   }), [asset, uuid, paramName, restReq, type]);

   const creator = useCallback(() => restReq[type].post({
      [paramName]: {
         ...asset,
         language: i18n.language
      },
   }), [asset, paramName, restReq, type, i18n]);

   const deleter = useCallback(() => restReq[type]._delete({batchRequest: {uuids: [uuid]}}), [uuid, restReq, type]);

   const protect = useCallback(() => ac_protect({batchRequest: {uuids: [uuid]}}), [uuid, ac_protect]);
   const approve = useCallback((comments?: string) => ac_approve({
      commentedBatchRequest: {
         uuids: [uuid],
         comments: comments || AUTO_COMMENT
      }
   }), [uuid, ac_approve]);
   const deny = useCallback((comments?: string) => ac_deny({
      commentedBatchRequest: {
         uuids: [uuid],
         comments: comments || AUTO_COMMENT
      }
   }), [uuid, ac_deny]);
   const lock = useCallback((comments?: string) => ac_lock({
      commentedBatchRequest: {
         uuids: [uuid],
         comments: comments || AUTO_COMMENT
      }
   }), [uuid, ac_lock]);
   const markAsAbuse = useCallback((comments?: string) => ac_markAsAbuse({
      commentedBatchRequest: {
         uuids: [uuid],
         comments: comments || AUTO_COMMENT
      }
   }), [uuid, ac_markAsAbuse]);
   const publish = useCallback((comments?: string) => ac_publish({
      commentedBatchRequest: {
         uuids: [uuid],
         comments: comments || AUTO_COMMENT
      }
   }), [uuid, ac_publish]);
   const expose = useCallback(() => ac_expose({batchRequest: {uuids: [uuid]}}), [uuid, ac_expose]);
   const hide = useCallback(() => ac_hide({batchRequest: {uuids: [uuid]}}), [uuid, ac_hide]);
   const share = useCallback(() => ac_share({batchRequest: {uuids: [uuid]}}), [uuid, ac_share]);

   return {
      asset: asset ? {
         data: asset,
         isLoading: false,
         isFetching: false,
         isUninitialized: false,
         isSuccess: true,
         refetch: restReq[type].get.refetch
      } : {data, ...rest},
      creator,
      merger,
      updater,
      deleter,
      approve,
      deny,
      expose,
      hide,
      lock,
      markAsAbuse,
      protect,
      publish,
      share,
      isLoading: restReq[type].isLoading,
      AssetBody: restReq[type].AssetBody
   }
}

export default useAsset;
