import { extendTheme, StyleFunctionProps, withDefaultColorScheme } from '@chakra-ui/react'
import config from "./config";
import colors from "./colors";
import shadows from "./shadows";
import fonts from "./fonts";
import radii from "./radius";
import { components } from "./components";
import FontFaces from "./typography/FontFaces";

/**
 * Theme extensions should follow the structure given by the default theme.
 *
 * See https://chakra-ui.com/docs/styled-system/theme;
 * and https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/foundations
 *
 * @author Otavio Biasutti, PRODYNA AG
 * @since 2022-07-19
 */
const Theme = extendTheme({
  fonts,
  config,
  colors,
  shadows,
  components,
  radii,
  breakpoints: {
    base: '0em',
    xs: '20em',
    sm: '30em',
    md: '48em',
    lg: '62em',
    xl: '80em',
    '2xl': '96em'
  },
  styles: {
    global: (props: StyleFunctionProps) => ({
      /* Chakra overrides */
      ".chakra-portal": {
        height: "100%"
      },
      /* Browser overrides */
      "#root": {
        minH: "100vh",
        display: "flex",
        flexDir: "column",
        width: "100%"
      },
      "main": {
        flex: "1 1 100%"
      },
      "*": {
        scrollbarWidth: "thin",
        /* "color background-color"*/
        scrollbarColor: props.theme.colors.blackAlpha['700'] + " transparent"
      },
      "*::-webkit-scrollbar": {
        width: "4px",
        height: "4px"
      },
      "*::-webkit-scrollbar-track": {
        background: "transparent"
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: props.theme.colors.blackAlpha['700'],
        border: "1px solid " + props.theme.colors.blackAlpha['300']
      },
      /* Custom styles */
      ".hide-scrollbar": {
        "-ms-overflow-style": "none",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
          display: "none"
        }
      },
      ".active-navigation": {
        "> :not(.active)": {
          display: "none"
        },
        "> .active": {
          border: 0,
        },
        ml: "auto",
        mr: 2,
      },
      ".outlined-text": {
        textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;"
      },
      /* Quill-Editor */
      ".rich-textarea": {
        rounded: "md",
        overflow: "hidden",
        boxShadow: "0 0 2px 0px inset black",
        width: "100%",
        height: "100%",
        bg: "pure-white",
        ".ql-editor": {
          minH: "250px"
        }
      },
      ".ql-container.ql-snow.ql-disabled": {
        border: "none",
        ".ql-editor": {
          p: 0
        }
      },
    })
  }
}, withDefaultColorScheme({
  colorScheme: 'primary'
}));

export { Theme, FontFaces };
