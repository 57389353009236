import { useUserProfileApiResourceMeQuery } from "../../api";
import * as React from "react";
import { AssetRenderer } from "../features/asset/AssetRenderer";

export const Profile: React.FC<any> = (props) => {

  const {data} = useUserProfileApiResourceMeQuery();

  return (
    <AssetRenderer asset={data} color={"primary.300"}/>
  )

}
