import * as React from "react"
import {ImProfile} from "react-icons/im";
import {FaBook, FaPencilAlt, FaVideo} from "react-icons/fa";
import {RiMovie2Fill} from "react-icons/ri";
import {BsFillCameraFill} from "react-icons/bs";
import {IoMdImage} from "react-icons/io";
import {Circle} from "@chakra-ui/react";
import {Classification} from "../../../api";

export const ContentTypeIcon: React.FC<any> = (props: { contentType: Classification | string }) => {

   const type = typeof props.contentType === "string" ? props.contentType : props.contentType.Name;

   switch (type) {
      case "video":
         return <FaVideo/>;
      case "movie":
         return <RiMovie2Fill/>;
      case "book":
         return <FaBook/>;
      case "image":
         return <IoMdImage/>;
      case "picture":
         return <ImProfile/>;
      case "photography":
         return <BsFillCameraFill/>;
      case "drawing":
         return <FaPencilAlt/>;
      default:
         return <Circle title={type} size={"100%"} fontWeight={900}>{type}</Circle>
   }

}
