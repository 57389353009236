import { ComponentStyleConfig } from "@chakra-ui/react";

const Input: ComponentStyleConfig = {
  variants: {
    outline: {
      field: {
        rounded: "full",
        _focusVisible: {
          borderColor: "blackAlpha.800",
          boxShadow: "1px 1px 1px 1px darkslategray"
        },
      }
    }
  }
}

export default Input;