import * as React from "react"
import {Center, Editable, EditablePreview, EditableTextarea, Text, Tooltip} from "@chakra-ui/react";
import Utils from "../../../util/utils";
import {useTranslation} from "react-i18next";
import {EditIcon} from "../icon/EditIcon";

export const TextArea: React.FC<any> = (props: { value: string, onChange?: Function, width?: string | string[], placeholder?: string }) => {

   const {t} = useTranslation();
   const isEditable = !!props.onChange;
   const render = props.value || props.onChange;

   const valueSplit = Utils.html2Text(props.value || "").split("\n");
   const placeholder = props.placeholder || t("Click to edit the text");

   return (
         render ?
               isEditable ?
                     <Center width={props.width}>
                        <Editable width={props.width} position="relative" defaultValue={valueSplit.join("\n")}
                                  placeholder={placeholder}
                                  onSubmit={nextValue => props.onChange?.("<p>" + nextValue.trim().replaceAll("(\r?\n)+", "</p><p>") + "</p>")}>
                           <Tooltip color="pure-white" label={placeholder} colorScheme="primary">
                              <EditablePreview cursor={"pointer"} fontSize={["xs", "sm", "md", "md"]} p={1} _hover={{
                                 bg: 'blackAlpha.500'
                              }}/>
                           </Tooltip>
                           <EditIcon/>
                           <EditableTextarea p={2} minHeight={"xs"} minWidth={280} w={props.width} maxLength={1000}
                                             borderWidth={2} borderColor="blackAlpha.500"/>
                        </Editable>
                     </Center>
                     :
                     <>
                        {valueSplit.map((t, i) =>
                              <Center key={i} mx="auto" width={props.width}>
                                 <Text width={props.width} fontSize={["xs", "sm", "md", "md"]}>{t}</Text>
                              </Center>)
                        }
                     </>
               :
               <></>
   )
}

