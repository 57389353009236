import * as React from "react";
import {Box, Flex} from "@chakra-ui/react";
import {Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {ModalFull} from "../components/ModalFull";
import {SearchFilterToolbar} from "../features/search/SearchFilterToolbar";
import {SearchResults} from "../features/search/SearchResults";
import usePermissions from "../hooks/usePermissions";
import {HeaderCanvas} from "../components/HeaderCanvas";

export const Atlas: React.FC<any> = () => {

   const {
      assetTypePath,
      uuid
   } = useParams();
   const navigate = useNavigate();
   const location = useLocation();
   const {isAuthor} = usePermissions();

   return (
         <Box>
            {assetTypePath && uuid &&
                  <ModalFull
                        header={isAuthor ? <HeaderCanvas/> : undefined}
                        onClose={() => {
                           if (location.key === 'default') {
                              navigate('/')
                           } else {
                              navigate(-1);
                           }
                        }}>
                     <Outlet/>
                  </ModalFull>}
            <Flex gap={8} mb={5} flexDirection="column" alignItems="center"
                  minHeight={"100vh"}>
               <SearchFilterToolbar/>
               <SearchResults/>
            </Flex>
         </Box>
   )
}
