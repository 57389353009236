import * as React from "react";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {EditRelationshipProps, RelationshipEditorType} from "../../types";
import {
   Asset,
   Classification,
   Relationship,
   RelationshipMutationRequest,
   ThreadsMutationRequest,
   useClassificationApiResourceFindQuery,
   useCollectsApiResourceCreateMutation,
   useCollectsApiResourceFromToQuery,
   useRelationshipApiResourceCountRelationshipThreadsQuery,
   useRelationshipApiResourceCreateMutation,
   useRelationshipApiResourceDeleteMutation,
   useRelationshipApiResourceFromToQuery,
   useRelationshipApiResourceUpdateMutation,
   useThreadsApiResourceCreateMutation,
   useThreadsApiResourceDeleteMutation,
   useThreadsApiResourceFromToQuery,
   useThreadsApiResourceUpdateMutation,
} from "../../api";
import {
   Button,
   ButtonGroup,
   Flex,
   Icon,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   Text,
   useBoolean,
   useDisclosure,
   useToken
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {IoMdSave, IoMdTrash} from "react-icons/io";
import useToasts from "./useToasts";
import {AssetCoin} from "../features/asset/AssetCoin";
import {BsDashLg} from "react-icons/bs";
import useClassifications from "./useClassifications";
import {SingleSelect} from "../components/editables/SingleSelect";
import {FaArrowLeft, FaArrowRight, FaInfoCircle} from "react-icons/fa";
import {Suspense} from "../components/Suspense";
import {Coordinates} from "../components/board";

const UseRelationshipEditorContext = React.createContext<RelationshipEditorType>(() => {
   throw Error("Relationship Editor not yet initialized")
});

const useRelationshipEditorContext = () => React.useContext<RelationshipEditorType>(UseRelationshipEditorContext);

const RelationshipEditorProvider = ({children}: React.PropsWithChildren) => {

   const {t} = useTranslation();
   const toasts = useToasts();
   const [infoColor] = useToken("colors", ["primary.900"]);

   const ref = useRef(null);
   const {
      isOpen,
      onClose,
      onOpen
   } = useDisclosure();

   // primary context for editing any relationship
   const [relationship, setRelationship] = useState<RelationshipMutationRequest>({});
   const [start, setStart] = useState<Asset>({});
   const [end, setEnd] = useState<Asset>({});

   // secondary context for editing any relationship inside a thread
   const [threads, setThreads] = useState<ThreadsMutationRequest | null>(null);
   const [threadUuid, setThreadUuid] = useState<string>();
   const [threadCoordinates, setThreadCoordinates] = useState<Coordinates>();

   const [relClassifier, setRelClassifier] = useState<string>();

   const [isInverted, {
      on: setInvertedOn,
      off: setInvertedOff,
      toggle
   }] = useBoolean(false);

   const [classifier] = useClassifications({
      slugs: relClassifier,
      domains: ["RELATIONSHIPS"],
      classifiersOnly: true
   });

   const {data} = useRelationshipApiResourceCountRelationshipThreadsQuery({uuid: relationship.uuid!}, {skip: !relationship.uuid});

   const [createCollects, createCollectsResult] = useCollectsApiResourceCreateMutation();

   const [addRelationship, addRelationshipResult] = useRelationshipApiResourceCreateMutation();
   const [updateRelationship, updateRelationshipResult] = useRelationshipApiResourceUpdateMutation();
   const [deleteRelationship, deleteRelationshipResult] = useRelationshipApiResourceDeleteMutation();

   const [addThreads, addThreadsResult] = useThreadsApiResourceCreateMutation();
   const [updateThreads, updateThreadsResult] = useThreadsApiResourceUpdateMutation();
   const [deleteThreads, deleteThreadsResult] = useThreadsApiResourceDeleteMutation();

   // If in a thread-context, try to fetch the threads-relation if not passed
   const {
      data: loadedThreads,
      isLoading: isLoadingThreads,
      isFetching: isFetchingThreads
   } = useThreadsApiResourceFromToQuery({
      aUuid: start.uuid!,
      anotherUuid: end.uuid!,
      threadUuid: threadUuid!,
   }, {skip: !start.uuid || !end.uuid || !threadUuid || !!threads})

   useEffect(() => {
      if (loadedThreads) {
         setThreads(loadedThreads)
         if (loadedThreads.inverted) {
            setInvertedOn();
         }
      } else {
         setThreads(null)
         setInvertedOff();
      }
   }, [loadedThreads, setThreads, setInvertedOn])

   // If in a thread-context, try to fetch the collects-relation for the start and end nodes
   const {
      data: loadedCollectsStart,
      isLoading: isLoadingCollectsStart,
      isFetching: isFetchingCollectsStart
   } = useCollectsApiResourceFromToQuery({
      assemblageUuid: threadUuid!,
      assetUuid: start.uuid!,
   }, {skip: !start.uuid || !threadUuid || !!threads?.uuid})

   const {
      data: loadedCollectsEnd,
      isLoading: isLoadingCollectsEnd,
      isFetching: isFetchingCollectsEnd
   } = useCollectsApiResourceFromToQuery({
      assemblageUuid: threadUuid!,
      assetUuid: end.uuid!,
   }, {skip: !end.uuid || !threadUuid || !!threads?.uuid})

   // Try to fetch the relationship if not passed
   const {
      data: loadedRelationships,
      isLoading: isLoadingRelationships,
      isFetching: isFetchingRelationships
   } = useRelationshipApiResourceFromToQuery({
      aUuid: start.uuid!,
      anotherUuid: end.uuid!
   }, {skip: !start.uuid || !end.uuid || !!relationship?.uuid});

   useEffect(() => {

      if (loadedRelationships?.length) {
         const loadedRelationship = loadedRelationships[0];
         if ((loadedRelationship.startUuid === start.uuid || loadedRelationship.endUuid === start.uuid) &&
               (loadedRelationship.startUuid === end.uuid || loadedRelationship.endUuid === end.uuid)) {
            setRelationship(loadedRelationship)
            setRelClassifier(loadedRelationship.classifier)
            if (start.uuid === loadedRelationship.endUuid && !threads) {
               setInvertedOn();
            }
         } else {
            setRelationship({});
            setRelClassifier(undefined);
         }
      } else {
         setRelationship({});
         setRelClassifier(undefined);
      }
   }, [loadedRelationships, setRelationship, setRelClassifier, setInvertedOn, threads, start])

   const isReady = useMemo(() =>
               !isFetchingRelationships &&
               !isLoadingRelationships &&
               !isFetchingCollectsEnd &&
               !isLoadingCollectsEnd &&
               !isFetchingCollectsStart &&
               !isLoadingCollectsStart &&
               !isFetchingThreads &&
               !isLoadingThreads,
         [isFetchingRelationships, isLoadingRelationships, isFetchingCollectsEnd, isLoadingCollectsEnd, isFetchingCollectsStart, isLoadingCollectsStart, isFetchingThreads, isLoadingThreads]);

   const editRelationship = useCallback((props: EditRelationshipProps) => {
      setStart(props.from);
      setEnd(props.to);
      setThreadUuid(props.threadUuid);
      setThreadCoordinates(props.threadCoordinates);
      setRelationship({
         uuid: props.relationshipUuid,
         version: props.relationshipVersion,
         classifier: props.relationshipClassifier,
         fromUuid: props.from.uuid,
         toUuid: props.to.uuid
      });
      setRelClassifier(props.relationshipClassifier);
      if (props.threadUuid) {
         setThreads({
            uuid: props.threadsUuid,
            version: props.threadsVersion,
            startUuid: props.threadUuid,
            endUuid: props.relationshipUuid
         });

         if (props.threadsInverted) {
            setInvertedOn();
         } else {
            setInvertedOff();
         }
      } else {
         setThreads(null)
         setInvertedOff();
      }
      onOpen()
   }, [onOpen, setThreads, setInvertedOn, setInvertedOff, setRelClassifier, setRelationship, setThreadCoordinates, setThreadUuid, setStart, setEnd])

   // response feedback handling
   useEffect(() => {
      if (addRelationshipResult.isError) {
         toasts.assetMutationError(addRelationshipResult.error, "relationship");
         addRelationshipResult.reset();
      } else if (addRelationshipResult.isSuccess) {
         toasts.linkCreationSuccess();
         addRelationshipResult.reset();
         if (!threads) {
            onClose();
         }
      }
   }, [addRelationshipResult, onClose, threads, toasts]);

   useEffect(() => {
      if (updateRelationshipResult.isError) {
         toasts.assetMutationError(updateRelationshipResult.error, "relationship");
         updateRelationshipResult.reset();
      } else if (updateRelationshipResult.isSuccess) {
         toasts.linkUpdateSuccess();
         updateRelationshipResult.reset();
         if (!threads) {
            onClose();
         }
      }
   }, [updateRelationshipResult, onClose, threads, toasts]);

   useEffect(() => {
      if (createCollectsResult.isError) {
         toasts.assetMutationError(createCollectsResult.error, "thread association");
         createCollectsResult.reset();
      }
   }, [createCollectsResult, toasts]);

   useEffect(() => {
      if (deleteRelationshipResult.isError) {
         toasts.assetDeletionError(deleteRelationshipResult.error, "relationship")
         deleteRelationshipResult.reset();
      } else if (deleteRelationshipResult.isSuccess) {
         toasts.linkDeletionSuccess();
         deleteRelationshipResult.reset();
         if (!threads) {
            onClose();
         }
      }
   }, [deleteRelationshipResult, onClose, threads, toasts]);

   useEffect(() => {
      if (addThreadsResult.isSuccess) {
         addThreadsResult.reset();
         onClose();
      }
   }, [addThreadsResult, onClose]);

   useEffect(() => {
      if (updateThreadsResult.isSuccess) {
         updateThreadsResult.reset();
         onClose();
      }
   }, [updateThreadsResult, onClose]);

   useEffect(() => {
      if (deleteThreadsResult.isSuccess) {
         deleteThreadsResult.reset();
         onClose();
      }
   }, [deleteThreadsResult, onClose]);

   const saveLabel = useMemo(() => {
      if (!relationship?.uuid) {
         if (threadUuid && !threads?.uuid) {
            return t("Create and add");
         }
         return t("Create")
      } else {
         if (threadUuid && !threads?.uuid) {
            return t("Add");
         }
      }
      return t("Save");
   }, [threadUuid, threads, relationship, t]);

   const deleteLabel = t("Delete relationship");
   const removeLabel = t("Remove from this thread");

   const saveCollects = useCallback(async () => {
      if (start && end && threadUuid && !threads) {
         let added = false;
         if (!loadedCollectsStart) {
            await createCollects({
               collectsMutationRequest: {
                  startUuid: threadUuid,
                  endUuid: start.uuid,
                  x: threadCoordinates?.x,
                  y: threadCoordinates?.y
               }
            })
            added = true;
         }
         if (!loadedCollectsEnd) {
            await createCollects({
               collectsMutationRequest: {
                  startUuid: threadUuid,
                  endUuid: end.uuid,
                  x: added ? typeof threadCoordinates?.x === "number" ? (threadCoordinates?.x + 400) : undefined : threadCoordinates?.x,
                  y: threadCoordinates?.y
               }
            })
         }
      }
   }, [createCollects, loadedCollectsEnd, loadedCollectsStart, start, end, threadUuid, threads, threadCoordinates]);

   const saveThreads = useCallback(async (rel: Relationship) => {
      if (threadUuid) {
         if (threads?.uuid) {
            await updateThreads({
               uuid: threads.uuid,
               threadsMutationRequest: {
                  uuid: threads.uuid,
                  version: threads.version,
                  inverted: isInverted
               }
            });
         } else {
            await saveCollects();
            await addThreads({
               threadsMutationRequest: {
                  startUuid: threadUuid,
                  endUuid: rel.uuid,
                  inverted: isInverted
               }
            });
         }
      } else {
         return void 0;
      }
   }, [threadUuid, isInverted, threads, saveCollects, addThreads, updateThreads])

   const save = useCallback(async () => {
      if (relationship.uuid) {
         if (relClassifier !== relationship.classifier) {
            await updateRelationship({
               uuid: relationship.uuid,
               relationshipMutationRequest: {
                  uuid: relationship.uuid,
                  version: relationship.version,
                  classifier: relClassifier
               }
            });
         }
         await saveThreads(relationship);
      } else {
         const response = await addRelationship({
            relationshipMutationRequest: {
               fromUuid: start.uuid,
               toUuid: end.uuid,
               classifier: relClassifier
            }
         })
         if ("data" in response) {
            await saveThreads(response.data);
         }

      }
   }, [relationship, start, end, addRelationship, saveThreads, relClassifier, updateRelationship])

   return (
         <UseRelationshipEditorContext.Provider value={editRelationship}>
            {children}
            <Modal
                  isOpen={isOpen}
                  onClose={onClose}
                  closeOnOverlayClick={false}
                  closeOnEsc={false}
                  initialFocusRef={ref}
                  size={{
                     base: "full",
                     md: "2xl"
                  }}
                  isCentered>
               <ModalOverlay/>
               <ModalContent>
                  <ModalHeader>{t("Connecting contents")}</ModalHeader>
                  <ModalCloseButton onClick={onClose} border={0}/>
                  <ModalBody>
                     <Suspense until={isReady}>
                        <Flex direction="column" gap={2} p={2}>
                           <SingleSelect
                                 placeholder={t("What does this relationship mean?")}
                                 isSlim
                                 allowEmptyQuery
                                 selection={classifier}
                                 useQuery={useClassificationApiResourceFindQuery}
                                 queryBuilder={(query: string) => ({
                                    query,
                                    classifiersOnly: true,
                                    forDomains: ["RELATIONSHIPS"],
                                    limit: 10
                                 })}
                                 labelSelector={(cat: Classification) => cat.Name}
                                 valueSelector={(cat: Classification) => cat.Slug}
                                 onChange={(newValue: Classification) => setRelClassifier(newValue ? newValue.Slug : undefined)}/>
                           <Flex gap={1} justifyContent={"center"}>
                              <AssetCoin asset={start} position={"relative"}/>
                              <Button variant={"ghost"} flex={1}
                                      colorScheme={"black"}
                                      isDisabled={!threads}
                                      leftIcon={isInverted ? <FaArrowLeft/> : <BsDashLg size={24}/>}
                                      rightIcon={isInverted ? <BsDashLg/> : <FaArrowRight/>}
                                      onClick={toggle}
                              >{(classifier?.Name ||
                                    <Text color={"gray.500"}>{t("? ? ?")}</Text>)} {(isInverted ? " (rev.)" : "")}</Button>
                              <AssetCoin asset={end} position={"relative"}/>
                           </Flex>
                           {threads &&
                                 <Text as={"i"} fontSize={"xs"}>
                                    <Icon>
                                       <FaInfoCircle size="100%" color={infoColor}/>
                                    </Icon> {t("Click on the arrow to change its direction.")}
                                 </Text>
                           }
                        </Flex>
                     </Suspense>
                  </ModalBody>
                  <ModalFooter>
                     <ButtonGroup size={"sm"} width="100%" justifyContent={"space-between"}>
                        {relationship?.uuid && data?.value === 0 &&
                              <Button
                                    aria-label={deleteLabel}
                                    colorScheme={"red"}
                                    onClick={() => deleteRelationship({batchRequest: {uuids: [relationship.uuid!]}})}
                                    leftIcon={<IoMdTrash/>}>{deleteLabel}</Button>
                        }
                        {threads?.uuid &&
                              <Button
                                    aria-label={removeLabel}
                                    colorScheme={"red"}
                                    onClick={() => deleteThreads({batchRequest: {uuids: [threads.uuid!]}})}
                                    leftIcon={<IoMdTrash/>}>{removeLabel}</Button>
                        }
                        <Button
                              aria-label={saveLabel}
                              isLoading={!isReady}
                              isDisabled={!isReady}
                              onClick={save}
                              leftIcon={<IoMdSave/>}>{saveLabel}</Button>
                     </ButtonGroup>
                  </ModalFooter>
               </ModalContent>
            </Modal>
         </UseRelationshipEditorContext.Provider>
   )
}

export default useRelationshipEditorContext;
export {RelationshipEditorProvider};

