import * as React from "react"
import {ForwardedRef} from "react"
import {Editable, EditableInput, EditablePreview, Tooltip} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {EditIcon} from "../icon/EditIcon";

export const InputText: React.FC<any> = React.forwardRef(({
                                                             onChange,
                                                             placeholder,
                                                             value
                                                          }: { value: string, onChange?: Function, placeholder?: string }, ref: ForwardedRef<any>) => {

   const {t} = useTranslation();
   const placeholderValue = placeholder || t("Click to edit the text");

   return ((value || onChange) ?
         onChange ?
               <>
                  <Editable position={"relative"}
                            defaultValue={value}
                            placeholder={placeholderValue}
                            onSubmit={nextValue => onChange?.(nextValue)}>
                     <Tooltip color="pure-white" label={placeholderValue} colorScheme="primary">
                        <EditablePreview p={1} cursor={"pointer"} _hover={{
                           bg: 'blackAlpha.500'
                        }}/>
                     </Tooltip>
                     <EditIcon/>
                     <EditableInput ref={ref}/>
                  </Editable>
               </>
               :
               <>{value}</>
         : <></>)
})

