import * as React from "react";
import { Divider } from "@chakra-ui/react";

const ToolbarDivider = React.memo(() => <Divider
  orientation="vertical"
  color={"primary.100"}
  width={"1px"}
  height={"100%"}
/>)


export default ToolbarDivider;