import { ChakraProvider } from "@chakra-ui/react"
import * as React from "react"
import * as ReactDOM from "react-dom/client"
import { App } from "./app/App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import { ApiProvider } from '@reduxjs/toolkit/query/react'
import { API } from "./api";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "react-oidc-context";
import CONFIG from "./config.json"
import './i18n'
import { FontFaces, Theme } from "./theme";

const container = document.getElementById("root")
if(!container) throw new Error('Failed to find the root element');
const root = ReactDOM.createRoot(container)

const oidcConfig = {
  authority: CONFIG.OIDC_ENDPOINT,
  client_id: CONFIG.OIDC_CLIENT_ID,
  redirect_uri: CONFIG.OIDC_REDIRECT_URI,
  // ...
};

root.render(
    <BrowserRouter>
      <ApiProvider api={API}>
        <AuthProvider {...oidcConfig}>
          <ChakraProvider theme={Theme}>
            <FontFaces/>
            <App/>
          </ChakraProvider>
        </AuthProvider>
      </ApiProvider>
    </BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

