import {Highlight} from "@chakra-ui/react";
import * as React from "react";

export const Highlighted = (props: { text: string; highlight: string; }) =>
      <Highlight
            query={props.highlight.split(/ +/)}
            styles={{
               bg: 'primary.100 !important',
               fontWeight: 'bold !important'
            }}
      >
         {props.text}
      </Highlight>;
