import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";

i18n
.use(HttpApi)
.use(initReactI18next) // passes i18n down to react-i18next
.init({
  lng: 'pt-BR',
  fallbackLng: false,
  nsSeparator: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false // react already safes from xss
  }
});

export default i18n;