import {BasePin, IPin} from "../index";

export class Pin implements IPin {

   static readonly CORNER_SIZE: number = 5;
   id: string;
   height: number;
   width: number;
   x: number;
   y: number;

   roundness: number | undefined;

   data: any;

   constructor({
                  id,
                  width,
                  height,
                  x,
                  y,
                  roundness,
               }: BasePin, data?: any) {
      this.id = id;
      this.height = height || 1;
      this.width = width || 1;
      this.x = x;
      this.y = y;
      this.roundness = roundness;
      this.data = data;
   }

   wireOutCoordinates(targetPin: IPin): [number, number] {

      const cornerSize = targetPin.roundness || Pin.CORNER_SIZE;

      const minX = this.x + cornerSize;
      const maxX = this.x + this.width - cornerSize;

      const minY = this.y + cornerSize;
      const maxY = this.y + this.height - cornerSize;

      const cx = this.getPinCenterX();
      const cy = this.getPinCenterY();
      const tan = this.height / this.width;

      const tcx = targetPin.getPinCenterX();
      const tcy = targetPin.getPinCenterY();

      const wireTan = Math.abs((tcy - cy) / (tcx - cx));
      const wireCotan = Math.abs((tcx - cx) / (tcy - cy));

      const signY = tcy > cy ? 1 : -1;
      const signX = tcx > cx ? 1 : -1;

      let dx: number;
      let dy: number;

      if (wireTan > tan) {
         dx = cx + this.height / 2 * wireCotan * signX;
         dy = cy + signY * this.height / 2;
         dx = Math.max(Math.min(maxX, dx), minX);
      } else {
         dx = cx + signX * this.width / 2;
         dy = cy + this.width / 2 * wireTan * signY;
         dy = Math.max(Math.min(maxY, dy), minY)
      }

      return [dx, dy];
   }

   getPinCenterX(): number {
      return this.x + this.width / 2;
   }

   getPinCenterY(): number {
      return this.y + this.height / 2;
   }

}
