import {useTranslation} from "react-i18next";
import usePermissions from "../hooks/usePermissions";
import {useAuth} from "react-oidc-context";
import {Icon, Link, Show, Spacer, Stack} from "@chakra-ui/react";
import {Link as RLink, NavLink} from "react-router-dom";
import {BsSearch} from "react-icons/bs";
import {MdOutlineDashboard} from "react-icons/md";
import {IoIosPersonAdd} from "react-icons/io";
import {TbLogin, TbLogout, TbTools} from "react-icons/tb";
import {HiOutlineUserCircle} from "react-icons/hi";
import CONFIG from "../../config.json";
import * as React from "react";
import {NavigationLinksParams} from "../../types";
import {VscOrganization} from "react-icons/vsc";
import {CgOrganisation} from "react-icons/cg";

export const NavigationLinks = ({
                                   disclosure,
                                   direction,
                                   activeOnly
                                }: NavigationLinksParams) => {

   const {t} = useTranslation();
   const {isReader} = usePermissions();
   const auth = useAuth();

   return <Stack direction={direction || "row"} className={activeOnly ? "active-navigation" : ""} gap={2}
                 alignItems={direction === "column" ? "start" : "center"} w={"100%"}>
      <Link onClick={disclosure?.onClose} variant="navlink" as={NavLink} to="/atlas">
         {direction === "column" &&
               <Icon verticalAlign="text-top" mr={2}><BsSearch size={"100%"}/></Icon>}{t('Atlas')}
      </Link>
      {isReader ?
            <>
               <Show above={direction === "row" ? "lg" : "base"}>
                  <Link alignItems={"center"} onClick={disclosure?.onClose} variant="navlink" as={NavLink}
                        to="/dashboard">
                     {direction === "column" && <Icon verticalAlign="text-top" mr={2}><MdOutlineDashboard
                           size={"100%"}/></Icon>}{t('Dashboard')}
                  </Link>
                  <Link size={"xs"} onClick={disclosure?.onClose} variant="navlink" as={NavLink} to="/workspace">
                     {direction === "column" &&
                           <Icon verticalAlign="text-top" mr={2}><TbTools size={"100%"}/></Icon>}{t('Workspace')}
                  </Link>
               </Show>
               {direction === "row" && <Spacer/>}
               <Show above={direction === "row" ? "xl" : "base"}>
                  <Link onClick={disclosure?.onClose} variant="navlink" as={NavLink} to="/me">
                     {direction === "column" &&
                           <Icon verticalAlign="text-top" mr={2}><HiOutlineUserCircle
                                 size={"100%"}/></Icon>}{t('Profile')}
                  </Link>
               </Show>
               <Show above={direction === "row" ? "lg" : "base"}>
                  <Link variant="navlink" href="/" onClick={() => void auth.signoutRedirect().then(() => {
                     auth.removeUser()
                  })}>{direction === "column" && <Icon verticalAlign="text-top" mr={2}><TbLogout size={"100%"}/></Icon>}
                     {t("Logout")}
                  </Link>
               </Show>
            </>
            :
            <>
               <Show above={direction === "row" ? "lg" : "base"}>
                  <Link alignItems={"center"} onClick={disclosure?.onClose} variant="navlink" as={NavLink}
                        to="/community">
                     {direction === "column" &&
                           <Icon verticalAlign="text-top" mr={2}><CgOrganisation size={"100%"}/></Icon>}

                     {t('Community')}
                  </Link>
                  <Link size={"xs"} onClick={disclosure?.onClose} variant="navlink" as={NavLink} to="/about">
                     {direction === "column" &&
                           <Icon verticalAlign="text-top" mr={2}><VscOrganization size={"100%"}/></Icon>}
                     {t('About Us')}
                  </Link>
               </Show>
               {direction === "row" && <Spacer/>}
               <Link variant="navlink" as={RLink} to="/"
                     onClick={() => void auth.signinRedirect()}>
                  {direction === "column" && <Icon verticalAlign="text-top" mr={2}><TbLogin size={"100%"}/></Icon>}
                  {t('Sign In')}</Link>
               <Link variant="button" href={CONFIG.OIDC_REGISTER_URI}>
                  {t('Sign Up')}
                  {direction === "column" &&
                        <Icon verticalAlign="text-top" ml={2}><IoIosPersonAdd size={"100%"}/></Icon>}
               </Link>
            </>
      }
      {direction === "row" && <Spacer/>}
   </Stack>
};
