import * as React from "react"
import { MdOutlinePlace, MdWeb } from "react-icons/md";
import { FiLink } from "react-icons/fi";
import { ImProfile } from "react-icons/im";
import { FaProjectDiagram, FaTheaterMasks } from "react-icons/fa";
import { BiTimeFive } from "react-icons/bi";
import { BsCollection } from "react-icons/bs";
import { AssetType } from "../../../types";
import { useTranslation } from "react-i18next";
import { GiWorld } from "react-icons/gi";
import { TbBox } from "react-icons/tb";

export const AssetIcon: React.FC<any> = (props: { assetType: AssetType }) => {

  const {t} = useTranslation();

  const icon = (): JSX.Element => {

    // TODO
    const size = "100%";

    switch(props.assetType) {
      case AssetType.WebLink:
        return <FiLink title={t(props.assetType as string)} size={"1.5em"}/>;
      case AssetType.WebSite:
        return <GiWorld title={t(props.assetType as string)} size={"1.5em"}/>;
      case AssetType.Collection:
        return <BsCollection title={t(props.assetType as string)} size={"1.25em"}/>;
      case AssetType.Thread:
        return <FaProjectDiagram title={t(props.assetType as string)} size={"1.25em"}/>;
      case AssetType.UserProfile:
        return <ImProfile title={t(props.assetType as string)} size={"1.25em"}/>;
      case AssetType.What:
        return <TbBox title={t(props.assetType as string)} size={"1.5em"}/>;
      case AssetType.Where:
        return <MdOutlinePlace title={t(props.assetType as string)} size={"1.5em"}/>;
      case AssetType.When:
        return <BiTimeFive title={t(props.assetType as string)} size={"1.5em"}/>;
      case AssetType.Who:
        return <FaTheaterMasks title={t(props.assetType as string)} size={"1.5em"}/>;
      default:
        return <MdWeb title={t(props.assetType as string)} size={size}/>
    }
  }

  return icon();
}
