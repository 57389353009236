/**
 * Theme extensions should follow the structure given by the default theme.
 *
 * See https://chakra-ui.com/docs/styled-system/theme;
 * and https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/foundations
 *
 * @author Otavio Biasutti, PRODYNA AG
 * @since 2022-07-19
 */
const config = {
  initialColorMode: 'light',
  useSystemColorMode: true,
  cssVarPrefix: "lab",

  cardWidth: 210,
  cardHeight: 300,

  presentationCardWidth: 126,
  presentationCardHeight: 180,

  coinWidth: 96,
  coinHeight: 96
};

export default config;
