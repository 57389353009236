import React, {useCallback, useMemo} from 'react';
import {Status, useSearchApiResourceSearchQuery, Visibility,} from "../../api";
import {useParams, useSearchParams} from "react-router-dom";
import {ARRAY_FILTERS, ArrayFilter, AssetType, Filter, SearchContextType} from "../../types";
import Utils from "../../util/utils";

const initialState: SearchContextType = {
   query: undefined,
   scope: "DEFAULT",
   page: 1,
   pageSize: 10,
   isLoading: false,
   isFetching: false,
   isUninitialized: true,
   isSearching: false,
   refetch: Utils.defUnboundFunc.bind(Utils),
   reset: Utils.defUnboundFunc.bind(Utils),
   assetType: 'Asset',
   setQuery: Utils.defUnboundFunc.bind(Utils),
   filterBy: Utils.defUnboundFunc.bind(Utils),
   removeFilterBy: Utils.defUnboundFunc.bind(Utils),
   filterByYear: Utils.defUnboundFunc.bind(Utils),
   filterByVisibility: Utils.defUnboundFunc.bind(Utils),
   removeFilterByVisibility: Utils.defUnboundFunc.bind(Utils),
   filterByStatus: Utils.defUnboundFunc.bind(Utils),
   removeFilterByStatus: Utils.defUnboundFunc.bind(Utils),
   filterByGrantedAccess: Utils.defUnboundFunc.bind(Utils),
   removeFilterByGrantedAccess: Utils.defUnboundFunc.bind(Utils),
   clearFilters: Utils.defUnboundFunc.bind(Utils),
   isFilterApplied: Utils.defUnboundFunc.bind(Utils),
   setPageNumber: Utils.defUnboundFunc.bind(Utils),
   setPageSize: Utils.defUnboundFunc.bind(Utils)
}

const UseSearchContext = React.createContext<SearchContextType>(initialState);

const useSearchContext = () => React.useContext<SearchContextType>(UseSearchContext);

// TODO optimization: apply useMemo and useCallback

const SearchContextProvider = ({children}: React.PropsWithChildren) => {

   const {
      searchScopePath,
      assetTypePath,
      uuid
   } = useParams();

   const [searchParams, setSearchParams] = useSearchParams();

   const searchScope = useMemo(() => Utils.pathToSearchScope(searchScopePath) || "DEFAULT", [searchScopePath]);
   const assetType = useMemo(() => Utils.pathToAssetType(assetTypePath) || AssetType.Asset, [assetTypePath]);

   const searchQuery = useMemo(() => ({
      // the scope
      scope: searchScope,
      assetType: assetType,

      // guard fields
      status: Utils.stringsToStatus(searchParams.getAll("status")),
      visibilities: Utils.stringsToVisibilities(searchParams.getAll("visibilities")),
      grantsWriteAccess: Boolean(searchParams.get("grantsWriteAccess")) || undefined,

      // assets
      query: searchParams.get("query") || initialState.query,

      //knots
      categories: Utils.arrayOrUndef(searchParams.getAll(ArrayFilter.CATEGORIES)),
      tags: Utils.arrayOrUndef(searchParams.getAll("tags")),

      // knot->year relationship
      fromYear: Number(searchParams.get("fromYear")) || undefined,
      untilYear: Number(searchParams.get("untilYear")) || undefined,

      // WhereBody
      maxDistanceInKm: Number(searchParams.get("maxDistanceInKm")) || undefined,
      latitude: Number(searchParams.get("latitude")) || undefined,
      longitude: Number(searchParams.get("longitude")) || undefined,

      // weblinks
      contentTypes: Utils.arrayOrUndef(searchParams.getAll("contentTypes")),
      keywords: Utils.arrayOrUndef(searchParams.getAll("keywords")),
      hosts: Utils.arrayOrUndef(searchParams.getAll("hosts")),

      // user profiles
      occupations: Utils.arrayOrUndef(searchParams.getAll("occupations")),
      organizations: Utils.arrayOrUndef(searchParams.getAll("organizations")),

      // sorting
      sortBy: searchParams.get('sortBy') || undefined,
      descending: searchParams.get('descending') === "true" || undefined,

      // pagination
      pageNumber: Number(searchParams.get('pageNumber')) || initialState.page,
      pageSize: Number(searchParams.get('pageSize')) || initialState.pageSize
   }), [searchParams, assetType, searchScope]);

   const hasSearchContext = useMemo(() => !!searchScopePath || (searchQuery.query !== undefined && searchQuery.query !== null), [searchScopePath, searchQuery]);

   const normalizedQuery = useMemo(() => hasSearchContext ? {
      ...searchQuery,
      query: Utils.normalizeSearchQuery(searchQuery.query)
   } : searchQuery, [hasSearchContext, searchQuery]);

   const result = useSearchApiResourceSearchQuery(normalizedQuery, {
      skip: !!uuid || !searchScope || !hasSearchContext
   });

   const resetPagination = useCallback(() => {
      searchParams.delete("pageSize");
      searchParams.delete("pageNumber");
      setTimeout(() =>
            window.scrollTo({
               top: 0,
               behavior: 'smooth'
            }), 100
      )
   }, [searchParams])

   const filterByYear = useCallback((minValue: number, maxValue: number) => {
      searchParams.set("fromYear", String(minValue));
      searchParams.set("untilYear", String(maxValue));
      resetPagination();
      setSearchParams(searchParams);
   }, [searchParams, setSearchParams, resetPagination])

   const filterByStatus = useCallback((status: Status) => {
      searchParams.append("status", status.toString());
      resetPagination();
      setSearchParams(searchParams);
   }, [searchParams, setSearchParams, resetPagination])

   const removeFilterByStatus = useCallback(() => {
      searchParams.delete("status");
      resetPagination();
      setSearchParams(searchParams);
   }, [searchParams, setSearchParams, resetPagination])

   const filterByVisibility = useCallback((visibility: Visibility) => {
      searchParams.append("visibilities", visibility.toString());
      resetPagination();
      setSearchParams(searchParams);
   }, [searchParams, setSearchParams, resetPagination])

   const removeFilterByVisibility = useCallback(() => {
      searchParams.delete("visibilities");
      resetPagination();
      setSearchParams(searchParams);
   }, [searchParams, setSearchParams, resetPagination])

   const filterByGrantedAccess = useCallback((writeAccess: boolean) => {
      searchParams.set("grantsWriteAccess", String(writeAccess));
      resetPagination();
      setSearchParams(searchParams);
   }, [searchParams, setSearchParams, resetPagination])

   const removeFilterByGrantedAccess = useCallback(() => {
      searchParams.delete("grantsWriteAccess");
      resetPagination();
      setSearchParams(searchParams);
   }, [searchParams, setSearchParams, resetPagination])

   const reset = useCallback(() => {
      setSearchParams({});
   }, [setSearchParams])

   const clearFilters = useCallback(() => {
      if (searchQuery.query) {
         setSearchParams({query: searchQuery.query});
      } else {
         setSearchParams({});
      }
   }, [setSearchParams, searchQuery])

   const filterBy = useCallback((param: ArrayFilter | Filter, value: string | number | boolean) => {
      if (ARRAY_FILTERS.includes(param)) {
         searchParams.append(param, String(value));
      } else {
         searchParams.set(param, String(value));
      }
      resetPagination();
      setSearchParams(searchParams);
   }, [setSearchParams, searchParams, resetPagination])

   const isFilterApplied = useCallback((param: ArrayFilter | Filter, value?: string | number | boolean) => {
      const values = searchParams.getAll(param);
      return (value === undefined && values.length > 0) || values.includes(String(value));
   }, [searchParams])

   const removeFilterBy = useCallback((param: string | string[], value: string | number | boolean) => {
      const params = new Array(param).flat();
      for (const p of params) {
         if (value !== null && value !== undefined) {
            const paramValue = String(value)
            let all = searchParams.getAll(p.toString());

            if (all) {
               searchParams.delete(p.toString());
               all.filter(v => v !== paramValue).forEach(v => searchParams.append(p.toString(), v));
            }
         } else {
            searchParams.delete(p.toString())
         }
      }
      resetPagination();
      setSearchParams(searchParams);
   }, [setSearchParams, searchParams, resetPagination])

   const setPageSize = useCallback((pageSize: number) => {
      searchParams.set("pageSize", String(pageSize));
      setSearchParams(searchParams, {replace: true});
   }, [setSearchParams, searchParams])

   const setPageNumber = useCallback((pageNumber: number) => {
      searchParams.set("pageNumber", String(pageNumber));
      setSearchParams(searchParams);
   }, [setSearchParams, searchParams])

   return (
         <UseSearchContext.Provider value={{
            normalizedQuery,
            ...searchQuery,
            ...(result.data ?? {
               assets: {},
               relationships: {},
               queriedAssets: {}
            }),
            isLoading: result.isLoading,
            isFetching: result.isFetching,
            isUninitialized: result.isUninitialized,
            isError: result.isError,
            refetch: result.refetch,
            currentData: result.currentData,
            timeInMillis: (result.fulfilledTimeStamp ?? 0) - (result.startedTimeStamp ?? 0),
            isSearching: hasSearchContext,
            reset,
            filterBy,
            removeFilterBy,
            filterByYear,
            filterByVisibility,
            removeFilterByVisibility,
            filterByStatus,
            removeFilterByStatus,
            filterByGrantedAccess,
            removeFilterByGrantedAccess,
            clearFilters,
            isFilterApplied,
            setPageSize,
            setPageNumber
         }}>
            {children}
         </UseSearchContext.Provider>
   )
}

export default useSearchContext;
export {SearchContextProvider};
