import {PinProps} from "../index";
import React, {ForwardedRef, forwardRef, useCallback, useEffect, useMemo, useRef, useState} from "react";
import * as d3 from "d3";
import {useBoardContext} from "../context/BoardContext";
import {PIN_Z_INDEX} from "../constants";
import {useMergeRefs} from "@chakra-ui/react";

const PinComponent = forwardRef(({
                                    id,
                                    width,
                                    height,
                                    x,
                                    y,
                                    onChange,
                                    roundness,
                                    children
                                 }: PinProps, ref: ForwardedRef<HTMLDivElement>) => {

   const internalRef = useRef<HTMLDivElement>(null);
   const mergedRef = useMergeRefs(internalRef, ref);

   const {
      draggable,
      transform,
      onChange: onBoardChange,
      setSelection,
      selection
   } = useBoardContext();

   const [visualX, setVisualX] = useState(x);
   const [visualY, setVisualY] = useState(y);
   const [isDragOn, setDragOn] = useState(false);

   useEffect(() => setVisualX(x), [x])
   useEffect(() => setVisualY(y), [y])

   const dragHandler = useCallback((event: any) => {
      const deltaX = Math.round(event.dx / transform.k);
      const deltaY = Math.round(event.dy / transform.k);
      setVisualX((vx) => vx + deltaX);
      setVisualY((vy) => vy + deltaY);
      onChange?.({
         pinId: id,
         deltaX,
         deltaY,
         ...event
      });
   }, [id, onChange, transform]);

   const dragStartHandler = useCallback(() => {
      d3.select(internalRef.current as Element).raise();
      setDragOn(true);
   }, [internalRef, setDragOn]);

   const dragEndHandler = useCallback((e: any) => {
      setDragOn(false)
      onBoardChange?.(id);
   }, [onBoardChange, id, setDragOn]);

   useEffect(() => {

      const pinNode = internalRef.current;

      if (draggable && pinNode && selection === id) {
         d3.select(pinNode as Element).call(d3.drag()
               .touchable(true)
               .on("start", dragStartHandler)
               .on("drag", dragHandler)
               .on("end", dragEndHandler));
      }

      return () => {
         if (pinNode) {
            d3.select(pinNode as Element).call(d3.drag()
                  .on("start", null)
                  .on("drag", null)
                  .on("end", null));
         }
      }
   }, [selection, internalRef, draggable, dragEndHandler, dragHandler, dragStartHandler])

   return useMemo(() =>
         <div ref={mergedRef} style={{
            pointerEvents: "all",
            position: "absolute",
            top: visualY,
            left: visualX,
            width: `${width}px`,
            height: `${height}px`,
            borderWidth: draggable && selection === id ? 3 : 0,
            borderColor: "rgb(41,49,51)",
            boxShadow: isDragOn ? "2px 2px 5px 0px rgba(0, 0, 0, .45)" : "none",
            cursor: isDragOn ? "grabbing" : "pointer",
            borderRadius: `${roundness || 0}px`,
            zIndex: PIN_Z_INDEX,
            transition: "opacity, border, border-radius, box-shadow, width, height .2s ease",
            overflow: "hidden",
         }}
              onClick={() => draggable && setSelection(id)}>
            <div style={{pointerEvents: !draggable || selection === id ? "all" : "none"}}>
               {children || <p style={{
                  display: "flex",
                  width: `${width}px`,
                  height: `${height}px`,
                  overflow: "hidden",
                  padding: "4px",
                  margin: 0,
                  color: "white",
                  flex: "1",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "rgb(170,170,170, .5)"
               }}>{id}</p>}</div>
         </div>, [children, height, id, isDragOn, roundness, visualX, visualY, width, mergedRef, selection, setSelection])
});

export default PinComponent;
