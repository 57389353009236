import * as React from "react";
import {useCallback, useMemo, useRef, useState} from "react";
import {ALL_ASSET_TYPES, AnyAsset, AssetType} from "../../../types";
import {Classification, useClassificationApiResourceFindQuery} from "../../../api";
import {
   Avatar,
   Button,
   ButtonGroup,
   Center,
   Flex,
   Heading,
   IconButton,
   Menu,
   MenuButton,
   MenuItem,
   MenuList,
   Modal,
   ModalBody,
   ModalCloseButton,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   Text,
   useDisclosure
} from "@chakra-ui/react";
import {AssetIcon} from "../../components/icon/AssetIcon";
import {StatusLabel} from "../../components/StatusLabel";
import {VisibilityIcon} from "../../components/icon/VisibilityIcon";
import {Images} from "../../components/editables/Images";
import {useTranslation} from "react-i18next";
import {InputText} from "../../components/editables/InputText";
import {TextArea} from "../../components/editables/TextArea";
import usePermissions from "../../hooks/usePermissions";
import {FaQuestion} from "react-icons/fa";
import {IoMdSave} from "react-icons/io";
import useAsset from "../../hooks/useAsset";
import useNavigation from "../../hooks/useNavigation";
import {BsPlusLg} from "react-icons/bs";
import Utils from "../../../util/utils";
import useToasts from "../../hooks/useToasts";
import {SingleSelect} from "../../components/editables/SingleSelect";
import useClassifications from "../../hooks/useClassifications";
import {useParams} from "react-router-dom";
import useGraphQueryContext from "../../hooks/useGraphQueryContext";
import {MdSaveAlt} from "react-icons/md";

const NOT_CREATABLE = [AssetType.WebSite, AssetType.WebLink, AssetType.UserProfile];

export const AssetCreator: React.FC<any> = () => {

   const {navigateToAsset} = useNavigation();
   const {t} = useTranslation();
   const {
      threadUuid,
      collectionUuid
   } = useParams();

   const {
      addCoordinates,
      addAssetPin
   } = useGraphQueryContext();

   const assemblageUuid = useMemo(() => threadUuid || collectionUuid, [threadUuid, collectionUuid]);

   const assetCreatorRef = useRef(null);

   const {
      isOpen,
      onClose,
      onOpen
   } = useDisclosure({id: "createAsset"});

   const {userProfile} = usePermissions();

   const [asset, setAsset] = useState<AnyAsset>({});

   const {
      creator,
      isLoading
   } = useAsset({asset});

   const toasts = useToasts();

   const [classifier] = useClassifications({
      slugs: asset.classifier ? [asset.classifier] : [],
      assetType: asset.assetType as AssetType
   });

   const createAssetAriaLabel = t("Add new content");
   const saveLabel = t("Save");
   const saveAndAddLabel = t("Save and add");

   const set = useCallback((prop: keyof AnyAsset | AnyAsset, value?: any) => {
      if (typeof prop === "string") {
         setAsset(asset => ({
            ...asset,
            [prop]: value
         }))
      } else {
         setAsset(asset => ({...asset, ...prop}));
      }
   }, [setAsset]);

   const hasAssetType = ALL_ASSET_TYPES.includes(asset?.assetType as string);

   const url = Utils.getAssetUrl(asset);

   const coverImages = Utils.getCoverImages(asset);

   const save = useCallback((assemblageUuid?: string) => {
      return creator()
            .then((r: any) => {
               if (r.error) {
                  toasts.assetMutationError(r.error, asset.assetType as string);
               } else if (r.data) {
                  onClose();
                  toasts.assetCreationSuccess(asset.assetType as string);
                  if (assemblageUuid) {
                     addAssetPin(r.data.uuid, addCoordinates?.x, addCoordinates?.y)
                  } else {
                     navigateToAsset(r.data)
                  }
               }
            });

   }, [toasts, navigateToAsset, creator, asset]);

   return (<>
            <IconButton
                  variant={"icon"}
                  size={["sm", "sm", "sm", "lg"]}
                  p={"0 !important"}
                  onClick={onOpen}
                  aria-label={createAssetAriaLabel}
                  title={createAssetAriaLabel}
                  icon={<BsPlusLg/>}
            />
            <Modal isOpen={isOpen}
                   onClose={onClose}
                   onCloseComplete={() => setAsset({})}
                   closeOnOverlayClick={false}
                   closeOnEsc={false}
                   initialFocusRef={assetCreatorRef}
                   size={{
                      base: "full",
                      md: "2xl"
                   }}
                   isCentered>
               <ModalOverlay/>
               <ModalContent
                     width="calc(80vh * 0.778)"
                     minH="80vh">
                  <ModalHeader>
                     {t("Let's create!")}
                  </ModalHeader>
                  <ModalCloseButton onClick={onClose} border={0}/>
                  <ModalBody>
                     <Flex
                           backgroundColor="pure-white"
                           rounded="base"
                           direction="column"
                           gap={1}
                           width="100%"
                           minHeight="70vh"
                           p={2}
                           textAlign="left">
                        {asset ?
                              <>
                                 <Flex gap={1} alignItems="center">
                                    <Center ml={0} boxSize={5}>
                                       <Menu>
                                          <MenuButton
                                                size={"2xs"}
                                                border={0}
                                                rounded="base"
                                                p={hasAssetType ? 0 : .5}
                                                as={IconButton}
                                                aria-label={t("Choose the type of content you are creating...")}
                                                icon={
                                                   hasAssetType ?
                                                         <Center boxSize={4}><AssetIcon
                                                               assetType={asset.assetType}/></Center> :
                                                         <FaQuestion/>
                                                }
                                                bg={hasAssetType ? "none" : undefined}
                                          />
                                          <MenuList>
                                             {ALL_ASSET_TYPES.filter((t) => NOT_CREATABLE.indexOf(t as AssetType) === -1).map((at, i) =>
                                                   <MenuItem key={i}
                                                             icon={<Center boxSize={6}><AssetIcon
                                                                   assetType={at}/></Center>}
                                                             onClick={() => set("assetType", at)}>{t(at)}</MenuItem>
                                             )}
                                          </MenuList>
                                       </Menu>
                                    </Center>
                                    <Center mx={1} justifyContent={"flex-start"} flex={1}>
                                       <StatusLabel status={"DRAFT"}/>
                                    </Center>
                                    <Center boxSize={4}>
                                       <VisibilityIcon visibility={"PRIVATE"}/>
                                    </Center>
                                    <Avatar textAlign="center" size="2xs" title={userProfile?.title}/>
                                 </Flex>
                                 {coverImages.length === 0 ?
                                       <Avatar
                                             width={"100%"}
                                             height={"30vh"}
                                             borderRadius={"sm"}
                                             title={asset.title || t("What is it then?")}
                                             name={asset.title || t("What is it then?")}
                                             getInitials={(s) => s}/>
                                       : <Images height={"45%"} sources={coverImages}/>
                                 }
                                 {(asset.host || asset.path) &&
                                       <Text ml={1} title={url} size={"xs"} noOfLines={1}
                                             color={"blackAlpha.600"}>{url}</Text>}
                                 <Heading noOfLines={1} as='h3' fontWeight="thin" size="xs">
                                    <SingleSelect placeholder={t(asset.assetType as string || "Classifier")}
                                                  isDisabled={!hasAssetType}
                                                  isSlim
                                                  allowEmptyQuery
                                                  selection={classifier}
                                                  useQuery={useClassificationApiResourceFindQuery}
                                                  queryBuilder={(query: string) => ({
                                                     query: query || "",
                                                     classifiersOnly: true,
                                                     forDomains: [asset.assetType?.toUpperCase()],
                                                     limit: 10
                                                  })}
                                                  labelSelector={(cat: Classification) => cat.Name}
                                                  valueSelector={(cat: Classification) => cat.Slug}
                                                  onChange={(newValue: Classification) => set("classifier", newValue ? newValue.Slug : null)}/>
                                 </Heading>
                                 <Heading noOfLines={1} as='h2' size="sm">
                                    <InputText ref={assetCreatorRef} placeholder={t("Title")} value={asset.title}
                                               onChange={(newValue: string) => set("title", newValue)}/>
                                 </Heading>
                                 <TextArea width={"100%"} placeholder={t("Description")} value={asset.description}
                                           onChange={(newValue: string) => set("description", newValue)}/>
                              </>
                              : <></>
                        }
                     </Flex>
                  </ModalBody>
                  <ModalFooter>
                     <ButtonGroup size={"sm"} width="100%" justifyContent={"flex-end"}>
                        <Button
                              aria-label={saveLabel}
                              onClick={() => save()}
                              isDisabled={isLoading || !asset.title || !asset.classifier || !ALL_ASSET_TYPES.includes(asset.assetType as string)}
                              leftIcon={<IoMdSave/>}>{saveLabel}</Button>
                        {assemblageUuid &&
                              <Button
                                    aria-label={saveAndAddLabel}
                                    onClick={() => save(assemblageUuid)}
                                    isDisabled={isLoading || !asset.title || !asset.classifier || !ALL_ASSET_TYPES.includes(asset.assetType as string)}
                                    leftIcon={<MdSaveAlt/>}>{saveAndAddLabel}</Button>
                        }
                     </ButtonGroup>
                  </ModalFooter>
               </ModalContent>
            </Modal>
         </>
   )

}
