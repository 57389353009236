import * as React from "react"
import {useDeferredValue, useEffect, useMemo, useRef, useState} from "react"
import {
   Box,
   Button,
   FormControl,
   FormLabel,
   Icon,
   IconButton,
   Input,
   InputGroup,
   InputRightElement,
   Slide,
   Spinner,
   Text,
   Tooltip,
   useBoolean,
   VStack
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {BiPencil} from "react-icons/bi";
import {MdClear} from "react-icons/md";
import {SingleSelectProps} from "../../../types";

export const SingleSelect: React.FC<any> = ({
                                               placeholder,
                                               label,
                                               isDisabled,
                                               labelSelector,
                                               emptySuggestionsMessage,
                                               selection,
                                               onChange,
                                               queryBuilder,
                                               useQuery,
                                               onStartEditing,
                                               onStopEditing,
                                               isSlim,
                                               allowEmptyQuery
                                            }: SingleSelectProps) => {

   const displayTextSelector = useMemo(() => labelSelector || ((e: unknown) => e), [labelSelector]);

   const {t} = useTranslation();
   const [query, setQuery] = useState("");
   const [selectedValue, setSelectedValue] = useState(null);
   const [isEditing, {
      on,
      off
   }] = useBoolean(false);
   const deferredQuery = useDeferredValue(query);
   const ref = useRef<HTMLDivElement>(null);

   useEffect(() => {
      setQuery(selection ? displayTextSelector(selection) : "")
      setSelectedValue(selection)
   }, [selection, displayTextSelector])

   const tooltip = t("Click here to edit the content");

   const fetchQuery = queryBuilder?.(deferredQuery);

   const {
      data,
      isLoading,
      isFetching
   } = useQuery?.(queryBuilder ? fetchQuery : {deferredQuery}, {skip: (!allowEmptyQuery ? !deferredQuery : false) || !isEditing || (queryBuilder && !fetchQuery)});

   const handleChangeValue = (selectedItem: any) => {

      try {
         setSelectedValue(selectedItem)
         setQuery(selectedItem ? displayTextSelector(selectedItem) : "");
         onChange?.(selectedItem);
      } catch (e) {
         console.trace("Component caught error onChange, skipping value update", e)
      }
   }

   let editTimeout: any;

   const startEditing = () => {
      if (!isDisabled) {
         if (editTimeout) {
            clearTimeout(editTimeout);
            editTimeout = null;
            onStartEditing?.()
         }
         on();
      }
   }

   const stopEditing = () => {
      if (!editTimeout) {
         editTimeout = setTimeout(() => {
            onStopEditing?.()
            off();
         }, 200);
      }
   }

   const NoSuggestionsFound = useMemo(() =>
         <Text as="i">{emptySuggestionsMessage || t("Oops, I got nothing...")}</Text>, [t, emptySuggestionsMessage]);

   const suggestions = data || [];

   return (
         onChange ?
               <Box>
                  <FormControl fontSize={"inherit"} isDisabled={isDisabled}
                               variant={"single-select" + (isSlim ? "-slim" : "")}>
                     <InputGroup>
                        <Input
                              fontSize={"inherit"}
                              color={"white"}
                              placeholder={placeholder}
                              autoComplete="off"
                              onFocus={startEditing}
                              onBlur={stopEditing}
                              value={isEditing ? query : (selectedValue ? displayTextSelector(selectedValue) : "")}
                              onChange={(e) => setQuery(e.target.value)}
                              onKeyDown={(e) => {
                                 if (e.key === "Enter" && suggestions?.length === 1) {
                                    handleChangeValue(suggestions[0])
                                 } else if (e.key === "Escape") {
                                    e.stopPropagation();
                                    setQuery("");
                                    e.target.blur();
                                    stopEditing();
                                 }
                              }}/>
                        {(!allowEmptyQuery ? deferredQuery : true) && isEditing &&
                              <InputRightElement width='3rem' alignItems="center">
                                 <IconButton
                                       size="sm"
                                       border="none"
                                       color={"inherit"}
                                       bg={"inherit"}
                                       rounded={"full"}
                                       onClick={() => handleChangeValue(null)}
                                       isLoading={isLoading || isFetching}
                                       icon={<MdClear/>}
                                       aria-label={t("Clear the current search")}/>
                              </InputRightElement>
                        }
                        <Tooltip isDisabled={isDisabled} color="pure-white" label={tooltip} colorScheme="primary">
                           <FormLabel onClick={startEditing}
                                      fontSize={"inherit"}
                                      fontWeight={"inherit"}
                                      color={"inherit"}
                                      cursor={isDisabled ? "not-allowed" : "pointer"}
                           >{selectedValue && !isEditing ? displayTextSelector(selectedValue) : (label || placeholder)}&nbsp;&nbsp;{!isDisabled && !isEditing &&
                                 <Icon verticalAlign={"text-top"} fontSize={"initial"}
                                       cursor={"pointer"}><BiPencil/></Icon>}
                           </FormLabel>
                        </Tooltip>
                     </InputGroup>
                     <Box position="relative" zIndex="dropdown" ref={ref} maxWidth={280}>
                        <Slide direction="bottom"
                               in={isEditing && ((isLoading || isFetching) || !!((allowEmptyQuery || deferredQuery) && suggestions))}
                               style={{
                                  position: "fixed",
                                  maxWidth: 280,
                                  top: (ref?.current?.getBoundingClientRect().y || 0) + 7 + "px",
                                  left: (ref?.current?.getBoundingClientRect().x || 0) + "px",
                                  height: (isEditing ? undefined : 0)
                               }}>
                           {isEditing && ((isLoading || isFetching) || !!((allowEmptyQuery || deferredQuery) && suggestions)) &&
                                 <VStack
                                       color={"black"}
                                       fontSize={"md"}
                                       borderWidth={1} borderColor={"black"} alignItems="flex-start" maxH={200}
                                       overflow="auto" bg="pure-white"
                                       rounded="lg" p={2} minW="25ch"
                                       onScroll={startEditing}
                                       onBlur={stopEditing}>
                                    {
                                       (isLoading || isFetching) ?
                                             <Spinner size='sm'/>
                                             :
                                             suggestions?.length ?
                                                   suggestions.map((sug: any, i: number) =>
                                                         <Button key={i}
                                                                 minW="25ch"
                                                                 minH="32px"
                                                                 width={"100%"}
                                                                 justifyContent={"flex-start"}
                                                                 px={2}
                                                                 title={displayTextSelector(sug)}
                                                                 border={0}
                                                                 bg={selectedValue === sug ? "primary.500" : "pure-white"}
                                                                 size={"sm"}
                                                                 overflow={"hidden"}
                                                                 textOverflow={"ellipsis"}
                                                                 onClick={() => {
                                                                    handleChangeValue(sug)
                                                                    stopEditing();
                                                                 }}>{displayTextSelector(sug)}</Button>)
                                                   :
                                                   NoSuggestionsFound
                                    }
                                 </VStack>
                           }
                        </Slide>
                     </Box>
                  </FormControl>
               </Box>
               :
               <>{selection ? displayTextSelector(selection) : ""}</>
   )
}

