import {HumanizeDuration, HumanizeDurationLanguage} from 'humanize-duration-ts';
import i18n from "i18next";
import {SearchScope, StageLayout, Status, Visibility} from "../api";
import {AnyAsset, AssetType, UserState} from "../types";
import CONFIG from "../config.json";
import {User, UserManager} from "oidc-client-ts";

const htmlToFormattedText = require("html-to-formatted-text");

const langService: HumanizeDurationLanguage = new HumanizeDurationLanguage();
const duration: HumanizeDuration = new HumanizeDuration(langService);

const PATH_TO_ASSET_TYPE: { [key: string]: AssetType } = {
   "wl": AssetType.WebLink,
   "ws": AssetType.WebSite,
   "up": AssetType.UserProfile,
   "wa": AssetType.What,
   "wo": AssetType.Who,
   "wn": AssetType.When,
   "wr": AssetType.Where,
   "th": AssetType.Thread,
   "co": AssetType.Collection,
}

const CMS_SORT_BY: { [key: string]: string } = {
   "Title": "title",
   "Created On": "createdOn",
   "Last Modified On": "lastModifiedOn",
   "Hits": "hits"
}

const ASSET_TYPE_TO_PATH = Object.fromEntries(Object.entries(PATH_TO_ASSET_TYPE).map(e => [e[1], e[0]]));

const SEARCH_SCOPE_TO_PATH: { [key in SearchScope]: string } = {
   DEFAULT: 'atlas',
   GLOBAL: 'search',
   HOME: 'dashboard',
   WORKSPACE: 'workspace',
}

const UUID_PATTERN = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

const PATH_TO_SEARCH_SCOPE: { [key: string]: string } = Object.fromEntries(Object.entries(SEARCH_SCOPE_TO_PATH).map(e => [e[1], e[0]]));

class Utils {
   static log(arg: any) {
      console.log(arg)
      return arg
   }

   static getCurrentLanguage() {
      return (i18n.resolvedLanguage || 'en').substring(0, 2).toLowerCase();
   }

   static duration(time: number): string {

      return duration.humanize(time,
            {
               language: Utils.getCurrentLanguage(),
               units: ['s', 'ms']
            }
      );
   }

   static html2Text(html: string): string {

      return htmlToFormattedText(html);
   }

   static stringsToVisibilities(visibilities: string[]): Visibility[] | undefined {
      if (visibilities && visibilities.length > 0) {
         return visibilities.map(v => v as Visibility)
      }
      return undefined;
   }

   static stringsToStatus(status: string[]): Status[] | undefined {
      if (status && status.length > 0) {
         return status.map(s => s as Status)
      }
      return undefined;
   }

   static arrayOrUndef(array: any): any[] | undefined {
      if (array instanceof Array && array.length > 0) {
         return array;
      }
      return undefined;
   }

   static assetTypeToPath(assetType: AssetType): string {

      return ASSET_TYPE_TO_PATH[assetType];
   }

   static pathToAssetType(pathVariable: string | undefined, fallback?: AssetType): AssetType | undefined {

      return (pathVariable ? PATH_TO_ASSET_TYPE[pathVariable] : undefined) || fallback;
   }

   static searchScopeToPath(scope: SearchScope): string {

      return SEARCH_SCOPE_TO_PATH[scope];
   }

   static pathToSearchScope(pathVariable: string | undefined): SearchScope | undefined {

      return pathVariable ? PATH_TO_SEARCH_SCOPE[pathVariable] as SearchScope : undefined;
   }

   static cmsSortByToField(sortBy: string | undefined): string | undefined {

      return sortBy ? CMS_SORT_BY[sortBy] : undefined;
   }

   static isUuid(uuid: string | undefined): boolean {

      return !!uuid && UUID_PATTERN.test(uuid);
   }

   // eslint-disable-next-line
   static isWeb(assetType: AssetType): boolean {
      switch (assetType) {
         case AssetType.WebLink:
         case AssetType.WebSite:
            return true
      }

      return false;
   }

   static isKnot(assetType?: AssetType | string): boolean {
      if (assetType) {
         switch (assetType) {
            case AssetType.Knot:
            case AssetType.What:
            case AssetType.When:
            case AssetType.Where:
            case AssetType.Who:
               return true
         }
      }

      return false;
   }

   static getCoverImages(asset?: AnyAsset, useDefaultLogo?: boolean) {

      if (asset?.assetType === AssetType.WebLink && asset.classifier?.includes("image")) {
         return Array(this.getAssetUrl(asset))
      }

      const sources = asset?.stages?.filter(s => s.layout === ("COVER_IMAGE" as StageLayout)).map(s => this.getAssetUrl(s.webLink));

      return Array(sources && sources.length ? sources : useDefaultLogo ? "/logo.png" : "").filter(e => !!e).flat() as string[];
   };

   static safeCoverImage(index: number, asset?: AnyAsset) {
      const flat = this.getCoverImages(asset);

      if (flat && flat.length > index) {
         return flat[index];
      }

      return undefined
   };

   static defUnboundFunc() {
      throw new Error("Not defined")
   }

   static normalizeSearchQuery(query: string | undefined): string {

      const normalizedQuery = ((query ? query : "").replace(/\*+$/, "")).trim();

      if (Utils.isUuid(normalizedQuery)) {
         return normalizedQuery;
      }

      return normalizedQuery.split(/ +/g).join(" ") + "*";
   }

   static getAssetUrl(asset?: AnyAsset): string {
      if (asset?.repositoryFileUrl) {
         return asset?.repositoryFileUrl;
      }

      return asset?.url || "";
   }

   static uuid() {
      return Utils.s4() + Utils.s4() + '-' + Utils.s4() + '-' + Utils.s4() + '-' +
            Utils.s4() + '-' + Utils.s4() + Utils.s4() + Utils.s4();
   }

   static wrapAsset(asset?: AnyAsset, assetUuid?: string) {
      return asset ? asset : {uuid: assetUuid};
   }

   static unwrapAsset(asset?: AnyAsset, assetUuid?: string) {
      return asset ? asset.uuid : assetUuid;
   }

   static async dataURItoBlob(url: string): Promise<Blob> {

      return await (await fetch(url)).blob();
   }

   static toUrl(url: string) {
      try {
         return new URL(url).toString()
      } catch (e) {
         return false;
      }
   }

   static getUser() {
      const oidcStorage = sessionStorage.getItem(`oidc.user:${CONFIG.OIDC_ENDPOINT}:${CONFIG.OIDC_CLIENT_ID}`);
      if (!oidcStorage) {
         return null;
      }
      return User.fromStorageString(oidcStorage);
   }

   static getUserManager(): UserManager {
      const um: UserManager = new UserManager({
         authority: CONFIG.OIDC_ENDPOINT,
         client_id: CONFIG.OIDC_CLIENT_ID,
         redirect_uri: CONFIG.OIDC_REDIRECT_URI
      });
      return um;
   }

   static loginRedirectWithCurrentPageState(): void {
      const um: UserManager = this.getUserManager();
      const pageState: UserState = {
         targetPage: window.location.pathname,
      };
      um.removeUser();
      um.signinRedirect({
         state: pageState
      });
   }

   static toHashtable(param: any, key?: Function, transform?: (val: any) => any) {

      return Array(param || []).flat(1).filter(e => !!e).reduce((r: any, el: any) => {
         r[key ? key(el) : el.key] = transform ? transform(el) : el;
         return r;
      }, {})
   }

   static merge(initialHashtable: any, hashtable: any) {

      // remove keys missing in the new hashtable
      for (const k in initialHashtable) {
         if (!(k in hashtable)) {
            delete initialHashtable[k];
         }
      }

      return Object.keys(initialHashtable).length ? {...initialHashtable, ...hashtable} : hashtable;
   }

   static rad2deg = (rad: number) => (rad * 180.0) / Math.PI;

   private static s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
   }

}

export default Utils;


