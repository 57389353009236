import * as React from "react";
import {useEffect, useState} from "react";
import {
   AccordionPanel,
   Box,
   RangeSlider,
   RangeSliderFilledTrack,
   RangeSliderMark,
   RangeSliderThumb,
   RangeSliderTrack,
   Text
} from "@chakra-ui/react";
import useSearchContext from "../../../hooks/useSearchContext";
import {useTranslation} from "react-i18next";

const BIG_BANG = -14000000000;
const THIS_YEAR = new Date().getFullYear();

export const YearFilterPanel: React.FC<any> = (): React.ReactElement => {

   const {t} = useTranslation();

   const {
      assets,
      fromYear,
      untilYear,
      filterByYear,
      isLoading,
      isFetching,
      isFilterApplied
   } = useSearchContext();
   const {filters} = assets;

   const yearsFilters: { [k: string]: number } = filters?.years;

   const years = Object.keys(yearsFilters);
   const max: number = Object.values(yearsFilters).sort((a, b) => Number(b) - Number(a))[0];
   const defaultMinYear = Number(years[0] || BIG_BANG);
   const defaultMaxYear = Number(years[years.length - 1] || THIS_YEAR);

   const [value, setValue] = useState<number[]>([Math.min(Math.max((isFilterApplied("fromYear") ? fromYear : defaultMinYear) || defaultMinYear, defaultMinYear), defaultMaxYear), Math.max(Math.min((isFilterApplied("untilYear") ? untilYear : defaultMaxYear) || defaultMaxYear, defaultMaxYear), defaultMinYear)]);

   useEffect(() => {
      setValue([defaultMinYear, defaultMaxYear]);
   }, [fromYear, untilYear])

   if (!filters || Object.keys(filters).length === 0) {
      return <></>
   }

   return ((value[0] !== BIG_BANG || value[1] !== THIS_YEAR) ?
               <AccordionPanel pb={4} bg={"primary.100"}>
                  <Box px={2} py={4}>
                     {
                        defaultMinYear === defaultMaxYear ?
                              <Text fontStyle={"italic"}>{t("Filtering by the year of {{year}}", {year: defaultMinYear})} </Text>
                              : <RangeSlider
                                    isDisabled={isLoading || isFetching}
                                    value={value}
                                    min={defaultMinYear}
                                    max={defaultMaxYear}
                                    step={1}
                                    onChangeEnd={(e) => filterByYear(e[0], e[1])}
                                    onChange={(e) => {
                                       setValue(e)
                                    }}>
                                 <RangeSliderMark
                                       mt='2.5' fontSize={"xs"} fontWeight={"bold"}
                                       value={value[0]}
                                       borderRadius={"md"}
                                       borderWidth={"2px"}
                                       bg={"pure-white"}
                                       py={.5}
                                       px={1}
                                       borderColor={"primary.900"}
                                       transform={"translateX(-50%)"}>{value[0]}</RangeSliderMark>
                                 <RangeSliderMark
                                       mt='2.5' fontSize={"xs"} fontWeight={"bold"}
                                       value={value[1]}
                                       borderRadius={"md"}
                                       borderWidth={"2px"}
                                       bg={"pure-white"}
                                       py={.5}
                                       px={1}
                                       borderColor={"primary.900"}
                                       transform={"translateX(-50%)"}>{value[1]}</RangeSliderMark>
                                 <RangeSliderTrack bg='gray.300'>
                                    <RangeSliderFilledTrack bg='primary.900'/>
                                 </RangeSliderTrack>
                                 <RangeSliderThumb boxSize={4} index={0} bg={"primary.500"} borderWidth={"3px"}
                                                   borderColor={"primary.900"}/>
                                 <RangeSliderThumb boxSize={4} index={1} bg={"primary.500"} borderWidth={"3px"}
                                                   borderColor={"primary.900"}/>
                                 {
                                    years.map((year: string) => {
                                       const frac = Math.max((yearsFilters[year]) / max * 30, 3);
                                       return <RangeSliderMark
                                             key={year}
                                             bg={"gray.300"}
                                             mt={-1 * frac + "px"}
                                             width={"2px"}
                                             height={frac + "px"}
                                             value={Number(year)}
                                             title={`$year (${yearsFilters[year]})`}/>;
                                    })

                                 }
                              </RangeSlider>
                     }
                  </Box>
               </AccordionPanel>
               : <></>
   )
}
