import * as React from "react";
import { useRef } from "react";
import { Box, Button, ButtonGroup, Center, chakra, Flex, Link, Spacer, Text, Tooltip } from "@chakra-ui/react";
import { SearchQuery } from "../features/search/SearchQuery";
import { useWebContentApiResourceFetchQuery } from "../../api";
import { Suspense } from "../components/Suspense";
import { FeaturedAssets } from "../features/asset/FeaturedAssets";
import Utils from "../../util/utils";
import { AssetType } from "../../types";
import { motion } from "framer-motion";
import useNavigation from "../hooks/useNavigation";

export const Home: React.FC<any> = (props) => {

  const ref = useRef<HTMLDivElement>(null);

  const cmsHomePage = useWebContentApiResourceFetchQuery<any>({id: "HOME_PAGE", populate: ["FeaturedContents.categories", "HeroAssetImage"]});
  const bgImage = cmsHomePage.data?.HeroAssetImage[Math.floor(Math.random() * cmsHomePage.data?.HeroAssetImage.length)];

  const {navigateToFeaturedContent} = useNavigation();


  return (
    <Suspense until={(bgImage && cmsHomePage.isSuccess) || cmsHomePage.isError}>
      <Flex
        flex={1}
        flexDirection="column"
      >
        <Flex
          backgroundRepeat={"no-repeat"}
          backgroundPosition={"center center"}
          backgroundSize={"cover"}
          backgroundImage={bgImage ? `url('${bgImage?.url}')` : undefined}
          flex={1}
          flexDirection="column"
          minHeight={bgImage ? `${bgImage?.height}px` : "680px"}
          boxShadow={"lg"}
          borderBottomColor={"primary.500"}
          borderBottomWidth={4}
        >
          <Flex
            gap={8}
            p={[2, 4, 6, 8, 10]}
            flex={1}
            flexDirection="column"
            alignItems="center"
          >
            <Center mt={"10em"} position={"sticky"} top={4}>
              <SearchQuery inputWidth={{base: "100%", lg: "640px"}}/>
            </Center>
            <Spacer/>
            {
              cmsHomePage.data &&
              <Box position={"sticky"} bottom={4} right={0} alignSelf={"end"}>
                <Tooltip hasArrow
                         closeDelay={1000}
                         closeOnClick={false}
                         closeOnMouseDown={false}
                         placement={"top-start"}
                         rounded={"lg"}
                         pointerEvents={"auto"}
                         p={2}
                         label={<>
                           <Text mb={1}>{cmsHomePage.data?.HeroLabelTooltipText}</Text>
                           <Link target={"_blank"} fontWeight={"bold"}
                                 href={cmsHomePage.data?.HeroAssetURL}>{cmsHomePage.data?.HeroLabelTooltipAssetURLText}</Link>
                         </>}
                         bg={"white"}>
                  <Text color={cmsHomePage.data?.HeroTextColor || "inherit"} fontSize={["xs", "xs", "md"]}>{cmsHomePage.data?.HeroLabel}</Text>
                </Tooltip>
              </Box>
            }
          </Flex>
          {cmsHomePage?.data?.FeaturedContents?.length > 0 &&
          <ButtonGroup
            bg="blackAlpha.600"
            backdropFilter='auto'
            backdropBlur='4px'
            alignItems={"center"}
            justifyContent={"center"}
            width="100%"
            height="40px"
            isAttached
            variant="toolbar"
            size="sm">
            <chakra.div
              overflow={"auto visible"}
              width={"7xl"}
              height={"100%"}
              className={"hide-scrollbar"}
              cursor={"grab"}
              _active={{
                cursor: "grabbing"
              }}
              ref={ref}>
              <motion.div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  height: "100%",
                  gap: 24,
                  padding: 4,
                  scrollPadding: 4,
                  scrollBehavior: "smooth",
                  scrollSnapAlign: "center",
                  scrollSnapType: "x mandatory",
                  width: "max-content"
                }}
                drag={"x"}
                dragConstraints={ref}
                dragTransition={{
                  bounceDamping: 70,
                  bounceStiffness: 600,
                  timeConstant: 200,
                  power: 0.4
                }}
              >
                {cmsHomePage?.data?.FeaturedContents?.map((fc: any) =>
                  <Button key={fc.Title}
                          flex={"0 0 auto"}
                          aria-label={fc.Title}
                          title={fc.Title}
                          onClick={() => navigateToFeaturedContent(fc)}
                          fontWeight={"hairline"}
                  >{fc.Title}</Button>
                )}
              </motion.div>
            </chakra.div>
          </ButtonGroup>
          }
        </Flex>
        {cmsHomePage?.data?.FeaturedContents?.length > 0 &&
        <Flex flexDir={"column"} gap={12} py={16} boxShadow={"inner"}>
          {cmsHomePage?.data?.FeaturedContents?.map((fc: any) =>
            <FeaturedAssets key={fc.Title}
                            title={fc.Title}
                            fontSize={["xl", "2xl", "3xl", "4xl"]}
                            assetType={fc.Domain || AssetType.Asset}
                            categories={Utils.arrayOrUndef(fc.categories)?.map(c => c.Slug)}
                            sortBy={Utils.cmsSortByToField(fc.SortBy)}
                            sortDescending={fc.SortDescending}
            />)
          }
        </Flex>
        }
      </Flex>
    </Suspense>
  )
}
