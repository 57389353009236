import * as React from "react";
import {Route, Routes} from "react-router-dom";
import {TermsOfUse} from "../pages/TermsOfUse";
import {DataPrivacy} from "../pages/DataPrivacy";
import {Main} from "../layouts/Main";
import {Atlas} from "../pages/Atlas";
import {Home} from "../pages/Home";
import {OAuth2Callback} from "../features/login/OAuth2Callback";
import {Profile} from "../pages/Profile";
import {NotFound} from "../pages/NotFound";
import {Canvas} from "../layouts/Canvas";
import {AssetRenderer} from "../features/asset/AssetRenderer";
import {CookieManagement} from "../pages/CookieManagement";
import {Tutorials} from "../pages/Tutorials";
import {ApiDocs} from "../pages/ApiDocs";
import {Blog} from "../pages/Blog";
import {Status} from "../pages/Status";
import {About} from "../pages/About";
import {Community} from "../pages/Community";

export const AppRoutes: React.FC<any> = (props) => {

   return (
         <Routes>
            <Route path="board/:searchScopePath/:threadUuid" element={<Canvas/>}/>
            <Route path="/" element={<Main/>}>
               <Route path="" element={<Home/>}/>
               <Route path=":searchScopePath" element={<Atlas/>}/>
               <Route path=":searchScopePath/:assetTypePath" element={<Atlas/>}>
                  <Route path=":uuid" element={<AssetRenderer/>}/>
               </Route>
               <Route path="about" element={<About/>}/>
               <Route path="community" element={<Community/>}/>
               <Route path="terms-of-use" element={<TermsOfUse/>}/>
               <Route path="data-privacy" element={<DataPrivacy/>}/>
               <Route path="cookie-management" element={<CookieManagement/>}/>
               <Route path="tutorials" element={<Tutorials/>}/>
               <Route path="apidocs" element={<ApiDocs/>}/>
               <Route path="blog" element={<Blog/>}/>
               <Route path="status" element={<Status/>}/>
               <Route path="me" element={<Profile/>}/>
               <Route path="404" element={<NotFound/>}/>
               <Route path="oauth2/callback" element={<OAuth2Callback/>}/>
            </Route>
         </Routes>
   );
}
