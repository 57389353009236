import CookieConsent from "react-cookie-consent";
import {useWebContentApiResourceFetchQuery} from "../../api";
import {Suspense} from "./Suspense";

export const CmsCookieConsent = () => {

   const cookieConsent = useWebContentApiResourceFetchQuery<any>({id: "COOKIE_CONSENT"});

   return (
         <Suspense until={cookieConsent.isSuccess && cookieConsent.data} fallback={<></>}>
            {cookieConsent.data &&
                  <CookieConsent
                        location="bottom"
                        buttonText={cookieConsent.data.acceptText}
                        cookieName="labCookieConsent"
                        expires={cookieConsent.data.expireDays}>
                     {cookieConsent.data.consentText}
                  </CookieConsent>
            }
         </Suspense>
   )
}
