import * as React from "react";
import { SearchScope } from "../../../api";
import {
  Accordion,
  AccordionItem,
  Button,
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { YearFilterPanel } from "./filters/YearFilterPanel";
import useSearchContext from "../../hooks/useSearchContext";
import { FilterPanel } from "./filters/FilterPanel";
import { FilterHeader } from "./filters/FilterHeader";
import { BiFilterAlt } from "react-icons/bi";
import ToolbarDivider from "../../components/ToolbarDivider";
import { ALL_FILTERS } from "../../../types";

// TODO handle max items
// const MAX_ITEMS = 5;

type AvailableFilters = { name: string, available: any };

export const SearchFilterPanel: React.FC<any> = (props: { scope: SearchScope }) => {

  const {t} = useTranslation();
  const {isOpen, onOpen, onClose} = useDisclosure()
  const {query, assets, isLoading, isFetching, clearFilters} = useSearchContext();
  const {filters, total} = assets;

  const avFilters: AvailableFilters[] =
    Object.entries(filters || [])
          .filter(e => Object.keys(e[1] as {}).length > 0 && ALL_FILTERS.includes(e[0]))
          .map(e => ({
            name: e[0],
            available: Object.entries(e[1] as {})
          }));

  if(!filters || (!avFilters.length && !("years" in filters))) {
    return <></>;
  }

  return (
    <>
      {
        total > 0 && <>
          <Button
            isLoading={isLoading || isFetching}
            rightIcon={<BiFilterAlt/>}
            bg={"transparent"}
            size={"sm"}
            borderWidth={2}
            roundedLeft={0}
            onClick={onOpen}
            aria-label={t("Toggle search filters")}
            title={t("Toggle search filters")}
          >{t('Filters')}</Button>
          <ToolbarDivider/>
        </>
      }
      <Drawer
        isOpen={isOpen}
        placement='right'
        size={['full', 'lg', 'md', 'md']}
        onClose={onClose}
      >
        <DrawerOverlay/>
        <DrawerContent>
          <DrawerCloseButton/>
          <DrawerHeader p={3}>
            <Text noOfLines={2}>{query ? <>{t("Filters for ")}<Text as="i">{query}</Text></> : t("Filters")}</Text>
          </DrawerHeader>
          <DrawerBody p={0}>
            <Accordion allowToggle>
              {"years" in filters && <AccordionItem key={-1}>
                <FilterHeader name={"years"} params={["fromYear", "untilYear"]}/>
                <YearFilterPanel/>
              </AccordionItem>}
              {avFilters.map((e, idx) =>
                <AccordionItem key={idx}>
                  <FilterHeader name={e.name}/>
                  <FilterPanel name={e.name} availableFilters={e.available}/>
                </AccordionItem>
              )}
            </Accordion>
          </DrawerBody>
          <DrawerFooter borderTopWidth='1px'>
            <ButtonGroup>
              <Button
                onClick={() => clearFilters()}
                isLoading={isLoading || isFetching}>
                {t('Clear filters')}
              </Button>
              <Button colorScheme='primary' isLoading={isLoading || isFetching}>{t('Save this search')}</Button>
            </ButtonGroup>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}