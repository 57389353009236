import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { User } from 'oidc-client-ts';
import CONFIG from "../config.json"
import Utils from "../util/utils";

const baseQuery = fetchBaseQuery({
  baseUrl: CONFIG.API_BACKEND_BASEURL,
  prepareHeaders: (headers, {getState, endpoint}) => {
    const user: User | null = Utils.getUser();
    if(user) {
      const token = user?.access_token;
      if(token && user.expires_in && user.expires_in > 5) {
        headers.set("Authorization", `Bearer ${token}`);
      } else {
        Utils.loginRedirectWithCurrentPageState();
      }
    }
    return headers
  },

  paramsSerializer: (params: Record<string, any>) => {

    const searchParams = new URLSearchParams()

    Object.entries(params).forEach(([key, value]) => {
      if(value !== undefined && value !== null && value !== "") {
        if(Array.isArray(value)) {
          if(value.length > 0) {
            value.forEach(value => searchParams.append(key, value.toString()))
          }
        } else {
          searchParams.append(key, value.toString())
        }
      }
    });
    return searchParams.toString()
  }
});
/*const mutex = new Mutex()
 const baseQueryWithReauth: BaseQueryFn<
 string | FetchArgs,
 unknown,
 FetchBaseQueryError
 > = async (args, api, extraOptions) => {
 // wait until the mutex is available without locking it
 await mutex.waitForUnlock()
 let result = await baseQuery(args, api, extraOptions)
 if (result.error && result.error.status === 401) {
 // checking whether the mutex is locked
 if (!mutex.isLocked()) {
 const release = await mutex.acquire()
 try {
 const refreshResult = await baseQuery(
 '/refreshToken',
 api,
 extraOptions
 )
 if (refreshResult.data) {
 api.dispatch(tokenReceived(refreshResult.data))
 // retry the initial query
 result = await baseQuery(args, api, extraOptions)
 } else {
 api.dispatch(loggedOut())
 }
 } finally {
 // release must be called once the mutex should be released again.
 release()
 }
 } else {
 // wait until the mutex is available without locking it
 await mutex.waitForUnlock()
 result = await baseQuery(args, api, extraOptions)
 }
 }
 return result
 }*/

export const ApiSlice = createApi({
  baseQuery: baseQuery,
  endpoints: () => ({})
});

