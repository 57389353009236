import { useAuth } from "react-oidc-context";
import { AnyAsset } from "../../types";

/**
 * Helps on asset access control.
 * TODO Implement
 */
const usePermissions = (asset?: AnyAsset) => {

  const {isAuthenticated, user} = useAuth();

  if(!isAuthenticated || !user) {
    return {
      isGuest: true,
      isReader: false,
      isAuthor: false,
      isAdmin: false,
    }
  }

  return {
    /**
     * Non-auth access.
     */
    isGuest: true, // TODO
    /**
     * The read access for non-owned resources.
     */
    isReader: isAuthenticated && true, // TODO
    /**
     * The write access for non-owned resources.
     */
    isAuthor: isAuthenticated && true, // TODO
    /**
     * Full admin access to the resource.
     */
    isAdmin: isAuthenticated && true, // TODO
    userProfile: {title: "Labirinto"}
  }
}

export default usePermissions;