import * as React from "react";
import { useRef, useState } from "react";
import { Box, Center, CircularProgress, SimpleGrid, Text } from "@chakra-ui/react";
import { Collection, useCollectsApiResourceFindQuery } from "../../../api";
import { Pagination } from "../../components/Pagination";
import { useTranslation } from "react-i18next";
import { AssetCard } from "../asset/AssetCard";

export const CollectionBody: React.FC<any> = (props: { asset: Collection }) => {

  const col = props.asset;

  const {t} = useTranslation();
  const ref = useRef(null);
  const [reqPageNumber, setReqPageNumber] = useState(1);
  const [reqPageSize, setReqPageSize] = useState(10);

  const {data, isLoading, isFetching} = useCollectsApiResourceFindQuery({
    pageNumber: reqPageNumber,
    pageSize: reqPageSize,
    parent: col.uuid
  });

  return <Box ref={ref}>
    {(isLoading || isFetching) && <Center height="100vh" width="100%"><CircularProgress isIndeterminate/></Center>}
    <Text mx={3} as="b" fontSize={["lg", "xl", "2xl", "3xl"]}>{t("Contents")}</Text>
    <Center  my={4} bg={"white"} p={[2, 8, 16]}>
      {data?.contents?.length ?
        <SimpleGrid columns={[1, 1, 1, 2, 3, 4, 5]} gap={12}>
          {data?.contents?.map((asset, i) => <AssetCard key={i} asset={asset}/>)}
        </SimpleGrid>
        :
        <Text size="lg">{t("This collection is empty.")}</Text>
      }
    </Center>
    {data &&
    <Pagination
      refocusOnPageChange={ref}
      total={data.total}
      pageSize={data.pageSize}
      pageNumber={data.pageNumber}
      setPageSize={setReqPageSize}
      setPageNumber={setReqPageNumber}
      isLoading={isLoading}
      isFetching={isFetching}/>}
  </Box>

}
