import {BaseWire, IPin, IWire} from "../index";

export class Wire implements IWire {
   id: string;
   source: IPin;
   target: IPin;
   label: string | undefined;
   data: any

   constructor({
                  id,
                  source,
                  target,
                  label
               }: BaseWire, data?: any) {
      this.id = id;
      this.source = source;
      this.target = target;
      this.label = label;
      this.data = data;
   }

   toProps() {
      const [sourceX, sourceY] = this.source.wireOutCoordinates(this.target);
      const [targetX, targetY] = this.target.wireOutCoordinates(this.source);
      return {
         id: this.id,
         label: this.label,
         sourceX,
         sourceY,
         targetX,
         targetY
      }
   }
}
