import * as React from "react";
import { Badge, Center, Container, Flex, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const ApiDocs: React.FC<any> = (props) => {

  const {t} = useTranslation()

  return (
    <Container>
      <Center boxSize={"100%"}>
        <Flex flexDir={"column"} gap={4} flex={1} bg={"pure-white"} boxShadow={"md"} rounded="base" my={6} p={4}>
          <Heading as={"h1"}>{t("API & Developer Documentation")}<Badge ml={2} colorScheme={"red"}
                                                                        variant={"solid"}>{t("Coming Soon!")}</Badge></Heading>
          <Text>{t("Integrate the Labirinto API into your digital solution to expand horizons.")}</Text>
          <Text>{t("Start delivering knowledge and contextualized contents in a whole different way!")}</Text>
        </Flex>
      </Center>
    </Container>
  )
}