import {Domain, useClassificationApiResourceFindQuery} from "../../api";
import {UseClassificationsParams} from "../../types";
import {useMemo} from "react";
import Utils from "../../util/utils";

const useClassifications = ({
                               slugs,
                               assetType,
                               domains,
                               classifiersOnly,
                               asHashtable
                            }: UseClassificationsParams) => {

   const forDomains: Domain[] = useMemo(() => {
            if (assetType) {
               return [assetType.toUpperCase() as Domain];
            }
            if (domains) {
               return domains;
            }
            return ["ALL"];
         }, [assetType, domains]
   )

   const slugArray = useMemo(() => slugs ? Array(slugs).flat() : [], [slugs]);

   const {
      data,
      isSuccess
   } = useClassificationApiResourceFindQuery({
      forDomains,
      classifiersOnly
   }, {skip: !slugArray.length});

   return useMemo(() => {
      const result = isSuccess ? data?.filter(d => slugArray.includes(d.Slug as string)) : [];
      return asHashtable ? Utils.toHashtable(result, (t: any) => t.Slug, (d: any) => d.Name) : result;
   }, [isSuccess, data, slugArray]);
}

export default useClassifications;
