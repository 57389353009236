import { ComponentStyleConfig } from "@chakra-ui/react";

const Link: ComponentStyleConfig = {
  variants: {
    navlink: {
      borderStyle: 'solid',
      borderBottomWidth: 2,
      borderColor: "transparent",
      fontSize: 'xl',
      fontWeight: "bold",
      py: 1,
      _hover: {
        textDecoration: 'none',
        borderBottomColor: 'primary.600'
      },
      _activeLink: {
        borderBottomColor: 'primary.900'
      }
    },
    button : {
      _hover: {
        textDecoration: 'none',
        bg: "primary.100",
        color: "primary.900"
      },
      borderStyle: 'solid',
      borderWidth: 3,
      borderColor: "primary.900",
      rounded: "md",
      p: 2,
      color: "primary.100",
      fontWeight: "bold",
      bg: "primary.900"
    }
  },
}

export default Link;
