import * as React from "react";
import {MutableRefObject} from "react";
import {
   Box,
   Button,
   ButtonGroup,
   Flex,
   Menu,
   MenuButton,
   MenuItem,
   MenuList,
   Stat,
   StatHelpText,
   StatLabel,
   StatNumber,
   useBreakpoint
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import Utils from "../../util/utils";
import {IoMdArrowDropdown} from "react-icons/io";

const PAGE_MENU_BREAKPOINTS = ["base", "xs", "sm"];

export const Pagination: React.FC<any> = (props: {
   defaultPageSize?: number,
   pageSizeOptions?: number[],
   total: number,
   timeInMillis: number,
   pageSize: number,
   setPageSize: Function,
   pageNumber: number,
   setPageNumber: Function,
   isLoading: boolean,
   isFetching: boolean,
   refocusOnPageChange?: MutableRefObject<any>
}) => {

   const [t] = useTranslation();
   const breakpoint = useBreakpoint();

   const defaultPageSize = props.defaultPageSize || 10;

   const {
      total,
      timeInMillis,
      pageSize,
      pageNumber,
      setPageNumber,
      isLoading,
      isFetching
   } = props;

   const currPage = pageNumber ?? 1;
   const lastPage: number = Math.ceil(total / (pageSize || defaultPageSize));

   const offsets: number[] = [1 - currPage, -1, 0, 1, lastPage - currPage];
   const pages: Set<number> = new Set(offsets.map(n => currPage + n)
         .filter(n => n >= 1 && n <= lastPage));

   const handlePageChange = (page: number) => {
      setPageNumber(page);
      if (props.refocusOnPageChange?.current) {
         props.refocusOnPageChange.current.scrollIntoView(true);
      } else {
         window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
         });
      }
   }

   const pageName = (page: number): String => {
      if (page === 1) {
         return t("First")
      }

      if (page === lastPage) {
         return t("Last")
      }
      return String(page);
   }

   return (
         <Flex gap="4" flexDir={"column"} alignItems="center" justifyContent="center">
            {lastPage > 1 ?
                  PAGE_MENU_BREAKPOINTS.includes(breakpoint) ?
                        <Menu autoSelect={false}>
                           <MenuButton
                                 size="md"
                                 as={Button}
                                 aria-label={t('Asset type filter menu')}
                                 rightIcon={<IoMdArrowDropdown/>}
                                 bg={"pure-white"}
                           >{pageName(pageNumber)}</MenuButton>
                           <MenuList bgColor="blackAlpha.700" rounded="base">
                              {Array.from(pages.values()).map((p, i) =>
                                    <MenuItem key={i} color={p === pageNumber ? "black" : "pure-white"}
                                              bg={p === pageNumber ? "whiteAlpha.900" : "black"}
                                              onClick={() => handlePageChange(p)}>
                                       {pageName(p)}
                                    </MenuItem>
                              )}
                           </MenuList>
                        </Menu> :
                        <ButtonGroup isAttached gap={2}>
                           {Array.from(pages.values()).map((p, i) =>
                                 <Button key={i} isLoading={isLoading || isFetching}
                                         colorScheme={pageNumber === p ? "primary" : "secondary"}
                                         onClick={() => handlePageChange(p)}>{pageName(p)}</Button>)}
                        </ButtonGroup> : <></>
            }
            <Box textAlign={"left"}>
               <Stat textAlign={"center"}>
                  <StatLabel fontSize={"xs"}>{t("Page {{pageNumber}} / {{lastPage}}", {
                     pageNumber,
                     lastPage: Math.max(lastPage, 1)
                  })}</StatLabel>
                  <StatNumber fontSize={"xs"}>{t("{{total}} result(s) found", {total})}</StatNumber>
                  {timeInMillis > 0 &&
                        <StatHelpText
                              fontSize={"xs"}>{t("in {{duration}}", {duration: Utils.duration(timeInMillis)})}</StatHelpText>
                  }
               </Stat>
            </Box>
         </Flex>
   )
}
