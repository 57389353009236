/**
 * Theme extensions should follow the structure given by the default theme.
 *
 * See https://chakra-ui.com/docs/styled-system/theme;
 * and https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/foundations
 *
 * @author Otavio Biasutti, PRODYNA AG
 * @since 2022-07-19
 */

const shadows = {
  outline: "0 0 0 3px rgba(242, 253, 00, 0.6)",
};

export default shadows;