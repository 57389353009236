import * as React from "react";
import {ForwardedRef, useMemo, useRef} from "react";
import {Avatar, Badge, Center, Flex, forwardRef, Heading, Spacer, StyleProps, Text} from "@chakra-ui/react";
import {AssetIcon} from "../../components/icon/AssetIcon";
import {VisibilityIcon} from "../../components/icon/VisibilityIcon";
import {StatusLabel} from "../../components/StatusLabel";
import {AnyAsset, AssetType} from "../../../types";
import {AssetQuickDescription} from "./AssetQuickDescription";
import {AssetQuickActionMenu} from "./AssetQuickActionMenu";
import {Images} from "../../components/editables/Images";
import {MultiSelect} from "../../components/editables/MultiSelect";
import {ContentTypes} from "../../components/editables/ContentTypes";
import DateFormat from "../../components/DateFormat";
import useClassifications from "../../hooks/useClassifications";
import useAnnotations from "../../hooks/useAnnotations";
import {Annotation, Relationship} from "../../../api";
import useNavigation from "../../hooks/useNavigation";
import Utils from "../../../util/utils";
import config from "../../../theme/config";
import {useParams} from "react-router-dom";

/*const CardColors: { [key in AssetType]?: string } = {
 [AssetType.Collection]: "brown.200",
 [AssetType.Thread]: "primary.50",
 [AssetType.What]: "gray.300",
 [AssetType.Where]: "green.300",
 [AssetType.When]: "purple.100",
 [AssetType.Who]: "primary.400",
 [AssetType.UserProfile]: "green.300",
 }*/

export const AssetCard = forwardRef(({
                                        asset,
                                        highlight,
                                        relationship,
                                        presentation,
                                        ...rest
                                     }: { asset: AnyAsset, relationship?: Relationship, highlight?: string, forceMobile?: boolean, presentation?: boolean } & StyleProps,
                                     ref: ForwardedRef<any>) => {

         // TODO: maybe handle it differently?
         const {threadUuid} = useParams();
         const internalRef = useRef(null);

         const {navigateToAsset} = useNavigation();

         const contentTypes = useClassifications({
            slugs: asset.contentTypes,
            assetType: asset.assetType as AssetType
         });
         const tags = useAnnotations({slugs: asset.tags});

         const [classifier] = useClassifications({
            slugs: asset.classifier,
            assetType: asset.assetType as AssetType
         });
         const [relClassifier] = useClassifications({
            slugs: relationship?.classifier,
            domains: ["RELATIONSHIPS"],
            classifiersOnly: true
         });

         const imgSources = useMemo(() => Utils.getCoverImages(asset), [asset]);

         const width = useMemo(() => presentation ? config.presentationCardWidth : config.cardWidth, [presentation]);
         const height = useMemo(() => presentation ? config.presentationCardHeight : config.cardHeight, [presentation]);

         return useMemo(() => {
                  return <Flex
                        ref={ref}
                        {...rest}
                        bg={"pure-white"}
                        borderColor="blackAlpha.600"
                        boxShadow={"md"}
                        borderWidth={1}
                        rounded="base"
                        direction="column"
                        gap={1}
                        minW={width}
                        width={width}
                        height={height - 4}
                        p={presentation ? 1 : 2}
                        textAlign="left">
                     {!presentation && <Flex gap={1} alignItems="center" maxHeight={4}>
                        <Center boxSize={4}>
                           <AssetIcon assetType={asset.assetType}/>
                        </Center>
                        {!!relClassifier ?
                              <>
                                 <Badge position={"absolute"}
                                        verticalAlign={"middle"}
                                        left={"50%"} top={0}
                                        style={{transform: "translate(-50%, -50%)"}}
                                        variant={"classifier"}
                                        fontSize="2xs"
                                 >{relClassifier.Name}</Badge>
                                 <Spacer/>
                              </>
                              :
                              <Center mx={1} justifyContent={"flex-start"} flex={1}>
                                 <StatusLabel status={asset.status}/>
                              </Center>
                        }
                        <Center boxSize={4}>
                           <VisibilityIcon visibility={asset.visibility}/>
                        </Center>
                        <Avatar textAlign="center" size="2xs" title={asset.ownerUuid}/>
                        {!threadUuid && <AssetQuickActionMenu asset={asset}/>}
                     </Flex>}
                     {imgSources.length === 0 ?
                           <Avatar
                                 width={"100%"}
                                 height={presentation ? "65%" : "45%"}
                                 borderRadius={"sm"}
                                 title={asset.title}
                                 name={asset.title}
                                 getInitials={(s) => s}
                                 onClick={() => !threadUuid && navigateToAsset(asset)}
                                 cursor={"pointer"}
                                 overflow={"hidden"}
                           />
                           : <Images
                                 onClick={() => !threadUuid && navigateToAsset(asset)}
                                 height={presentation ? "65%" : "45%"}
                                 sources={imgSources}/>
                     }
                     <Flex color="gray.500" gap={2} alignItems={"center"}>
                        {(classifier?.Name || asset.classifier) && <>
                           <Heading noOfLines={1} as='h3' fontWeight={"thin"}
                                    title={classifier?.Name || asset.classifier}
                                    fontSize={presentation ? "2xs" : "xs"}>{classifier?.Name || asset.classifier}</Heading>
                           {!presentation && <Text fontWeight={"thin"} fontSize={"xs"}>&bull;</Text>}
                        </>
                        }
                        {!presentation && <DateFormat fontWeight={"thin"} fontSize={"xs"} value={asset.createdOn}/>}
                     </Flex>
                     <Heading noOfLines={presentation ? 2 : 1} title={asset.title} as='h2'
                              size={presentation ? "xs" : "sm"}>{asset.title}</Heading>
                     {!presentation && <>
                        <AssetQuickDescription asset={asset} highlight={highlight} fontSize="xs"/>
                        <Flex justifyContent={"space-between"} alignItems={"center"} mt={"auto"}>
                           <MultiSelect
                                 values={tags}
                                 valueSelector={(cat: Annotation) => cat.Slug}
                                 labelSelector={(cat: Annotation) => cat.Value}
                                 maxWidth={"180px"} maxElements={2}/>
                           <ContentTypes values={contentTypes} maxWidth={"200px"} maxElements={5}/>
                        </Flex>
                     </>}
                  </Flex>;
               }, [contentTypes, asset, highlight, presentation, classifier, imgSources, navigateToAsset, relClassifier, ref, internalRef, width, height, rest, tags]
         )
      }
)
