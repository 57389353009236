import {IconButton} from "@chakra-ui/react";
import {HiChevronDoubleDown, HiChevronDoubleUp} from "react-icons/hi";
import * as React from "react";

export function CollapsibleButton(props: { reverse?:boolean, showing: boolean, ariaLabel: any, onClick: () => void }) {
   return <IconButton width={"48px"}
                      height={"16px"}
                      position={"absolute"}
                      top={props.showing && !props.reverse ? "100%" : 0} left={"50%"}
                      transform={"translate(-50%, 0)"}
                      bg={"blackAlpha.900"}
                      color={"white"}
                      m={0}
                      pb={1}
                      roundedTop={"none"}
                      transition={"all .2s ease"}
                      border={0} outline={0}
                      aria-label={props.ariaLabel} title={props.ariaLabel} size="xs" onClick={props.onClick}
                      icon={props.showing && !props.reverse ? <HiChevronDoubleUp/> : <HiChevronDoubleDown/>}/>;
}
