import * as React from "react";
import {useCallback, useRef, useState} from "react";
import {
   AlertDialog,
   AlertDialogBody,
   AlertDialogCloseButton,
   AlertDialogContent,
   AlertDialogFooter,
   AlertDialogHeader,
   AlertDialogOverlay,
   Button,
   Text,
   useDisclosure
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {ConfirmationContextType, ConfirmProps} from "../../types";

const UseConfirmationContext = React.createContext<ConfirmationContextType>(() => {
   throw Error("Confirmation context not yet initialized.");
});

const useConfirmationContext = () => React.useContext<ConfirmationContextType>(UseConfirmationContext);

const ConfirmationContextProvider = ({children}: React.PropsWithChildren) => {

   const {t} = useTranslation();
   const {
      isOpen,
      onClose,
      onOpen
   } = useDisclosure();
   const cancelRef = useRef<HTMLButtonElement>(null);

   const [title, setTitle] = useState<string>("");
   const [message, setMessage] = useState<string>("");
   const [onConfirm, setOnConfirm] = useState<Function>();
   const [onCancel, setOnCancel] = useState<Function>();
   const [confirmLabel, setConfirmLabel] = useState<string>();
   const [cancelLabel, setCancelLabel] = useState<string | false>();
   const [showCloseButton, setShowCloseButton] = useState<boolean>();

   const confirm = useCallback(({
                                   title,
                                   message,
                                   onConfirm,
                                   onCancel,
                                   cancelLabel,
                                   confirmLabel,
                                   showCloseButton
                                }: ConfirmProps) => {
      setTitle(title || "");
      setMessage(message || "");
      setOnConfirm(() => onConfirm);
      setOnCancel(() => onCancel);
      setCancelLabel(cancelLabel);
      setConfirmLabel(confirmLabel);
      setShowCloseButton(showCloseButton);
      onOpen();
   }, [onOpen]);

   return (
         <UseConfirmationContext.Provider value={confirm}>
            {children}
            <AlertDialog
                  isOpen={isOpen}
                  leastDestructiveRef={cancelRef}
                  onClose={onClose}
                  isCentered
                  closeOnEsc={!!showCloseButton}
            >
               <AlertDialogOverlay>
                  <AlertDialogContent overflow={"hidden"}>
                     <AlertDialogHeader
                           fontSize='2xl'
                           fontWeight='bold'>
                        {title || t("Are you sure?")}
                     </AlertDialogHeader>
                     {showCloseButton && <AlertDialogCloseButton/>}
                     <AlertDialogBody bgColor={"primary.50"}>
                        {message.split("\n").map((p, i) => <Text key={i}
                                                                 mx={2} my={4}>{p}</Text>)}
                     </AlertDialogBody>

                     <AlertDialogFooter>
                        {cancelLabel !== false &&
                              <Button size={"sm"} ref={cancelRef} colorScheme="secondary" onClick={() => {
                                 onCancel?.();
                                 onClose();
                              }}>
                                 {cancelLabel || t("Cancel")}
                              </Button>
                        }
                        <Button size={"sm"} colorScheme="primary" onClick={() => {
                           onConfirm?.();
                           onClose();
                        }} ml={3}>
                           {confirmLabel || t("Confirm")}
                        </Button>
                     </AlertDialogFooter>
                  </AlertDialogContent>
               </AlertDialogOverlay>
            </AlertDialog>
         </UseConfirmationContext.Provider>
   )
}

export default useConfirmationContext;
export {ConfirmationContextProvider}
