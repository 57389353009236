import {Center, chakra, CircularProgress, useBoolean} from "@chakra-ui/react";
import {motion, useInView} from "framer-motion";
import * as React from "react";
import {PropsWithChildren, ReactElement, useEffect, useRef, useState} from "react";
import config from "../../theme/config";

const GAP = 24;
/**
 * Key (React.Element.key) of the supplied children (including lazy loaded ones) must be unique. The parentId prop can be used to reset the list.
 * @param props
 * @constructor
 */
export const HorizontalInfiniteScroll: React.FC<any> = ({
                                                           parentId,
                                                           onChange,
                                                           children
                                                        }: { parentId: string, onChange: Function } & PropsWithChildren) => {

   const [isLoading, {
      on,
      off
   }] = useBoolean(false);
   const [hasLoadedAll, setHasLoadedAll] = useState(false);
   const [items, setItems] = useState<React.ReactElement[]>([]);

   const ref = useRef<HTMLDivElement>(null);
   const innerRef = useRef<HTMLDivElement>(null);
   const loaderRef = useRef(null);
   const inView = useInView(loaderRef, {amount: "all"});

   useEffect(() => {
      off();
      setHasLoadedAll(false);
      setItems([]);
   }, [parentId]);

   useEffect(() => {

      setItems(curItems => {

         if (!children) {
            off();
            return curItems;
         }

         let array = Array(children).flat(1) as ReactElement[];

         if (array && array.length === 0) {
            off();
            setHasLoadedAll(true);
            return curItems;
         }

         array = array.filter((c) => !curItems.find(i => i.key === c.key));

         if (array && array.length) {
            off();
            return curItems.concat(array);
         }
         return curItems;
      })

   }, [children])

   useEffect(() => {
      if (items && items.length && !isLoading && !hasLoadedAll) {
         on();
         onChange(1);
      }
   }, [inView])

   let timeout: any = null;

   useEffect(() => {
      if (!hasLoadedAll) {
         if (timeout) {
            clearTimeout(timeout);
         }
         setTimeout(() => {
            setHasLoadedAll(true);
            clearTimeout(timeout);

         }, 3000);
      }
   }, [hasLoadedAll])

   return (
         <chakra.div
               overflow={"auto visible"}
               width="100%"
               className={"hide-scrollbar"}
               cursor={"grab"}
               _active={{
                  cursor: "grabbing"
               }}
               ref={ref}>
            <motion.div
                  ref={innerRef}
                  style={{
                     display: "flex",
                     flexWrap: "nowrap",
                     gap: GAP,
                     paddingLeft: GAP,
                     paddingRight: GAP,
                     paddingTop: 12,
                     paddingBottom: 8,
                     scrollPadding: 4,
                     scrollBehavior: "smooth",
                     scrollSnapAlign: "center",
                     scrollSnapType: "x mandatory",
                     width: items.length * config.cardWidth + GAP * (items.length) + GAP,
                     minWidth: "100%"
                  }}
                  drag={"x"}
                  dragConstraints={ref}
                  dragTransition={{
                     bounceDamping: 70,
                     bounceStiffness: 600,
                     timeConstant: 200,
                     power: 0.4
                  }}
            >
               {items}
               {!hasLoadedAll &&
                     <Center ref={loaderRef} w={config.cardWidth} height={"100%"} my={"auto"}><CircularProgress
                           isIndeterminate/></Center>}
            </motion.div>
         </chakra.div>
   )
}
