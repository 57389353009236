import {useTranslation} from "react-i18next";
import {Hide, IconButton, Modal, ModalBody, ModalContent, ModalOverlay, Show, useDisclosure} from "@chakra-ui/react";
import * as React from "react";
import {useRef} from "react";
import {BsSearch} from "react-icons/bs";
import {SearchQuery} from "../search/SearchQuery";

export const AssetSearchBar = () => {

   const {t} = useTranslation();
   const searchDisc = useDisclosure({id: "search"});
   const ref = useRef(null);
   const searchAriaLabel = t("Toggle search field");

   return <>
      <Show below={"md"}>
         <IconButton
               variant={"icon"}
               size={["sm", "sm", "sm", "lg"]}
               p={"0 !important"}
               onClick={searchDisc.onOpen}
               aria-label={searchAriaLabel}
               title={searchAriaLabel}
               icon={<BsSearch size={"1em"}/>}
         />
         <Modal isOpen={searchDisc.isOpen} onClose={searchDisc.onClose} closeOnOverlayClick={true} closeOnEsc={true}
                initialFocusRef={ref}>
            <ModalOverlay/>
            <ModalContent rounded={0}>
               <ModalBody>
                  <SearchQuery inputWidth={{
                     base: "100%",
                     xl: 360
                  }} onSearch={searchDisc.onClose} ref={ref}/>
               </ModalBody>
            </ModalContent>
         </Modal>
      </Show>
      <Hide below="md">
         <SearchQuery inputWidth={{
            base: 240,
            xl: 360
         }} ref={ref}/>
      </Hide>
   </>
};
