import { ComponentStyleConfig } from "@chakra-ui/react";

const bullet = {
  content: "'\\2022'",
  px: 2,
}

const Badge: ComponentStyleConfig = {
  variants: {
    classifier: {
      color: "white",
      bg: "black",
      rounded: "full",
      maxWidth: 180,
      textOverflow: "ellipsis",
      overflow: "hidden",
      p: 1,
      _before: bullet,
      _after: bullet
    }
  }
}

export default Badge;