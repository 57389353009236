import * as React from "react"
import { useState } from "react"
import { HStack, Stack, Tag, TagCloseButton, TagLabel, Text, Wrap, WrapItem } from "@chakra-ui/react";
import { ContentTypesProps } from "../../../types";
import { useTranslation } from "react-i18next";
import { ContentTypeIcon } from "../icon/ContentTypeIcon";
import { AutoComplete } from "./AutoComplete";
import { Classification, useClassificationApiResourceFindQuery } from "../../../api";

const NoContentTypesFound = React.memo(() => {
  const {t} = useTranslation();
  return <Text as="i">{t("No content types found.")}</Text>
});

export const ContentTypes: React.FC<any> = (props: ContentTypesProps) => {

  const {t} = useTranslation();
  const [values, setValues] = useState(props.values || [] as Classification[]);
  const [query, setQuery] = useState("");

  const {data, isLoading, isFetching} = useClassificationApiResourceFindQuery({
    query,
    forDomains: ["WEBLINK"],
    classifiersOnly: true,
    limit: 10
  });

  const maxElements = props.maxElements || values.length;
  const isEllipsis = maxElements && props.maxWidth;

  const render = props.values?.length || props.onChange;
  const isEditable = props.onChange

  const handleSubmit = (value: Classification, remove?: boolean) => {

    const newValues: Classification[] = remove ? values.filter(v => v.Slug !== value.Slug) : [...values, value];

    try {
      props.onChange?.(newValues.map(v => v.Slug));
      setValues(newValues)
      if(!remove) {
        setQuery("");
      }
    } catch(e) {
      console.trace("Component caught error onChange, skipping value update", e)
    }
  }

  const ViewWithEllipsis = () => <HStack mr={3} maxWidth={props.maxWidth || "100%"}>
    {values.filter((t, i) => i < maxElements).map((ct, i) =>
      <Tag key={i} colorScheme={"primary"} rounded={"full"} size={{base: "sm", md: "md"}} aria-label={ct.Name}
           title={ct.Name} lineHeight={{base: 1.5, md: 1.75}}>
        <ContentTypeIcon contentType={ct}/>
      </Tag>
    )}
  </HStack>

  const ViewFull = () => values.length ? <Wrap>
      {values.map((ct, i) =>
        <WrapItem key={i}>
          <Tag colorScheme={"primary"}
               rounded={"full"} size={{base: "sm", md: "md"}} aria-label={ct.Name}
               title={ct.Name}>
            <TagLabel>
              <ContentTypeIcon contentType={ct}/>
            </TagLabel>
            {isEditable &&
            <TagCloseButton onClick={() => handleSubmit(ct, true)}/>}
          </Tag>
        </WrapItem>
      )}
    </Wrap>
    : props.showLabel ? <NoContentTypesFound/> : <></>

  const suggestions = data?.filter((ct: Classification) => ct.Slug && !values.find(v => v.Slug === ct.Slug)) || [];

  return (
    render ?
      <Stack maxWidth={{base: 280, lg: 560}}>
        {isEditable ?
          <AutoComplete label={isEditable ? t("Content Types") : undefined}
                        labelSelector={(ct: Classification) => ct.Name}
                        isLoading={isLoading || isFetching}
                        suggestions={suggestions}
                        onChange={(newQuery: string) => setQuery(newQuery)}
                        onSubmit={(newValue: Classification) => handleSubmit(newValue, false)}/>
          : props.showLabel && <Text fontWeight="bold">{t("Content Types")}</Text>
        }
        {isEditable || !isEllipsis ?
          <ViewFull/>
          :
          <ViewWithEllipsis/>
        }
      </Stack>
      :
      <></>
  )
}

