import * as React from "react";
import {useEffect, useMemo, useRef} from "react";
import {
   Avatar,
   Box,
   Button,
   ButtonGroup,
   Divider,
   Drawer,
   DrawerBody,
   DrawerCloseButton,
   DrawerContent,
   DrawerFooter,
   DrawerHeader,
   DrawerOverlay,
   Text,
   useDisclosure,
   VStack
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {AssetQuickActionMenu} from "../../features/asset/AssetQuickActionMenu";
import {Images} from "../editables/Images";
import useGraphQueryContext from "../../hooks/useGraphQueryContext";
import Utils from "../../../util/utils";
import {AssetQuickDescription} from "../../features/asset/AssetQuickDescription";

const heights = [150, 200, 250, 300, 350];
export const GraphSelectionDrawer: React.FC<any> = () => {

   const {t} = useTranslation();

   const {
      isOpen,
      onOpen,
      onClose
   } = useDisclosure();
   const ref = useRef(null);

   const {
      selection,
      isOpenRelationships
   } = useGraphQueryContext();

   const drawBody = useRef<HTMLDivElement>(null);

   useEffect(() => {
      if (selection) {
         onOpen();
         setTimeout(() => drawBody?.current?.scrollTo({
            top: 0,
            left: 0,
            behavior: "auto"
         }), 0);
      }
   }, [selection, onOpen, drawBody])

   useEffect(() => {
      if (isOpenRelationships) {
         onClose()
      }
   }, [isOpenRelationships, onClose]);

   const coverImages = useMemo(() => Utils.getCoverImages(selection), [selection]);

   return useMemo(() =>
         <Drawer
               isOpen={!!selection && isOpen}
               placement='right'
               size={['full', 'lg', 'md', 'md']}
               onClose={onClose}
               closeOnEsc={true}
               initialFocusRef={ref}
               autoFocus={false}
         >
            <DrawerOverlay/>
            <DrawerContent>
               <DrawerCloseButton/>
               <DrawerHeader p={3}>
                  <Text noOfLines={1}>{selection?.title}</Text>
               </DrawerHeader>
               <DrawerBody bg={"pure-white"} ref={drawBody}>
                  <VStack ref={ref} width={"100%"}>
                     {coverImages.length === 0 ?
                           <Avatar
                                 width={"100%"}
                                 height={heights}
                                 borderRadius={"sm"}
                                 title={selection?.title}
                                 name={selection?.title}
                                 getInitials={(s) => s}/>
                           :
                           <Images sources={coverImages} height={heights}/>
                     }
                     <AssetQuickDescription asset={selection} fontSize={["xs", "sm", "md", "md"]} noOfLines={false}/>
                  </VStack>
                  <Divider my={4}/>
                  <Text fontWeight={"extrabold"}>{t("Actions")}</Text>
                  <Box position={"relative"} width={"100%"}>
                     <AssetQuickActionMenu asset={selection} isExploded={true} onCloseExploded={onClose}/>
                  </Box>
               </DrawerBody>
               <DrawerFooter borderTopWidth='1px'>
                  <ButtonGroup>
                     <Button colorScheme='primary' onClick={onClose}>{t('Close')}</Button>
                  </ButtonGroup>
               </DrawerFooter>
            </DrawerContent>
         </Drawer>, [onClose, t, selection, coverImages, drawBody, isOpen]
   )
}
