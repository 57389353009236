import {
   Asset,
   Classification,
   Collection,
   CollectionMutationRequest,
   Collects,
   Domain,
   Grants,
   Relationship,
   SearchApiResourceSearchApiArg,
   SetsCustomAttribute,
   Stages,
   Thread,
   ThreadMutationRequest,
   Threads,
   Translates,
   UserProfile,
   UserProfileMutationRequest,
   useSearchApiResourceSearchQuery,
   WebLink,
   WebLinkMutationRequest,
   WebSite,
   WebSiteMutationRequest,
   What,
   WhatMutationRequest,
   When,
   WhenMutationRequest,
   Where,
   WhereMutationRequest,
   Who,
   WhoMutationRequest
} from "../api";
import * as React from "react";
import {StyleProps} from "@chakra-ui/react";
import {Pin} from "../app/components/board/feature/Pin";
import {Wire} from "../app/components/board/feature/Wire";
import {Coordinates} from "../app/components/board";

/* Model Helpers */
export enum SearchScope {
   Atlas = "DEFAULT",
   Dashboard = "USER_HOME",
   Workspace = "USER_WORKSPACE",
   Global = "GLOBAL",
}

export enum AssetType {
   Asset = "Asset",
   WebLink = "WebLink",
   WebSite = "WebSite",
   Collection = "Collection",
   Thread = "Thread",
   UserProfile = "UserProfile",
   GroupProfile = "GroupProfile",
   Knot = "Knot",
   What = "What",
   When = "When",
   Who = "Who",
   Where = "Where"
}

export type AnyAsset =
      Who
      & What
      & When
      & Where
      & Thread
      & Collection
      & UserProfile
      & WebLink
      & WebSite
      & Asset;

export type AnyMutationRequest =
      WhoMutationRequest
      & WhatMutationRequest
      & WhenMutationRequest
      & WhereMutationRequest
      & ThreadMutationRequest
      & CollectionMutationRequest
      & UserProfileMutationRequest
      & WebLinkMutationRequest
      & WebSiteMutationRequest;

export type GraphRelationship = Threads & Collects;

export type AnyRelationship = GraphRelationship & Stages & Translates & Grants & SetsCustomAttribute;

export type AnyKnot = Who & What & When & Where;

/* Component Props */
export interface ContentTypesProps {
   showLabel?: boolean;
   values?: Classification[];
   onChange?: Function;
   maxWidth?: number | string;
   maxElements?: number;
}

export interface MultiSelectProps extends StyleProps {
   useQuery?: Function;
   label?: string;
   values?: any[];
   onChange?: Function;
   queryBuilder?: Function;
   valueSelector?: Function;
   labelSelector?: Function;
   onValueClick?: Function;
   maxWidth?: number | string;
   maxElements?: number;
   justify?: string;

   allowEmptyQuery?: boolean
}

export interface ImagesProps {
   mx?: string | number
   boxSize?: string | string[];
   height?: number | string | number[] | string[];
   sources?: string[];
   onChange?: Function;
   onClick?: Function
}

export interface AssetRelationsProps {
   title: string;
}

export interface FetchQueryProps {
   hook: Function;
   params: any;
   options?: any
}

enum ArrayFilter {
   CATEGORIES = "categories",
   TAGS = "tags",
   CONTENT_TYPES = "contentTypes",
   KEYWORDS = "keywords",
   HOSTS = "hosts",
   OCCUPATIONS = "occupations",
   ORGANIZATIONS = "organizations"
}

export enum RelationshipType {
   COLLECTS = "COLLECTS",
   THREADS = "THREADS",
   STAGES = "STAGES",
   TRANSLATES = "TRANSLATES",
   SETS_CUSTOM_ATTRIBUTE = "SETS_CUSTOM_ATTRIBUTE",
   FOLLOWS = "FOLLOWS",

}

export {ArrayFilter};
const KNOTS = [AssetType.What, AssetType.Who, AssetType.Where, AssetType.When];
type SearchQueryType = SearchApiResourceSearchApiArg;
type SearchResultType = ReturnType<typeof useSearchApiResourceSearchQuery>;
export type SearchContextType =
      SearchQueryType
      & SearchResultType
      & {
   isSearching: boolean,
   reset: Function,
   assetType: string,
   filterBy: Function,
   removeFilterBy: Function,
   filterByYear: Function,
   filterByVisibility: Function,
   removeFilterByVisibility: Function,
   filterByStatus: Function,
   removeFilterByStatus: Function,
   filterByGrantedAccess: Function,
   removeFilterByGrantedAccess: Function,
   clearFilters: Function,
   isFilterApplied: Function,
   setPageNumber: Function,
   setPageSize: Function
};

enum Filter {
   DISTANCE = "distance",
   LATITUDE = "latitude",
   LONGITUDE = "longitude",
}

const SIMPLE_FILTERS: string[] = Object.values(Filter);
const ARRAY_FILTERS: string[] = Object.values(ArrayFilter);
const ALL_FILTERS: string[] = SIMPLE_FILTERS.concat(ARRAY_FILTERS);
export const ALL_ASSET_TYPES: string[] = KNOTS.concat([AssetType.Thread, AssetType.Collection, AssetType.UserProfile, AssetType.WebLink, AssetType.WebSite]);
export {ALL_FILTERS};
export {ARRAY_FILTERS};
export {SIMPLE_FILTERS};
export {Filter};

export interface ContextAction {
   available: EdgeActionDefinition[],
   contexts: ActionFactoryParams[]
}

export interface EdgeActionDefinition {
   allowedEdgeTypes: RelationshipType[]
   allowedStartTypes: AssetType[];
   allowedEndTypes: AssetType[];
   label: string;
   icon: React.ReactElement;
   hasParentContext?: boolean;
   factory: Function;
   performFactory: Function;
}

export interface ActionProps {
   type: string,
   title: string;
   "aria-label": string;
   icon: React.ReactElement;
   onClick: Function;
}

/**
 * The parameters needed to make the call to a target Edge mutator.
 */
export interface ActionFactoryParams {
   startType: AssetType;
   startUuid: string;
   endType: AssetType;
   endUuid: string;
   relationshipType: RelationshipType;
   relationshipUuid?: string; // delete and update actions will need this
   confirmationMessage?: string; // if informed a confirmation pop-up opens before firing the action
   classifier?: String | boolean; // if true or informed the classifier-picker pop-up opens before firing the action
   params: any; // further params needed by the endpoint.
}

export interface ConfirmProps {
   title: string;
   message: string;
   onConfirm: Function;
   onCancel?: Function;

   confirmLabel?: string;
   cancelLabel?: string | false;
   showCloseButton?: boolean;
}

export type LabirintoContextType = {
   thread?: Thread | null;
   center?: AnyAsset | null;
   setLabirintoCenter: Function;
}

export interface UseAnnotationsParams {
   slugs?: string[];
   predefined?: boolean;
}

export interface UseClassificationsParams {
   slugs?: string[] | string;
   assetType?: AssetType;
   domains?: Domain[];
   classifiersOnly?: boolean;
   asHashtable?: boolean
}

export interface NavigationLinksParams {
   disclosure?: any;
   activeOnly?: boolean;
   direction?: "row" | "column";
}

export interface GraphContainerParams {
   assets: AnyAsset[];
   relationships: AnyRelationship[];
   initialSelection?: AnyAsset;
}

export interface UserState {
   targetPage: string;
}

export type ConfirmationContextType = (p: ConfirmProps) => void;
export type EditRelationshipProps = {
   from: AnyAsset,
   to: AnyAsset,
   threadUuid?: string,
   relationshipUuid?: string,
   relationshipVersion?: number,
   relationshipClassifier?: string,
   threadsUuid?: string,
   threadsVersion?: number,
   threadsInverted?: boolean
   onSuccess?: Function
   threadCoordinates?: Coordinates
};
export type RelationshipEditorType = (props: EditRelationshipProps) => void;
export type GraphQueryContextType = {
   isWorking: boolean,
   pins: { [key: string]: Pin },
   wires: { [key: string]: Wire },
   nodeHeight: number,
   nodeWidth: number,
   selection?: AnyAsset,
   setSelection: Function,
   setCoin: Function,
   isCoin: boolean,
   addAssetPin: Function,
   removeAssetPins: Function,
   moveAssetPin: Function,
   addAssetWire: Function,
   editRelationshipWire: Function,
   enableDrag: Function,
   disableDrag: Function,
   isDragEnabled: boolean,
   enableWiring: Function,
   disableWiring: Function,
   isWiringEnabled: boolean,
   openRelationships: Function,
   isOpenRelationships: boolean,
   setDragTarget?: Function,
   dragTarget?: GraphDragTarget,
   setAddCoordinates: Function,
   addCoordinates?: Coordinates,
   isPresentation: boolean,
   setPresentation: Function
}

export type GraphDragTarget = { asset: Asset, relationship: Relationship };

export interface SingleSelectProps {
   selection: any;
   label: string;
   onStartEditing: Function;
   onStopEditing: Function;
   onChange: Function;
   labelSelector: Function;
   valueSelector: Function;
   placeholder?: string;
   isDisabled?: boolean;
   isSlim?: boolean;
   queryBuilder?: Function;
   useQuery?: Function;
   emptySuggestionsMessage?: string,

   allowEmptyQuery?: boolean
}

export interface AutoCompleteProps {
   defaultValue: string;
   label: string;
   onStartEditing: Function;
   onStopEditing: Function;
   onSubmit: Function;
   onChange: Function;
   labelSelector: Function;
   suggestions?: any[];
   isLoading?: boolean;
   emptySuggestionsMessage?: string
   allowEmptyQuery?: boolean
}
