import * as React from "react";
import {useMemo} from "react";
import {Knot, StageLayout, useStagesApiResourceFetchByStartUuidQuery} from "../../../api";
import {Tab, TabList, TabPanel, TabPanels, Tabs, Text} from "@chakra-ui/react";

import {AssetRenderer} from "../asset/AssetRenderer";

/**
 * WhoBody Page must enforce SEO data not only into the header but also use strict html tags to represent its data.
 * @param props
 * @constructor
 */
export const KnotBody: React.FC<any> = ({
                                           asset,
                                           mutator
                                        }: { asset: Knot, mutator: Function }) => {

   // const {isAuthor} = usePermissions({asset: asset})

   const skipFetch = useMemo(() => asset.stages?.length === 0 || !!(asset.stages?.[0]?.webLink), [asset]);

   const {data} = useStagesApiResourceFetchByStartUuidQuery({
      startUuid: asset.uuid!
   }, {
      skip: skipFetch
   });

   const stages = (asset.stages || data || []).filter(s => s.layout === ("BODY" as StageLayout));

   if (!stages.length) {
      return <></>;
   }

   if (stages.length === 1) {
      return <AssetRenderer asset={stages[0].webLink} stagesUuid={stages[0].uuid}/>;
   }

   return (
         <Tabs variant="solid-rounded" colorScheme={"blackAlpha"} bgColor={"primary.500"}>
            <TabList justifyContent="center">
               {
                  stages.map((s, i) =>
                        <Tab rounded={0} key={i}><Text noOfLines={1} maxWidth={"50ch"}>{s.webLink?.title}</Text></Tab>)
               }
            </TabList>
            <TabPanels>
               {
                  stages.map((s, i) =>
                        <TabPanel p={0} key={i}><AssetRenderer asset={s.webLink} stagesUuid={s.uuid}/></TabPanel>)
               }
            </TabPanels>
         </Tabs>
   )
}
