import * as React from "react"
import {useEffect, useState} from "react"
import {
   Button,
   ButtonGroup,
   Center,
   CircularProgress,
   Flex,
   IconButton,
   Image,
   Modal,
   ModalBody,
   ModalContent,
   Slide,
   Spacer,
   Text,
   useBoolean,
   useDisclosure
} from "@chakra-ui/react";
import {ImagesProps} from "../../../types";
import {BiExpand, BiImageAdd} from "react-icons/bi";
import {useTranslation} from "react-i18next";
import {HiOutlineTrash, HiPlus} from "react-icons/hi";
import ToolbarDivider from "../ToolbarDivider";
import {IoMdCheckmark, IoMdClose} from "react-icons/io";
import {MdClose} from "react-icons/md";
import {FaChevronLeft, FaChevronRight} from "react-icons/fa";
import Utils from "../../../util/utils";
import CONFIG from "../../../config.json";

import {ExportInterface} from "react-images-uploading/dist/typings";
import ImageUploading, {ImageListType} from "react-images-uploading";

const FALLBACK_IMG = "/logo.png";

export const Images = ({
                          boxSize,
                          height,
                          onChange,
                          onClick,
                          sources
                       }: ImagesProps) => {

   const {t} = useTranslation();
   const {
      isOpen,
      onClose,
      onToggle
   } = useDisclosure();
   const [isHovering, {
      on,
      off
   }] = useBoolean(false);
   const [showConfirm, setShowConfirm] = useState(false);
   const [selectedIndex, setSelectedIndex] = useState(0);
   const [isLoading, upload] = useBoolean(false);

   const isEmpty = !sources || sources.length === 0;

   const images = sources?.map(url => ({dataURL: url})) || [];
   const isEditable = !!onChange;

   const handleSlide = (i: number) => {
      const jump = (i < 0 ? images.length + i : i) % images.length;
      setSelectedIndex(jump);
   };

   useEffect(() => {
      setSelectedIndex(idx => Math.min(Math.max(idx, 0), (sources?.length || 1) - 1))
   }, [sources])

   const onChangeHandler = async (imageList: ImageListType, addUpdateIndex: number[] | undefined) => {

      if (addUpdateIndex) {
         if (imageList.length > images.length) {
            Promise.all(imageList.filter((img, i) => img.file && addUpdateIndex.includes(i)).map(img => {
               const data = new FormData()
               data.append('file', img.file!)
               upload.on()

               return fetch(`${CONFIG.API_BACKEND_BASEURL}v1/media/repository/upload`, {
                  method: 'POST',
                  body: data,
                  headers: {
                     'Authorization': 'Bearer ' + Utils.getUser()?.access_token,
                  }
               })
            })).then((r) => Promise.all(r.map(r => r.json())).then(dataArray => {
               onChange?.([...dataArray]);
            })).finally(() => upload.off())
         }
      }
   }

   const renderImages = (ctrl?: ExportInterface, isModal?: boolean) => (
         <Center rounded={isModal ? 0 : "base"}
                 position={"relative"}
                 overflow="hidden"
                 boxSize={isModal ? undefined : boxSize}
                 width={"100%"}
                 height={isModal ? "100%" : (height || "100%")}
                 maxH={isModal ? "100vh" : (height || "100%")}
                 minH={isModal ? 0 : (height || "100%")}
                 onMouseEnter={on}
                 onMouseLeave={off}
                 {...ctrl?.dragProps}
         >
            {((isEditable || !isEmpty) && (!isModal || isModal)) &&
                  <Slide direction="top" style={{position: "absolute"}} in={isHovering && !isEmpty}>
                     <ButtonGroup isDisabled={isLoading}
                                  height={isModal ? "40px" : "24px"} px={isModal ? "25%" : 0} bg={"blackAlpha.400"}
                                  isAttached size={isModal ? "md" : "xs"}
                                  width="100%" justifyContent={"center"} alignItems={"center"}
                                  variant={"toolbar"}>
                        {isEditable && isModal &&
                              <>
                                 <ToolbarDivider/>
                                 <IconButton onClick={ctrl?.onImageUpload}
                                             aria-label={t("Add new cover image")}
                                             title={t("Add new cover image")}
                                             icon={<HiPlus/>}/>
                                 <ToolbarDivider/>
                              </>}
                        {isEditable && isModal &&
                              <>
                                 {!showConfirm ?
                                       <>
                                          <IconButton onClick={() => setShowConfirm(true)}
                                                      aria-label={t("Delete this cover image")}
                                                      title={t("Delete this cover image")}
                                                      icon={<HiOutlineTrash/>}/>
                                          <ToolbarDivider/>
                                       </>
                                       :
                                       <Flex ml={2} alignItems={"center"}>
                                          <Text color={"white"} as={"b"} fontSize={"sm"}>{t("Confirm?")}</Text>
                                          <IconButton
                                                onClick={() => setShowConfirm(false)}
                                                aria-label={t("Cancel")}
                                                title={t("Cancel")}
                                                icon={<IoMdClose/>}/>
                                          <IconButton
                                                onClick={() => {
                                                   const idx = selectedIndex;
                                                   onChange?.(sources?.[idx]);
                                                   setSelectedIndex(Math.min(Math.max(0, idx), sources?.length ? sources?.length - 1 : 0));
                                                   setShowConfirm(false);
                                                }}
                                                aria-label={t("Confirm deletion")}
                                                title={t("Confirm deletion")}
                                                icon={<IoMdCheckmark/>}/>
                                       </Flex>
                                 }
                              </>
                        }
                        <Spacer mx="auto"/>
                        <ToolbarDivider/>
                        <IconButton onClick={(e) => {
                           e.stopPropagation();
                           onToggle();
                        }} aria-label={t("Toggle image fullscreen")}
                                    title={t("Toggle image fullscreen")}
                                    color={"white"}
                                    icon={isModal ? <MdClose/> : <BiExpand/>}/>
                        <ToolbarDivider/>
                     </ButtonGroup>
                  </Slide>
            }
            {
               ctrl?.isDragging || (isEmpty && isEditable) || isLoading ?
                     <Center boxSize={"100%"}>
                        {isLoading ?
                              <CircularProgress isIndeterminate/> :
                              <Center boxSize={"100%"} onClick={ctrl?.onImageUpload} cursor={"pointer"}>
                                 <BiImageAdd size={"100%"}/>
                                 <Text position={"absolute"}
                                       className={"outlined-text"}
                                       fontWeight={ctrl?.isDragging ? "bold" : undefined}
                                       color={"pure-white"}>{t("Drop an image here!")}</Text>
                              </Center>
                        }
                     </Center>
                     : images.map(img => img.dataURL).map((src: string, i) =>
                           <Image key={src}
                                  opacity={isEmpty ? .65 : 1}
                                  display={i === selectedIndex ? "block" : "none"}
                                  boxSize="100%"
                                  maxH={isModal ? undefined : "100%"}
                                  height={isModal ? undefined : "100%"}
                                  fit={isModal ? "scale-down" : "cover"}
                                  src={src}
                                  fallbackSrc={FALLBACK_IMG}
                                  onClick={() => onClick?.()}
                                  cursor={onClick ? "pointer" : "default"}
                           />)
            }
            {((isEditable || !isEmpty) && (!isOpen || isModal)) &&
                  <Slide direction="bottom" style={{position: "absolute"}} in={isHovering && !isEmpty}>
                     <ButtonGroup alignItems={"center"} size={isModal ? "md" : "xs"} width="100%" isAttached
                                  justifyContent={"center"} bg="blackAlpha.400"
                                  variant={"toolbar"}>
                        {
                              images.length > 1 && <>
                                 <ToolbarDivider/>
                                 <IconButton onClick={() => handleSlide(selectedIndex - 1)}
                                             aria-label={t("Jump to previous image")} icon={<FaChevronLeft/>}/>
                              </>
                        }
                        <ToolbarDivider/>
                        <Button>{selectedIndex + 1} / {images.length}</Button>
                        <ToolbarDivider/>
                        {
                              images.length > 1 && <>
                                 <IconButton onClick={() => handleSlide(selectedIndex + 1)}
                                             aria-label={t("Jump to next image")} icon={<FaChevronRight/>}/>
                                 <ToolbarDivider/>
                              </>
                        }
                     </ButtonGroup>
                  </Slide>
            }
         </Center>
   )

   const renderEditableImages = (modal?: boolean) => {
      return onChange ?
            <ImageUploading
                  multiple
                  value={images}
                  onChange={onChangeHandler}
                  maxNumber={10}
                  maxFileSize={5242880}
                  acceptType={["png", "jpeg", "jpg", "gif"]}
                  allowNonImageType={false}
            >
               {(props) => (renderImages(props, modal))}
            </ImageUploading>
            :
            renderImages(undefined, modal)
   };

   // fixme: do not use portal, open a new modal!!
   return (isOpen ?
               <>
                  {renderEditableImages()}
                  <Modal
                        motionPreset="slideInBottom"
                        size="full"
                        onClose={onClose}
                        isOpen={isOpen}
                        scrollBehavior={"inside"}
                        closeOnOverlayClick={true}
                        closeOnEsc={true}
                  >
                     <ModalContent bg="blackAlpha.700" height="100vh" maxHeight="100vh" minHeight="100vh">
                        <ModalBody p={0} scrollBehavior={"smooth"}>
                           {renderEditableImages(true)}
                        </ModalBody>
                     </ModalContent>
                  </Modal>
               </>
               :
               renderEditableImages()
   )
}

