import * as React from "react";
import {Box, Heading, VStack} from "@chakra-ui/react";
import {AnyAsset, AnyRelationship} from "../../../types";
import {Relationship} from "../../../api";
import {AssetCard} from "./AssetCard";
import {HorizontalInfiniteScroll} from "../../components/HorizontalInfiniteScroll";
import useGraphQueryContext from "../../hooks/useGraphQueryContext";

const toRelMap = (parentId: string, relationships?: AnyRelationship[]) => {
   const relEntries = relationships?.map((e) => {
      const res = [];
      if (e.startUuid !== parentId) {
         res.push([e.startUuid, e]);
      }
      if (e.endUuid !== parentId) {
         res.push([e.endUuid, e]);
      }
      return res;
   }).flat(1);
   return relEntries ? Object.fromEntries(relEntries) : undefined;
};

export const AssetRelations: React.FC<any> = ({
                                                 parentId,
                                                 title,
                                                 assets,
                                                 relationships,
                                                 onChange,
                                                 ...rest
                                              }: { parentId: string, title: string, result?: any, assets: AnyAsset[], relationships?: Relationship[], isLoading?: boolean, onChange: Function }) => {

   const {setDragTarget} = useGraphQueryContext();

   return (
         <VStack alignItems={"flex-start"} gap={2}>
            {title &&
                  <Heading px={4} as="h4" size={"md"} {...rest}>{title}</Heading>
            }
            <HorizontalInfiniteScroll
                  parentId={parentId}
                  onChange={onChange}>
               {assets?.map((asset) => {
                  const relationship = toRelMap(parentId, relationships)?.[asset.uuid || ""];
                  return <Box key={asset.uuid} scrollSnapAlign={"start"} draggable={!!setDragTarget}
                              onDragStart={(e) =>
                                    setDragTarget?.({
                                       asset: asset,
                                       relationship: relationship
                                    })}
                              onDragEnd={() => setDragTarget?.(undefined)}
                              userSelect="none">
                     <AssetCard asset={asset} position={"relative"}
                                relationship={relationship}/>
                  </Box>;
               })}
            </HorizontalInfiniteScroll>
         </VStack>
   )
}
