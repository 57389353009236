import * as React from "react";
import {useRef} from "react";
import {
   Box,
   Collapse,
   Divider,
   Flex,
   Link,
   SimpleGrid,
   Text,
   useDisclosure,
   useOutsideClick,
   VStack
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import CONFIG from "../../config.json";
import {ExternalLinks} from "./ExternalLinks";
import {ExternalLinkIcon} from "./icon/ExternalLinkIcon";
import {Link as RLink, useParams} from "react-router-dom";
import useSearchContext from "../hooks/useSearchContext";
import {useAuth} from "react-oidc-context";
import {CollapsibleButton} from "./CollapsibleButton";

export const Footer: React.FC<any> = () => {
   const auth = useAuth();
   const {t} = useTranslation();
   const {threadUuid} = useParams();
   const {isAssetQuery} = useSearchContext();
   const isSlide = !!threadUuid || isAssetQuery;
   const {
      isOpen,
      onToggle,
      onClose
   } = useDisclosure();
   const ref = useRef<HTMLDivElement>(null)

   useOutsideClick({
      ref: ref,
      handler: onClose
   })

   const renderFooterMenu = () => (
         <SimpleGrid ref={ref} columns={[1, 2, 2, 4]} spacing={10} p={[10]} bg="pure-white" justifyContent="center">
            <Box>
               <Text fontSize={["md"]} fontWeight={"bold"}>{t("Navigation")}</Text>
               <VStack pt={4} alignItems={"start"}>
                  <Link onClick={onClose} variant="link" href="/">{t('Home')}</Link>
                  <Link onClick={onClose} variant="link" href="/atlas">{t('Atlas')}</Link>
                  <Link variant="link" as={RLink} to="/"
                        onClick={() => {
                           onClose();
                           return void auth.signinRedirect();
                        }}>{t('Sign In')}</Link>
                  <Link onClick={onClose} variant="link" href={CONFIG.OIDC_REGISTER_URI}>{t('Sign Up')}</Link>
               </VStack>
            </Box>
            <Box>
               <Text fontSize={["md"]} fontWeight={"bold"}>{t("About")}</Text>
               <VStack pt={4} alignItems={"start"}>
                  <Link onClick={onClose} variant="link"
                        href="https://www.labirinto.art/">{t('Labirinto')}<ExternalLinkIcon/></Link>
                  <Link onClick={onClose} variant="link"
                        href="https://www.labirinto.art/all-events">{t('Events')}<ExternalLinkIcon/></Link>
                  <Link onClick={onClose} variant="link"
                        href="https://www.labirinto.art/contact-8">{t('Contact')}<ExternalLinkIcon/></Link>
                  <Link onClick={onClose} variant="link"
                        href="https://www.labirinto.art/drops">{t('NFT Drops')}<ExternalLinkIcon/></Link>
                  <ExternalLinks hideHome/>
               </VStack>
            </Box>
            <Box>
               <Text fontSize={["md"]} fontWeight={"bold"}>{t("Resources")}</Text>
               <VStack pt={4} alignItems={"start"}>
                  <Link onClick={onClose} variant="link" href="/tutorial">{t('Tutorials')}</Link>
                  <Link onClick={onClose} variant="link" href="/blog">{t('Blog')}</Link>
                  <Link onClick={onClose} variant="link" href="/apidocs">{t('API & Docs')}</Link>
                  <Link onClick={onClose} variant="link" href="/status">{t('Status')}</Link>
               </VStack>
            </Box>
            <Box>
               <Text fontSize={["md"]} fontWeight={"bold"}>{t("Privacy")}</Text>
               <VStack pt={4} alignItems={"start"}>
                  <Link onClick={onClose} variant="link" href="/terms-of-use">{t('Terms of Use')}</Link>
                  <Link onClick={onClose} variant="link" href="/data-privacy">{t('Data Privacy')}</Link>
                  <Link onClick={onClose} variant="link" href="/cookie-management">{t('Cookie Management')}</Link>
               </VStack>
            </Box>
         </SimpleGrid>
   )

   const ariaLabel = t("Expand footer");
   return (
         <Box cursor={isSlide ? "pointer" : "default"} overflow={"hidden"}>
            {isSlide ?
                  <Collapse in={isOpen} startingHeight={34} animateOpacity
                            style={{
                               position: "fixed",
                               bottom: 0,
                               left: 0,
                               width: "100vw",
                               maxHeight: "50vh",
                               overflow: isOpen ? "auto" : "hidden",
                               zIndex: "popover"
                            }}>
                     {isOpen &&
                           <CollapsibleButton reverse showing={isOpen} ariaLabel={ariaLabel} onClick={onToggle}/>}
                     <Flex flexDir={"column"}>
                        <Flex order={isOpen ? 10 : -1} justifyContent={"center"} width="100%"
                              bg="blackAlpha.900" color="white" py={isSlide ? 2 : 4} onClick={onToggle}>
                           <Text textAlign={"center"} fontSize={isSlide ? ["2xs", null, "xs"] : ["sm", null, "md"]}
                                 fontWeight={"bold"}>{t("Copyright © {{year}} Labirinto. All rights reserved.", {year: new Date().getFullYear()})}</Text>
                        </Flex>
                        <Divider position={"sticky"} top={0} height={1} bg="blackAlpha.900"/>
                        {renderFooterMenu()}
                     </Flex>
                  </Collapse>
                  : <>
                     {renderFooterMenu()}
                     <Divider height={1.5} bg="primary.500"/>
                     <Flex justifyContent={"center"} width="100%" bg="blackAlpha.900" color="white"
                           py={isSlide ? 2 : 4}>
                        <Text textAlign={"center"} fontSize={isSlide ? ["2xs", null, "xs"] : ["sm", null, "md"]}
                              fontWeight={"bold"}>{t("Copyright © {{year}} Labirinto. All rights reserved.", {year: new Date().getFullYear()})}</Text>
                     </Flex>
                  </>
            }

         </Box>
   )

}
