import * as React from "react";
import {ForwardedRef, useDeferredValue, useEffect, useMemo, useRef, useState} from "react";
import {
   forwardRef,
   IconButton,
   InputGroup,
   InputLeftElement,
   InputRightElement,
   Kbd,
   Spacer,
   Text,
   useFocusEffect,
   useMergeRefs
} from "@chakra-ui/react";
import {BsSearch} from "react-icons/bs";
import {MdClear} from "react-icons/md";
import {useTranslation} from "react-i18next";
import useSearchContext from "../../hooks/useSearchContext";
import useNavigation from "../../hooks/useNavigation";
import {AssetType, SearchScope} from "../../../types";
import {AutoComplete, AutoCompleteInput, AutoCompleteItem, AutoCompleteList} from "@choc-ui/chakra-autocomplete";
import {useParams} from "react-router-dom";
import {
   Stages,
   useCollectsApiResourceCreateMutation,
   useSearchApiResourceSearchQuery,
   useStagesApiResourceCreateMutation
} from "../../../api";
import {Highlighted} from "../../components/Highlighted";
import {AssetCoin} from "../asset/AssetCoin";
import useGraphQueryContext from "../../hooks/useGraphQueryContext";
import usePermissions from "../../hooks/usePermissions";
import useAsset from "../../hooks/useAsset";
import Utils from "../../../util/utils";
import {BiImageAdd, BiLink} from "react-icons/bi";
import {AiOutlineAppstoreAdd} from "react-icons/ai";

export const SearchQuery: React.FC<any> = forwardRef((props: { inputWidth: any, onSearch: Function },
                                                      ref: ForwardedRef<any>) => {

   const {t} = useTranslation();
   const {isAuthor} = usePermissions();
   const {
      threadUuid,
      uuid,
      assetTypePath
   } = useParams();

   const type = Utils.pathToAssetType(assetTypePath) as AssetType;

   const {asset: currentAsset} = useAsset({
      assetUuid: uuid,
      assetType: type
   });

   const {
      navigateToQuery,
      navigateToAsset,
      navigateToCanvas
   } = useNavigation();
   const {
      query,
      assetType,
      isLoading,
      isFetching,
      reset,
      scope,
   } = useSearchContext();

   const {
      addAssetPin,
      isWorking,
      addCoordinates
   } = useGraphQueryContext();

   const [queryValue, setQueryValue] = useState<string>(query || "");
   const defQuery = useDeferredValue(queryValue);

   const internalRef = useRef(null);

   useFocusEffect(internalRef, {shouldFocus: !defQuery});

   const [createStages] = useStagesApiResourceCreateMutation();
   const [createCollects] = useCollectsApiResourceCreateMutation();

   const {
      data,
      isLoading: isQuickSearchLoading,
      isFetching: isQuickSearchFetching
   } = useSearchApiResourceSearchQuery({
      query: defQuery + "*",
      scope: threadUuid || uuid ? SearchScope.Global : scope,
      assetType: threadUuid || uuid ? AssetType.Asset : assetType,
      pageSize: 10,
      pageNumber: 1
   }, {
      skip: !defQuery || !scope
   });

   useEffect(() => {
      setQueryValue(query || "");
   }, [query])

   const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' && defQuery) {
         props.onSearch && props.onSearch();
         navigateToQuery(defQuery);
      }
   }

   const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setQueryValue(e.target.value);
   };

   const results = useMemo(() => data?.assets?.contents || [], [data]);

   const linkLabel = t("Link with this content");
   const imageLabel = t("Add as cover image");
   const addToCollection = t("Add to the {{assetType}}", {assetType});

   return (
         <AutoComplete emphasize emptyState={false} disableFilter={true} onSelectOption={(e) => {
            if (e.item.originalValue?.uuid) {
               if (e.item.originalValue.assetType === "Thread") {
                  navigateToCanvas(e.item.originalValue?.uuid)
               } else {
                  navigateToAsset(e.item.originalValue)
               }
            } else {
               navigateToQuery(defQuery)
            }
         }}>
            <InputGroup size={"lg"}>
               <InputLeftElement width={"min-content"} height={"100%"}>
                  <IconButton border="none"
                              colorScheme="transparent"
                              onClick={() => navigateToQuery(defQuery)}
                              isLoading={isLoading || isFetching || isQuickSearchLoading || isQuickSearchFetching || isWorking}
                              icon={<BsSearch/>}
                              aria-label={t("Search results for {{query}}", {query: query})}/>
               </InputLeftElement>
               <AutoCompleteInput
                     ref={useMergeRefs(ref, internalRef)}
                     isDisabled={isFetching || isLoading || isWorking}
                     borderWidth={2}
                     borderColor={"black"}
                     bgColor={"pure-white"}
                     _disabled={{bg: "pure-white"}}
                     pr={"3.5rem"}
                     placeholder={(threadUuid || uuid) && isAuthor ? t("search & add...") : t("let's research...")}
                     onKeyDown={(e) => handleKeyDown(e)}
                     onChange={(e) => handleQueryChange(e)}
                     value={defQuery}
                     autoFocus={true}
                     width={props.inputWidth}

               />
               {(threadUuid || uuid ? defQuery : query) &&
                     <InputRightElement width='3rem' height={"100%"}>
                        <IconButton
                              border="none"
                              colorScheme="transparent"
                              onClick={() => {
                                 if (!(threadUuid || uuid)) {
                                    props.onSearch && props.onSearch();
                                    reset();
                                 } else {
                                    setQueryValue("");
                                 }
                              }}
                              isDisabled={isLoading || isFetching}
                              icon={<MdClear/>}
                              aria-label={t("Clear the current query")}/>
                     </InputRightElement>
               }
            </InputGroup>
            <AutoCompleteList>
               {results.length === 0 && <AutoCompleteItem
                     key={"empty"}
                     value={"empty"}
                     disabled={true}
               >
                  {t("Sorry, got nothing for ")}&nbsp;"<Text as="i" fontWeight="bold">{defQuery}</Text>"
               </AutoCompleteItem>
               }
               {results.map((asset: any) =>
                     <AutoCompleteItem
                           key={`${asset.uuid}`}
                           value={asset}>
                        <AssetCoin size="xs" asset={asset}/>
                        <Text noOfLines={1} ml={2} fontSize={"sm"}>
                           <Highlighted text={asset.title} highlight={defQuery}/>
                        </Text>
                        <Spacer mx={"auto"}/>
                        {uuid && !currentAsset.data?.stages?.find((s: Stages) => s.endUuid === asset.uuid) && (
                              asset.classifier?.includes("image") ? (
                                    <IconButton
                                          size={"xs"}
                                          variant={"solid"}
                                          colorScheme={"black"}
                                          aria-label={imageLabel}
                                          title={imageLabel}
                                          icon={<BiImageAdd size={16}/>}
                                          onClick={(e) => {
                                             e.preventDefault();
                                             e.stopPropagation();
                                             createStages({
                                                stagesMutationRequest: {
                                                   startUuid: uuid,
                                                   endUuid: asset.uuid,
                                                   layout: "COVER_IMAGE"
                                                }
                                             })
                                          }}
                                    />
                              ) : (
                                    <IconButton
                                          size={"xs"}
                                          variant={"solid"}
                                          colorScheme={"black"}
                                          aria-label={linkLabel}
                                          title={linkLabel}
                                          icon={<BiLink size={16}/>}
                                          onClick={(e) => {
                                             e.preventDefault();
                                             e.stopPropagation();
                                             createStages({
                                                stagesMutationRequest: {
                                                   startUuid: uuid,
                                                   endUuid: asset.uuid,
                                                   layout: "BODY"
                                                }
                                             })
                                          }}
                                    />
                              ))}
                        {(threadUuid || assetType === AssetType.Collection || assetType === AssetType.Thread) &&
                              <IconButton
                                    ml={2}
                                    size={"xs"}
                                    variant={"solid"}
                                    colorScheme={"black"}
                                    aria-label={addToCollection}
                                    title={addToCollection}
                                    icon={<AiOutlineAppstoreAdd size={16}/>}
                                    onClick={(e) => {
                                       e.preventDefault();
                                       e.stopPropagation();
                                       if (threadUuid) {
                                          addAssetPin(asset.uuid, addCoordinates?.x, addCoordinates?.y)
                                       } else {
                                          createCollects({
                                             collectsMutationRequest: {
                                                startUuid: uuid,
                                                endUuid: asset.uuid,
                                             }
                                          })
                                       }
                                    }}
                              />}
                     </AutoCompleteItem>
               )}
               {results.length > 0 &&
                     <AutoCompleteItem
                           key={"last"}
                           value={"last"}>
                        &#10148;&nbsp;{t("Go to all {{count}} result(s)...", {count: data?.assets?.total || 0})}<Kbd
                           ml="auto">{t("Enter")}</Kbd>
                     </AutoCompleteItem>
               }
            </AutoCompleteList>
         </AutoComplete>
   )
})
