import * as React from "react";
import {useMemo, useRef, useState} from "react";
import {AssetRelations} from "./AssetRelations";
import config from "../../../theme/config";
import {Box} from "@chakra-ui/react";
import {useSize} from "@chakra-ui/react-use-size";
import {AssetType} from "../../../types";
import Utils from "../../../util/utils";
import {useSearchApiResourceSearchQuery} from "../../../api";

export const FeaturedAssets: React.FC<any> = ({
                                                 title,
                                                 assetType,
                                                 categories,
                                                 sortBy,
                                                 sortDescending,
                                                 ...rest
                                              }: { assetType: AssetType, categories: string[], title: string, sortBy?: string, sortDescending?: boolean }) => {

   const [pageNumber, setPageNumber] = useState(1);
   const ref = useRef(null);
   const box = useSize(ref);
   const pageSize = useMemo(() => box?.width ? Math.ceil(box.width / config.cardWidth) : 0, [box]);

   const result = useSearchApiResourceSearchQuery({
            scope: "DEFAULT",
            assetType: assetType || AssetType.Asset,
            categories: Utils.arrayOrUndef(categories),
            sortBy: sortBy || undefined,
            descending: sortDescending,
            query: "*",
            pageSize,
            pageNumber,
         },
         {
            skip: !pageSize
         });

   return (
         <Box boxSize={"100%"} ref={ref}>
            <AssetRelations
                  parentId={title}
                  title={title}
                  onChange={(offset: number) => {
                     if (offset > 0) {
                        setPageNumber((p) => p + offset)
                     }
                  }}
                  assets={result?.data?.assets?.contents}
                  {...rest}
            />
         </Box>
   )
}
