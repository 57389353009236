/**
 * Theme extensions should follow the structure given by the default theme.
 *
 * See https://chakra-ui.com/docs/styled-system/theme;
 * and https://github.com/chakra-ui/chakra-ui/tree/main/packages/theme/src/foundations
 *
 * @author Otavio Biasutti, PRODYNA AG
 * @since 2022-07-19
 */
const colors = {
   black: '#293133', // anthracite
   white: '#F0F1EA', // white creme
   'pure-white': '#FFF',
   'pure-black': '#000',
   primary: {
      50: '#FFF',
      100: '#F0F1EA',
      200: '#FBFAEC',
      300: '#FFFBCC',
      400: '#FFE03F',
      500: '#F2FD00',
      600: '#B8C700',
      700: '#7F9400',
      800: '#4D6300',
      900: '#2E3500',
   },
   secondary: {
      900: '#FFFBCC',
      800: '#FFFBCC',
      700: '#F0F1EA',
      600: '#F0F1EA',
      500: '#F5F6F1',
      400: '#FAFAF8',
      300: '#FAFAF8',
      200: '#FFFFFF',
      100: '#FFFFFF'
   },
   darken: {
      50: '#FFF',
      100: '#EADFAA',
      200: '#FFE03F',
      300: '#F2FD00',
      400: '#B8C700',
      500: '#7F9400',
      600: '#4D6300',
      700: '#2E3500',
      800: '#293133',
      900: '#000',
   },
   lighten: {
      50: '#000',
      100: '#293133',
      200: '#2E3500',
      300: '#4D6300',
      400: '#7F9400',
      500: '#B8C700',
      600: '#F2FD00',
      700: '#FFE03F',
      800: '#EADFAA',
      900: '#FFF',
   },
   alternative: {
      50: '#FFFADA',
      100: '#FFF7AE',
      200: '#FFF87D',
      300: '#FFFB4B',
      400: '#FDFF1A',
      500: '#DCE600',
      600: '#B3AF00',
      700: '#807600',
      800: '#4D4300',
      900: '#1B1600',
   }
};

export default colors;
