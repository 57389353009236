import * as React from "react";
import {useMemo} from "react";
import {Footer} from "../components/Footer";
import {HeaderCanvas} from "../components/HeaderCanvas";
import {chakra, Flex} from "@chakra-ui/react";
import {SearchContextProvider} from "../hooks/useSearchContext";
import {useParams} from "react-router-dom";
import useAsset from "../hooks/useAsset";
import {AssetType} from "../../types";
import {NotFound} from "../pages/NotFound";
import {GraphToolbar} from "../components/graph/GraphToolbar";
import {Graph} from "../components/graph/Graph";
import {GraphSelectionDrawer} from "../components/graph/GraphSelectionDrawer";
import {GraphQueryContextProvider} from "../hooks/useGraphQueryContext";
import {useRelationshipApiResourceThreadGraphQuery} from "../../api";

export const Canvas: React.FC<any> = (props) => {

   const {threadUuid} = useParams();

   const thread = useAsset({
      assetUuid: threadUuid,
      assetType: AssetType.Thread
   });

   const response = useRelationshipApiResourceThreadGraphQuery({
      threadUuid: threadUuid || ""
   });

   return useMemo(() =>
         <SearchContextProvider>
            <GraphQueryContextProvider
                  edges={response.data?.edges}
                  nodes={response.data?.nodes}>
               <Flex direction="column" flex="1 1 100%" maxH="100vh" height="100vh" maxW="100vw">
                  <chakra.header>
                     <nav>
                        <HeaderCanvas title={thread?.asset.data?.title}/>
                     </nav>
                  </chakra.header>
                  <chakra.main flex="1" display="flex" overflow="hidden">
                     {thread.asset.isError ?
                           <NotFound/>
                           :
                           <Flex w="100%" direction="column" flex="1 1 auto" alignItems="center"
                                 justifyContent="center">
                              <GraphToolbar {...{}}/>
                              <Graph/>
                              <GraphSelectionDrawer/>
                           </Flex>
                     }
                  </chakra.main>
                  <chakra.footer>
                     <Footer/>
                  </chakra.footer>
               </Flex>
            </GraphQueryContextProvider>
         </SearchContextProvider>, [response, thread])
}
