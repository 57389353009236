import * as React from "react";
import {useCallback, useMemo, useRef} from "react";
import {
   Box,
   Button,
   ButtonGroup,
   Collapse,
   Menu,
   MenuButton,
   MenuDivider,
   MenuItemOption,
   MenuList,
   MenuOptionGroup,
   useBoolean,
   useOutsideClick
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {FaProjectDiagram} from "react-icons/fa";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useGraphQueryContext from "../../hooks/useGraphQueryContext";
import {IoArrowBackCircleOutline} from "react-icons/io5";
import {CollapsibleButton} from "../CollapsibleButton";
import usePermissions from "../../hooks/usePermissions";
import {TbCards, TbPlugConnected} from "react-icons/tb";
import {RiCoinLine} from "react-icons/ri";
import {GiPostStamp} from "react-icons/gi";
import {BiCaretDown} from "react-icons/bi";

export const GraphToolbar: React.FC<any> = (props: {}) => {

   const {t} = useTranslation();
   const {isAdmin} = usePermissions();
   const [isShowing, {
      off,
      toggle
   }] = useBoolean();
   const ref = useRef(null);
   const navigate = useNavigate();
   const {key} = useLocation();
   const {threadUuid} = useParams();
   const {
      isCoin,
      setCoin,
      isWiringEnabled,
      enableWiring,
      disableWiring,
      isPresentation,
      setPresentation
   } = useGraphQueryContext();
   useOutsideClick({
      ref,
      handler: off
   })

   const mode = useCallback((mode?: string) => {
      if (mode) {
         setCoin(mode === "coins");
         setPresentation(mode === "stamps");
         if (mode === "connect") {
            enableWiring();
         } else {
            disableWiring();
         }
      } else if (isCoin) {
         return "coins";
      } else if (isWiringEnabled) {
         return "connect";
      } else if (isPresentation) {
         return "stamps";
      } else {
         return "cards";
      }
   }, [isCoin, isWiringEnabled, isPresentation, enableWiring, disableWiring, setCoin, setPresentation]);

   const toggleLabel = t("Toggle toolbar");

   return useMemo(() =>
         <Box ref={ref} width="100%" position={"sticky"} top={"0"} zIndex="sticky" bg="blackAlpha.900"
              color={"pure-white"} justifyContent={"center"} border={"xs"}>
            <Collapse in={isShowing} startingHeight={4}>
               <CollapsibleButton showing={isShowing} ariaLabel={toggleLabel} onClick={toggle}/>
               <ButtonGroup
                     px={"24px"}
                     alignItems={"center"}
                     justifyContent={"center"}
                     flexWrap={"wrap"}
                     width="100%"
                     isAttached
                     variant="toolbar"
                     size="sm"
                     gap={3}
                     maxHeight={"60vh"}
                     height={"32px"}
                     visibility={isShowing ? "visible" : "hidden"}
               >
                  <Menu>
                     <MenuButton as={Button} colorScheme="blue" rightIcon={<BiCaretDown size={16}/>}>
                        {t("Modes")}
                     </MenuButton>
                     <MenuList minWidth="150px" bgColor={"blackAlpha.700"}>
                        <MenuOptionGroup defaultValue={mode()} type="radio"
                                         onChange={(value) => mode(value as string)}>
                           {isAdmin &&
                                 <MenuItemOption as={Button} rightIcon={<TbPlugConnected size={16}/>}
                                                 value="connect">{t("Connect")}</MenuItemOption>}
                           {isAdmin && <MenuDivider/>}
                           <MenuItemOption as={Button} rightIcon={<RiCoinLine size={16}/>}
                                           value="coins">{t("Coins")}</MenuItemOption>
                           <MenuDivider/>
                           <MenuItemOption as={Button} rightIcon={<TbCards size={16}/>}
                                           value="cards">{t("Cards")}</MenuItemOption>
                           <MenuDivider/>
                           <MenuItemOption as={Button} rightIcon={<GiPostStamp size={16}/>}
                                           value="stamps">{t("Stamps")}</MenuItemOption>
                        </MenuOptionGroup>
                     </MenuList>
                  </Menu>
                  {threadUuid &&
                        <Button leftIcon={<FaProjectDiagram size={16}/>}
                                onClick={() => navigate(`/atlas/th/${threadUuid}`)}>{t("Go to Thread")}</Button>
                  }
                  {key !== 'default' &&
                        <Button leftIcon={<IoArrowBackCircleOutline size={24}/>}
                                onClick={() => navigate(-1)}>{t("Back")}</Button>
                  }
               </ButtonGroup>
            </Collapse>
         </Box>, [isShowing, toggleLabel, ref, threadUuid, isCoin, key, navigate, isAdmin, mode])
}
