import * as React from "react";
import { Center, Container, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BiCookie } from "react-icons/bi";

export const CookieManagement: React.FC<any> = (props) => {

  const {t} = useTranslation()

  return (
    <Container>
      <Center boxSize={"100%"}>
        <Flex flexDir={"column"} gap={4} flex={1} bg={"pure-white"} boxShadow={"md"} rounded="base" my={6} p={4}>
          <Heading as={"h1"}><Icon boxSize={"40px"} verticalAlign={"bottom"}><BiCookie size={"100%"}/></Icon>{t("ookies")}</Heading>
          <Text as={"em"}>{t("Coming Soon!")}</Text>
          <Text>{t("When we store personal data into web-cookies, it is solely to improve the user's experience within these Labirinto walls!")}</Text>
          <Text>{t("Find here a space to manage all the web-cookies we will be using soon.")}</Text>
        </Flex>
      </Center>
    </Container>
  )
}