import * as React from "react";
import {useCallback, useMemo, useState} from "react";
import {Center, IconButton, Menu, MenuButton, MenuGroup, MenuItem, MenuList} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import useNavigation from "../../hooks/useNavigation";
import {AnyAsset, AssetType} from "../../../types";
import {MdOutlinePublish, MdOutlineVisibility, MdStart} from "react-icons/md";
import {GrConnectivity, GrOverview} from "react-icons/gr";
import {BiCaretDown, BiTrash} from "react-icons/bi";
import usePermissions from "../../hooks/usePermissions";
import {useParams} from "react-router-dom";
import useAsset from "../../hooks/useAsset";
import {FaProjectDiagram} from "react-icons/fa";
import {VisibilityIcon} from "../../components/icon/VisibilityIcon";
import useGraphQueryContext from "../../hooks/useGraphQueryContext";
import {IoRemoveCircle} from "react-icons/io5";
import {
   Thread,
   useCollectsApiResourceCollectsQuery,
   useCollectsApiResourceDeleteMutation,
   useThreadApiResourceFetchQuery,
   useThreadApiResourceUpdateMutation
} from "../../../api";
import Utils from "../../../util/utils";

/**
 * Actions for assets.
 *
 * For context based actions the uuid from the url has precedence over the labirinto's center.
 *
 * <li>Empty context, only asset based actions
 * <li>With a context, relationships are modified instead of nodes
 * <li>Within a thread context operations are based on the thread's content
 */
export const AssetQuickActionMenu: React.FC<any> = (props: { asset: AnyAsset, onCloseExploded?: Function, isExploded?: boolean, size?: string | number }) => {

   const {t} = useTranslation();
   const {asset} = props;
   const {isAuthor} = usePermissions(asset);
   const {
      threadUuid,
      uuid,
      assetTypePath
   } = useParams();

   const assetType = Utils.pathToAssetType(assetTypePath);

   const {
      navigateToAsset,
      navigateToCanvas
   } = useNavigation();
   const [activeConfirmAction, setActiveConfirmAction] = useState<string>();

   const {
      deleter,
      publish,
      expose,
      hide
   } = useAsset({asset: asset});

   const {
      openRelationships,
      removeAssetPins
   } = useGraphQueryContext();

   const viewLabel = t("View");
   const showRelationships = t("Show relationships");
   const canvasLabel = t("Open Board");
   const setAsStart = t("Set as start");
   const removeFromAssemblage = useMemo(() => activeConfirmAction === "removing" ? t("Confirm removal?") : t("Remove from {{assetType}}", {assetType: threadUuid ? AssetType.Thread : assetType}), [activeConfirmAction, assetType, threadUuid]);
   const deleteLabel = useMemo(() => activeConfirmAction === "deleting" ? t("Confirm deletion?") : t("Delete"), [activeConfirmAction]);
   const publishLabel = useMemo(() => activeConfirmAction === "publishing" ? t("Confirm publishing?") : t("Publish"), [activeConfirmAction]);
   const hideLabel = useMemo(() => activeConfirmAction === "hiding" ? t("Confirm hiding?") : t("Hide"), [activeConfirmAction]);
   const exposeLabel = useMemo(() => activeConfirmAction === "exposing" ? t("Confirm exposing?") : t("Expose"), [activeConfirmAction]);

   const getConfirmActionProps = useCallback((confirmAction: string, onClick: Function) => {
      const active = activeConfirmAction === confirmAction;
      return {
         bg: active ? "primary.500 !important" : undefined,
         closeOnSelect: active && (!!props.onCloseExploded || !props.isExploded),
         onClick: () => active ? onClick() : setActiveConfirmAction(confirmAction),
      };
   }, [activeConfirmAction]);

   const [updateThread] = useThreadApiResourceUpdateMutation();
   const {data: thread} = useThreadApiResourceFetchQuery({uuid: threadUuid!}, {skip: !threadUuid});
   const [deleteCollects] = useCollectsApiResourceDeleteMutation();
   const {data} = useCollectsApiResourceCollectsQuery({
      collectionUuid: uuid!,
      assetUuid: asset.uuid!
   }, {skip: !asset.uuid || !uuid || assetType !== AssetType.Collection});

   return (
         asset.uuid ?
               <Menu variant={props.isExploded ? "exploded" : undefined}
                     isLazy
                     autoSelect={false}
                     defaultIsOpen={props.isExploded === true}
                     computePositionOnMount={!props.isExploded}
                     lazyBehavior={props.isExploded ? "keepMounted" : "unmount"}
                     onClose={() => {
                        setActiveConfirmAction(undefined);
                        props.onCloseExploded?.(asset);
                     }}
                     closeOnSelect={!props.isExploded}
                     closeOnBlur={!props.isExploded}
                     strategy={props.isExploded ? "absolute" : "fixed"}>
                  {!props.isExploded &&
                        <MenuButton ml="auto" as={IconButton} icon={<BiCaretDown size="100%"/>}
                                    size={props.size || "xs"} variant="ghost" colorScheme={"blackAlpha"}/>
                  }
                  <MenuList zIndex={"popover"}>
                     <MenuGroup title={t("Browse & Seek")}>
                        <MenuItem aria-label={viewLabel + " " + asset.title}
                                  onClick={() => navigateToAsset(asset)}
                                  icon={<GrOverview size={16}/>}>{viewLabel}</MenuItem>
                        {asset.assetType === AssetType.Thread &&
                              <MenuItem aria-label={canvasLabel}
                                        onClick={() => navigateToCanvas(asset.uuid)}
                                        icon={<FaProjectDiagram size={16}/>}>{canvasLabel}</MenuItem>
                        }
                     </MenuGroup>
                     {(threadUuid || (uuid && assetType === AssetType.Collection)) &&
                           <MenuGroup title={t("{{assetType}}", {assetType})}>
                              {threadUuid && (
                                    <MenuItem
                                          aria-label={showRelationships}
                                          icon={<GrConnectivity size={16}/>}
                                          onClick={() => openRelationships()}
                                    >{showRelationships}</MenuItem>
                              )}
                              {isAuthor && data?.uuid && (
                                    <MenuItem
                                          aria-label={removeFromAssemblage}
                                          icon={<IoRemoveCircle size={16}/>}
                                          {...getConfirmActionProps("removing", () => {
                                             if (threadUuid) {
                                                removeAssetPins(asset.uuid);
                                             } else {
                                                deleteCollects({batchRequest: {uuids: [data.uuid!]}})
                                             }
                                          })}
                                    >{removeFromAssemblage}</MenuItem>
                              )}
                              {isAuthor && thread && (thread as Thread).startsAt !== asset.uuid && (
                                    <MenuItem
                                          aria-label={setAsStart}
                                          icon={<MdStart size={16}/>}
                                          onClick={() => updateThread({
                                             uuid: threadUuid!,
                                             threadMutationRequest: {
                                                uuid: threadUuid,
                                                version: thread.version,
                                                startsAt: asset.uuid
                                             }
                                          })}
                                    >{setAsStart}</MenuItem>
                              )}
                           </MenuGroup>
                     }
                     {isAuthor && <>
                        <MenuGroup title={t("Authoring")}>
                           <MenuItem aria-label={deleteLabel} {...getConfirmActionProps("deleting", deleter)}
                                     icon={<BiTrash size={16}/>}>{deleteLabel}</MenuItem>
                        </MenuGroup>
                        <MenuGroup title={t("Access Control")}>
                           {(asset.status !== "APPROVED" && asset.status !== "FINAL") &&
                                 <MenuItem
                                       aria-label={publishLabel}  {...getConfirmActionProps("publishing", publish)}
                                       icon={<MdOutlinePublish size={16}/>}>{publishLabel}</MenuItem>}
                           {asset.visibility !== "PUBLIC" &&
                                 <MenuItem
                                       aria-label={exposeLabel}  {...getConfirmActionProps("exposing", expose)}
                                       icon={<MdOutlineVisibility size={16}/>}>{exposeLabel}</MenuItem>}
                           {asset.visibility !== "PRIVATE" &&
                                 <MenuItem aria-label={hideLabel}  {...getConfirmActionProps("hiding", hide)}
                                           icon={<Center boxSize={4}><VisibilityIcon
                                                 visibility={"PRIVATE"}/></Center>}>{hideLabel}</MenuItem>}
                        </MenuGroup>
                     </>
                     }
                  </MenuList>
               </Menu>
               : <></>
   )
}
