import {useCallback, useDeferredValue, useEffect, useMemo, useState} from "react";
import {useWebLinkApiResourceCreateMutation, useWebLinkApiResourceFindByUrlQuery, WebLink} from "../../api";
import Utils from "../../util/utils";
import useToasts from "./useToasts";
import useLocalStorageState from "./useLocalStorageState";
import {
   Box,
   Button,
   ButtonGroup,
   Flex,
   FormControl,
   FormErrorMessage,
   FormLabel,
   Kbd,
   Spinner,
   Text,
   Textarea,
   useToast
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {AssetCard} from "../features/asset/AssetCard";

const KEY_SUFFIX = "_WebLinkClipboard";
const WEBLINK_CLIPBOARD = "WEBLINK_CLIPBOARD";

const validUrl = (text: string | undefined) => {
   const url = Utils.toUrl((text || "").trim()) || "";
   if (url.startsWith("http") || url.startsWith("ftp")) {
      return url;
   }
   return "";
};

const useWebLinkClipboard = (isActive: boolean, helpId: string, helpMessage?: string) => {

   const {t} = useTranslation();
   const toasts = useToasts();
   const toast = useToast();

   const [webLink, setWebLink] = useState<WebLink>();
   const [webLinkCandidate, setWebLinkCandidate] = useState<WebLink>();
   const [url, setUrl] = useState<string>("");
   const [clipboardText, setClipboardText] = useState<string>("");
   const clipboardDefText = useDeferredValue(clipboardText);

   const [hideHelpContext, setHideHelpContext] = useLocalStorageState<boolean>((helpId || "") + KEY_SUFFIX);

   const handleOnPaste: EventListenerObject = useMemo(() => ({
      handleEvent: (e: ClipboardEvent) => {
         const validatedUrl = validUrl(e.clipboardData?.getData("text"));
         if (validatedUrl) {
            setClipboardText(validatedUrl);
         } else if (!toast.isActive(WEBLINK_CLIPBOARD)) {
            toast({
               title: t("Nice pasting, but..."),
               description: t("The content you've just pasted is not a valid url. Please, give it another try!"),
               status: "warning"
            })
         }
      }
   }), [setClipboardText, t, toast]);

   useEffect(() => {
      setUrl(validUrl(clipboardDefText));
   }, [clipboardDefText, setUrl])

   const {
      data,
      isLoading,
      isFetching,
         currentData
   } = useWebLinkApiResourceFindByUrlQuery({
      url: url
   }, {
      skip: !url
   });

   const [createWebLink, createWebLinkResult] = useWebLinkApiResourceCreateMutation();

   useEffect(() => {
      if (isActive) {
         window.addEventListener("paste", handleOnPaste);
      } else {
         window.removeEventListener("paste", handleOnPaste);
      }
      return () => {
         window.removeEventListener("paste", handleOnPaste);
      };
   }, [handleOnPaste, isActive]);

   const create = useCallback(() => {
      const targetUrl = new URL(url);
      const paths = (targetUrl.pathname || "").split("/");
      createWebLink({
         webLinkMutationRequest: {
            url: targetUrl.toString(),
            title: paths[-1] || "/",
            description: url,
            language: Utils.getCurrentLanguage()
         }
      })
   }, [url, createWebLink])

   useEffect(() => {
      if (data && data.uuid) {
         setWebLinkCandidate(data);
      } else {
         setWebLinkCandidate(undefined);
      }
   }, [data, currentData]);

   useEffect(() => {
      if (createWebLinkResult.isError) {
         setWebLinkCandidate(undefined);
         toasts.assetMutationError(createWebLinkResult.error, "weblink");
         createWebLinkResult.reset();
      } else if (createWebLinkResult.isSuccess) {
         setWebLinkCandidate(createWebLinkResult.data);
         toasts.assetCreationSuccess("weblink")
         createWebLinkResult.reset();
      }
   }, [createWebLinkResult, toasts])

   const resetState = useCallback((clearWeblink: boolean) => {
      setUrl("");
      setClipboardText("");
      setWebLinkCandidate(undefined);
      toast.close(WEBLINK_CLIPBOARD)
      if (clearWeblink) {
         setWebLink(undefined);
      }
   }, [setUrl, setClipboardText, setWebLinkCandidate, setWebLink]);

   const toastRenderer = useCallback(() => (
         <Flex flexDir="column" gap={2} maxWidth={280} p={2} bg="primary.300" rounded="md"
               borderColor="black"
               borderWidth={2} borderLeftWidth={4}
               alignItems="center" justifyContent="center"
         >
            <FormControl isInvalid={!url}>
               <FormLabel htmlFor={"webLinkUrl"}>
                  {t("Pasted URL")}
               </FormLabel>
               <Textarea bg="pure-white" id={"webLinkUrl"} height={24} value={clipboardText}
                         title={clipboardText}
                         resize={"none"}
                         onChange={(e) => setClipboardText(e.target.value)}></Textarea>
               <FormErrorMessage>{t("This is not a valid weblink (URL)")}.</FormErrorMessage>
            </FormControl>
            {webLinkCandidate && <AssetCard asset={webLinkCandidate}/>}
            {url && (
                  (createWebLinkResult.isLoading || isFetching || isLoading) ? (
                        <Spinner my={4} alignSelf={"center"} thickness='4px'
                                 speed='0.65s'
                                 emptyColor='pure-black'
                                 color='primary.500'
                                 size='xl'/>
                  ) : (
                        !webLinkCandidate &&
                        <Text>{t("We have not seen this yet, let's check it?")}</Text>
                  )
            )}

            <ButtonGroup w={"100%"} variant={"solid"} gap={10} size={"sm"} alignItems={"center"}
                         justifyContent={"space-between"}>
               <Button colorScheme={"secondary"} onClick={() => {
                  resetState(true);
               }}>{t("Cancel")}</Button>
               {!webLinkCandidate ?
                     (
                           <Button isDisabled={createWebLinkResult.isLoading || isFetching || isLoading}
                                   colorScheme={"primary"} onClick={() => create()}>{t("Yes, check it out!")}</Button>
                     ) : (
                           <Button colorScheme={"primary"} onClick={() => {
                              setWebLink(webLinkCandidate);
                              resetState(false);
                           }}>{t("Use it!")}</Button>
                     )
               }
            </ButtonGroup>
         </Flex>
   ), [t, webLinkCandidate, webLink, clipboardText, url, create, createWebLinkResult, isLoading, isFetching, setClipboardText, resetState,setWebLink]);

   useEffect(() => {
      if (isActive && !toast.isActive(WEBLINK_CLIPBOARD)) {
         if (clipboardText) {
            toast({
               id: WEBLINK_CLIPBOARD,
               position: 'top-right',
               duration: null,
               isClosable: true,
               render: toastRenderer,
            })
         }
      } else {
         toast.update(WEBLINK_CLIPBOARD, {render: toastRenderer})
      }
   }, [clipboardText, toastRenderer, toast, isActive]);

   useEffect(() => {
      let timeout: any = null;
      timeout = setTimeout(() => {
         if (isActive && !hideHelpContext && !toast.isActive(helpId) && !toast.isActive(WEBLINK_CLIPBOARD)) {
            toast({
                     id: helpId,
                     position: 'bottom',
                     duration: 9000,
                     isClosable: true,
                     render: () => (
                           <Box maxWidth={250} p={3} bg='primary.300' rounded={"lg"} borderColor={"black"}
                                borderWidth={2} borderLeftWidth={4}>
                              <Text fontWeight={"bold"} fontSize={"sm"}>
                                 {helpMessage || t("Paste any link from the web to add contents.")}
                                 <>&nbsp;&nbsp;</>
                                 <Kbd fontFamily={"monospace"}>{t("Ctrl")}</Kbd> + <Kbd>V</Kbd>
                              </Text>
                              <ButtonGroup w={"100%"} variant="link" gap={10} size={"xs"} alignItems={"center"}
                                           colorScheme={"blackAlpha"} justifyContent={"space-between"}>
                                 <Button onClick={() => {
                                    setHideHelpContext(true);
                                    toast.close(helpId);
                                 }}>{t("Don't show again!")}</Button>
                                 <Button onClick={() => {
                                    toast.close(helpId);
                                 }}>{t("Okay!")}</Button>
                              </ButtonGroup>
                           </Box>
                     )
                  }
            )
         }
      }, 3000)

      return () => {
         if (timeout) {
            clearTimeout(timeout)
         }
      }

   }, [isActive, hideHelpContext, helpMessage, helpId, t])

   return {
      webLink,
      reset: resetState
   }
}

export default useWebLinkClipboard;
