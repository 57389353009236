export const LABEL_PADDING_Y = 3;
export const LABEL_PADDING_X = 5;
export const LABEL_FONT_SIZE = 12;
export const TEXT_HEIGHT = LABEL_FONT_SIZE * 1.25;
export const MAX_CHANGES = 20;
export const ZOOM_SENSITIVITY = 5;
export const DEFAULT_SCALE = 1;
export const MIN_SCALE = .5;
export const MAX_SCALE = 3;
export const PIN_Z_INDEX = 10;
export const WIRE_STROKE_WIDTH = "2";
