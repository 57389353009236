import { Box, chakra, IconButton, keyframes, Skeleton, useDisclosure, usePrefersReducedMotion } from "@chakra-ui/react";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TiArrowBackOutline } from "react-icons/ti";

const upDown = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-38px); }
  100% { transform: translateY(0); }
`

export const SeamlessIFrame: React.FC<any> = (props: { url: string, openNewTabLabel?: string }) => {

  const {t} = useTranslation();
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const {isOpen, onToggle} = useDisclosure();
  const prefersReducedMotion = usePrefersReducedMotion({fallback: false});

  const title = t(isOpen ? "Go back to Labirinto" : "Go into the website");

  const animation = prefersReducedMotion
    ? undefined
    : `${upDown} 2 .75s ease-out`

  return (
    <Skeleton isLoaded={iframeLoaded}>
      <Box zIndex={isOpen ? "modal" : undefined}
           position={isOpen ? "fixed" : "relative"}
           top={0}
           left={0}
           width={isOpen ? "100vw" : "100%"}
           height={isOpen ? "100vh" : `60vh`}>
        {!isOpen &&
        <Box position="absolute" boxSize="100%" bg='blackAlpha.300'
             backdropFilter='auto' backdropBlur="3px"/>
        }
        <IconButton
          transitionProperty={"bottom"}
          transitionDuration={".25s"}
          transitionTimingFunction={"ease-out"}
          animation={isOpen ? animation : undefined}
          aria-label={title}
          icon={<TiArrowBackOutline style={{rotate: "-90deg"}}/>}
          onClick={onToggle}
          minHeight={"64px"}
          width={"64px"}
          position={isOpen ? "fixed" : "absolute"}
          bottom={isOpen ? "-38px" : "calc(50% - 32px)"}
          right="calc(50% - 32px)"
          bgColor={"blackAlpha.700"}
          border={0}
          color={"pure-white"}
          rounded="full"
          roundedBottom={isOpen ? 0 : "full"}
          _hover={isOpen ? {bottom: 0} : undefined}
        />
        <chakra.iframe
          loading={"lazy"}
          scrolling={isOpen ? "yes" : "no"}
          src={props.url}
          width="100%"
          height="100%"
          onLoad={(e) => setIframeLoaded(true)}
        />
      </Box>
    </Skeleton>
  )
}
