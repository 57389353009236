import * as React from "react";
import { useRef, useState } from "react";
import { Center, CircularProgress, SimpleGrid, Text } from "@chakra-ui/react";
import {Thread, useRelationshipApiResourceFetchGraphAssetsQuery} from "../../../api";
import { useTranslation } from "react-i18next";
import { AssetCard } from "../asset/AssetCard";
import { Pagination } from "../../components/Pagination";

export const ThreadBody: React.FC<any> = (props: { asset: Thread }) => {

  const thread = props.asset;

  const {t} = useTranslation();
  const ref = useRef(null);
  const [reqPageNumber, setReqPageNumber] = useState(1);
  const [reqPageSize, setReqPageSize] = useState(10);

  const {data, isLoading, isFetching} = useRelationshipApiResourceFetchGraphAssetsQuery({
    pageNumber: reqPageNumber,
    pageSize: reqPageSize,
    parent: thread.uuid
  });

  if(!thread) {
    return <></>;
  }

  return <>
    {(isLoading || isFetching) && <Center height="100vh" width="100%"><CircularProgress isIndeterminate/></Center>}
    <Center ref={ref} bg={"white"} minHeight="50vh" mb={4} p={[2, 8, 16]}>
      {data?.contents?.length ?
        <SimpleGrid columns={[1, 1, 1, 2, 3, 4, 5]} gap={12}>
          {data?.contents?.map((asset, i) => <AssetCard key={i} asset={asset}/>)}
        </SimpleGrid>
        :
        <Text size="lg">{t("This thread is empty.")}</Text>
      }
    </Center>
    {data &&
    <Pagination
      refocusOnPageChange={ref}
      total={data.total}
      pageSize={data.pageSize}
      pageNumber={data.pageNumber}
      setPageSize={setReqPageSize}
      setPageNumber={setReqPageNumber}
      isLoading={isLoading}
      isFetching={isFetching}
      statsOff={true}/>}
  </>
}
