import * as React from "react";
import {Avatar, StyleProps} from "@chakra-ui/react";
import {AnyAsset} from "../../../types";
import Utils from "../../../util/utils";
import {useTranslation} from "react-i18next";
import useSearchContext from "../../hooks/useSearchContext";
import {useParams} from "react-router-dom";

export const AssetCoin: React.FC<any> = ({
                                            asset,
                                            size,
                                            ...rest
                                         }: { asset: AnyAsset, size?: string } & StyleProps) => {
   const {t} = useTranslation();
   const title = (asset.title ? (asset.title + " ") : "") + "(" + t(asset.assetType as string) + ")"
   const {threadUuid} = useParams();
   const {isAssetQuery} = useSearchContext();
   const isCanvas = !!threadUuid || isAssetQuery;

   return (
         <Avatar {...rest} borderColor={"black"}
                 borderWidth={2}
                 cursor={isCanvas ? "pointer" : "auto"}
                 size={size || "md"}
                 title={title}
                 name={asset.title || "??"}
                 getInitials={(s) => ((s || "").replace(/[^A-Z]/ug, "") || "??")}
                 src={isCanvas ? Utils.safeCoverImage(0, asset) : Utils.getCoverImages(asset)[0]}
         />
   )
}
