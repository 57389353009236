import {useEffect, useMemo, useState} from 'react';

const LABIRINTO_STORAGE_KEY_PREFIX = "labirintoStorage";

/**
 * Wrapper for local storage states.
 * @param keyName the key for the localStorage
 * @param defaultValue the default value if the localStorage does not have it yet
 */
function useLocalStorageState<T>(keyName: string, defaultValue?: T) {

   const [value, setValue] = useState<T>();

   const key = useMemo(() => LABIRINTO_STORAGE_KEY_PREFIX + "_" + keyName, [keyName]);

   useEffect(() => {

      const curVal = localStorage.getItem(key);

      if (curVal !== null) {
         setValue(JSON.parse(curVal));
      } else {
         setValue(defaultValue);
      }

   }, [])

   useEffect(() => {

      if (value === undefined || value === null) {
         localStorage.removeItem(key);
      } else {

         const curVal = localStorage.getItem(key);
         const newVal = JSON.stringify(value);

         if (curVal !== newVal) {
            localStorage.setItem(key, newVal);
         }
      }

   }, [value])

   return [value, setValue] as const
}

export default useLocalStorageState;
