import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import * as React from "react";
import { Outlet } from "react-router-dom";
import { SearchContextProvider } from "../hooks/useSearchContext";


/**
 * Basic layout of the site with the top-level html elements rendered inside the <body>.
 * @param props
 * @constructor
 */
export const Main: React.FC<any> = (props) => {

  return (
    <SearchContextProvider>
      <header>
        <nav>
          <Header/>
        </nav>
      </header>
      <main>
        <Outlet/>
      </main>
      <footer>
        <Footer/>
      </footer>
    </SearchContextProvider>
  )
}