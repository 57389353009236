import * as React from "react";
import {
   Center,
   Container,
   Flex,
   Heading,
   Icon,
   Table,
   TableCaption,
   TableContainer,
   Tbody,
   Td,
   Text,
   Th,
   Thead,
   Tr
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {MdCheck} from "react-icons/md";
import {TbHeartRateMonitor} from "react-icons/tb";
import {useSearchApiResourceStatsQuery} from "../../api";
import {AssetType} from "../../types";

export const Status: React.FC<any> = (props) => {

   const {data} = useSearchApiResourceStatsQuery({scope: "DEFAULT"});
   const {t} = useTranslation()

   return (
         <Container>
            <Center boxSize={"100%"}>
               <Flex flexDir={"column"} gap={4} flex={1} bg={"pure-white"} boxShadow={"md"} rounded="base" my={6} p={4}>
                  <Heading as={"h1"}>{t("Status")}<Icon float={"right"}><TbHeartRateMonitor
                        size={"100%"}/></Icon></Heading>
                  <TableContainer>
                     <Table variant='simple'>
                        <TableCaption>{t("Status of our services and statistical information on our curated data currently stored.")}</TableCaption>
                        <Thead>
                           <Tr>
                              <Th>{t("Name")}</Th>
                              <Th>{t("Status/Stats")}</Th>
                           </Tr>
                        </Thead>
                        <Tbody>
                           <Tr>
                              <Td>{t("Graph DB")}</Td>
                              <Td><Icon><MdCheck size="100%" color={"green"}/></Icon> <Text as="i"
                                                                                            color={"gray.500"}>{t("Online")}</Text></Td>
                           </Tr>
                           <Tr>
                              <Td>{t("API")}</Td>
                              <Td><Icon><MdCheck size="100%" color={"green"}/></Icon> <Text as="i"
                                                                                            color={"gray.500"}>{t("Online")}</Text></Td>
                           </Tr>
                           <Tr>
                              <Td>{t("Identity Manager")}</Td>
                              <Td><Icon><MdCheck size="100%" color={"green"}/></Icon> <Text as="i"
                                                                                            color={"gray.500"}>{t("Online")}</Text></Td>
                           </Tr>
                           <Tr>
                              <Td>{t("Content Manager")}</Td>
                              <Td><Icon><MdCheck size="100%" color={"green"}/></Icon> <Text as="i"
                                                                                            color={"gray.500"}>{t("Online")}</Text></Td>
                           </Tr>
                           <Tr>
                              <Td>{t("Trusted Web Sources")}</Td>
                              <Td isNumeric>{data?.[AssetType.WebSite]}</Td>
                           </Tr>
                           <Tr>
                              <Td>{t("Web References")}</Td>
                              <Td isNumeric>{data?.[AssetType.WebLink]}</Td>
                           </Tr>
                           <Tr>
                              <Td>{t("Curated Content")}</Td>
                              <Td isNumeric>{Object.keys(data ?? {}).filter(k => !k.startsWith("We")).map(k => data?.[k])
                                    .reduce((p: number | undefined, n: number | undefined) => (p ?? 0) + (n ?? 0), 0)}</Td>
                           </Tr>
                        </Tbody>
                     </Table>
                  </TableContainer>
               </Flex>
            </Center>
         </Container>
   )
}
