import * as React from "react";
import {Badge, Center, Container, Flex, Heading} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";

export const About: React.FC<any> = () => {

   const {t} = useTranslation()

   return (
         <Container>
            <Center boxSize={"100%"}>
               <Flex flexDir={"column"} gap={4} flex={1} bg={"pure-white"} boxShadow={"md"} rounded="base" my={6} p={4}>
                  <Heading as={"h1"}>{t("About Us - Atlas")}<Badge ml={2} colorScheme={"red"}
                                                                   variant={"solid"}>{t("Coming Soon!")}</Badge></Heading>
               </Flex>
            </Center>
         </Container>
   )
}
