import { Center, chakra, forwardRef, ImageProps } from "@chakra-ui/react"


export const Logo = forwardRef<ImageProps, "img">((props, ref) => {

  return <Center as={"span"} position="relative" overflow="hidden" rounded="full" boxSize="3.25em" {...props}>
    <chakra.img src="/logo192.png" objectFit="cover" ref={ref}/>
  </Center>

})
