import * as React from "react";
import {PropsWithChildren, useCallback, useDeferredValue, useEffect, useMemo, useRef, useState} from "react";
import {GraphDragTarget, GraphQueryContextType} from "../../types";
import useClassifications from "./useClassifications";
import {
   Asset,
   Domain,
   EdgeRecord,
   NodeRecord,
   useCollectionApiResourceFetchAssetCollectionsQuery,
   useCollectsApiResourceCreateMutation,
   useCollectsApiResourceDeleteMutation,
   useCollectsApiResourceUpdateMutation,
   useRelationshipApiResourceFetchAssetRelationshipsQuery,
   useThreadApiResourceAssetThreadsQuery
} from "../../api";
import config from "../../theme/config";
import {useParams} from "react-router-dom";
import Utils from "../../util/utils";
import {Pin} from "../components/board/feature/Pin";
import {Wire} from "../components/board/feature/Wire";
import {
   Center,
   CloseButton,
   Flex,
   Slide,
   Tab,
   TabList,
   TabPanel,
   TabPanels,
   Tabs,
   Text,
   useBoolean,
   useDisclosure
} from "@chakra-ui/react";
import useRelationshipEditorContext from "./useRelationshipEditorContext";
import {useTranslation} from "react-i18next";
import {AssetRelations} from "../features/asset/AssetRelations";
import {useSize} from "@chakra-ui/react-use-size";
import {FiMaximize2, FiMinimize2} from "react-icons/fi";
import usePermissions from "./usePermissions";
import useWebLinkClipboard from "./useWebLinkClipboard";
import {Coordinates} from "../components/board";
import useConfirmationContext from "./useConfirmationContext";
import useToasts from "./useToasts";

const RELATIONSHIPS: Domain[] = ["RELATIONSHIPS"];

const NEW_ID_PREFIX = "new_";

const positionOrRandom = (pos: number | null | undefined, maxPos?: number) => {
   const max = maxPos || 280;
   if (pos || pos === 0) {
      return pos;
   }
   return Math.random() * max;
}

const COIN_BORDER_RADIUS_PX = 45;
const CARD_BORDER_RADIUS_PX = 5;

const UseGraphQueryContext = React.createContext<GraphQueryContextType>({} as GraphQueryContextType);

const useGraphQueryContext = () => React.useContext<GraphQueryContextType>(UseGraphQueryContext);

const GraphQueryContextProvider = ({
                                      nodes,
                                      edges,
                                      children,
                                   }: {
   nodes?: NodeRecord[],
   edges?: EdgeRecord[],
} & PropsWithChildren) => {

   const {t} = useTranslation();

   const toasts = useToasts();

   const ref = useRef<HTMLDivElement>(null);
   const box = useSize(ref);
   const pageSize = box?.width ? Math.ceil(box.width / config.cardWidth) : 0;

   const confirm = useConfirmationContext();

   const {isAuthor} = usePermissions();

   const editRelationship = useRelationshipEditorContext();

   const {
      isOpen: isOpenRelationships,
      onOpen: openRelationships,
      onClose: closeRelationships
   } = useDisclosure()

   const [updateCollects, updateCollectsResult] = useCollectsApiResourceUpdateMutation();
   const [createCollects, createCollectsResult] = useCollectsApiResourceCreateMutation();
   const [deleteCollects, deleteCollectsResult] = useCollectsApiResourceDeleteMutation();

   const {threadUuid, collectionUuid} = useParams();

   const [isDragEnabled, {
      on: enableDrag,
      off: disableDrag
   }] = useBoolean(isAuthor);

   const [isWiringEnabled, {
      on: enableWiring,
      off: disableWiring,
   }] = useBoolean();

   const {
      webLink: clipboardWebLink,
      reset
   } = useWebLinkClipboard(!isWiringEnabled && isAuthor && isDragEnabled, "graph");

   const [isPresentation, setPresentation] = useState(!isAuthor);
   const [nodeHeight, setNodeHeight] = useState<number>(isPresentation ? config.presentationCardHeight : config.cardHeight);
   const [nodeWidth, setNodeWidth] = useState<number>(isPresentation ? config.presentationCardWidth : config.cardWidth);

   const [isCoin, setCoin] = useState(false);

   const toPin = useCallback((node: NodeRecord): Pin => {
      return new Pin({
         id: node.data?.uuid || NEW_ID_PREFIX + Utils.uuid(),
         x: positionOrRandom(node.x),
         y: positionOrRandom(node.y),
         height: nodeHeight,
         width: nodeWidth,
         // TODO clustered: node.clustered,
         roundness: isCoin ? COIN_BORDER_RADIUS_PX : CARD_BORDER_RADIUS_PX
      }, node);
   }, [nodeHeight, nodeWidth, isCoin]);

   const [addCoordinatesUndeferred, setAddCoordinates] = useState<Coordinates>();
   const addCoordinates = useDeferredValue(addCoordinatesUndeferred);

   const [pins, setPins] = useState<{ [key: string]: Pin }>({});

   const [wires, setWires] = useState<{ [key: string]: Wire }>({});

   const classifierSlugs = useMemo(() => Array.from(new Set(edges?.map((e: EdgeRecord) => e.label || ""))).filter(e => !!e).sort(), [edges]);

   const labelsI18n = useClassifications({
      slugs: classifierSlugs,
      domains: RELATIONSHIPS,
      classifiersOnly: true,
      asHashtable: true
   });

   const toWire = useCallback((edge: EdgeRecord): Wire => {
      return new Wire({
         id: edge.relationshipUuid || NEW_ID_PREFIX + Utils.uuid(),
         source: pins[edge.startUuid || ""],
         target: pins[edge.endUuid || ""],
         label: edge.label ? (labelsI18n[edge.label] || edge.label) : undefined
      }, edge);
   }, [pins, labelsI18n]);

   const [selection, setSelection] = useState<Asset>();

   useEffect(() => {
      if (isAuthor) {
         if (isWiringEnabled) {
            disableDrag();
         } else {
            enableDrag();
         }
      }
   }, [isWiringEnabled, isAuthor]);

   useEffect(() => {
      setNodeHeight(isCoin ? config.coinHeight : isPresentation ? config.presentationCardHeight : config.cardHeight);
      setNodeWidth(isCoin ? config.coinWidth : isPresentation ? config.presentationCardWidth : config.cardWidth);
   }, [isCoin, isPresentation])

   useEffect(() => {
      setPins((curPins) => Utils.merge(curPins, Utils.toHashtable(nodes, (n: NodeRecord) => n.data?.uuid, toPin)));
   }, [nodes, toPin])

   useEffect(() => {
      if (Object.keys(pins).length) {
         setWires((curWires) => Utils.merge(curWires, Utils.toHashtable(edges, (n: EdgeRecord) => n.relationshipUuid, toWire)));
      }
   }, [pins, edges, toWire])

   const addAssetWire = useCallback((start: string | Asset, end: string | Asset, x?: number, y?: number) => {
      editRelationship({
         from: typeof start === "string" ? pins[start].data.data : start,
         to: typeof end === "string" ? pins[end].data.data : end,
         threadUuid,
         threadCoordinates: typeof x === "number" && typeof y === "number" ? {
            x,
            y
         } : undefined
      })
   }, [threadUuid, pins, editRelationship]);

   const editRelationshipWire = useCallback((wire: Wire) => {
      editRelationship({
         from: (wire.source as Pin).data.data,
         to: (wire.target as Pin).data.data,
         threadUuid,
         relationshipUuid: wire.data.relationshipUuid,
         relationshipVersion: wire.data.relationshipVersion,
         relationshipClassifier: wire.data.label,
         threadsUuid: wire.data.uuid,
         threadsVersion: wire.data.version,
         threadsInverted: wire.data.inverted,
      })
   }, [threadUuid, editRelationship]);

   const addAssetPin = useCallback((assetUuid: string, x?: number, y?: number) => {

      createCollects({
         collectsMutationRequest: {
            startUuid: threadUuid || collectionUuid,
            endUuid: assetUuid,
            x: typeof x === "number" ? x : 0,
            y: typeof y === "number" ? y : 0
         }
      })
   }, [threadUuid, collectionUuid, createCollects]);

   const moveAssetPin = useCallback((assetUuid: string, x: number, y: number) => {
      const node = pins[assetUuid].data;
      const uuid = node.uuid;
      const version = node.version;
      updateCollects({
         uuid,
         collectsMutationRequest: {
            uuid,
            version,
            x,
            y
         }
      })
   }, [updateCollects, pins]);

   const removeAssetPins = useCallback((assetUuid: string | string[]) => {

      const uuids = Array(assetUuid).flat().map(uuid => pins[uuid].data.uuid);

      if (Object.values(wires).some(w => uuids.indexOf(w.source?.id) >= 0 || uuids.indexOf(w.target?.id) >= 0)) {

         confirm({
            title: t("Oops!"),
            message: t("Before you remove it, please check that it has no connections."),
            cancelLabel: false,
            confirmLabel: t("Okay"),
            onConfirm: () => void 0
         })
      } else {

         deleteCollects({
            batchRequest: {uuids}
         })
      }

   }, [pins, wires, deleteCollects]);

   /////////////////////////////
   // RELATED CONTENTS
   const skipLoadingRelatedContents = !selection || !isOpenRelationships;

   const [relationshipsPage, setRelationshipsPage] = useState(1);
   const relationships = useRelationshipApiResourceFetchAssetRelationshipsQuery({
      asset: selection?.uuid!,
      pageNumber: relationshipsPage,
      pageSize
   }, {
      skip: skipLoadingRelatedContents
   });

   const [collectionsPage, setCollectionsPage] = useState(1);
   const collections = useCollectionApiResourceFetchAssetCollectionsQuery({
      asset: selection?.uuid!,
      pageNumber: collectionsPage,
      pageSize
   }, {
      skip: skipLoadingRelatedContents
   });

   const [threadsPage, setThreadsPage] = useState(1);
   const threads = useThreadApiResourceAssetThreadsQuery({
      asset: selection?.uuid!,
      pageNumber: threadsPage,
      pageSize
   }, {
      skip: skipLoadingRelatedContents
   });

   useEffect(() => {
      setRelationshipsPage(1);
      setCollectionsPage(1);
      setThreadsPage(1);
   }, [selection, setRelationshipsPage, setCollectionsPage, setThreadsPage])

   const hasRelationships = (relationships.data?.contents?.length ?? 0) > 0 || relationshipsPage > 1;
   const hasCollections = (collections.data?.contents?.length ?? 0) > 0 || collectionsPage > 1;
   const hasThreads = (threads.data?.contents?.length ?? 0) > 0 || threadsPage > 1;

   const [isDockedRelationships, {
      toggle: toggleDockedRelationships,
      on: dockRelationships,
   }] = useBoolean(false);

   const [dragTarget, setDragTarget] = useState<GraphDragTarget>();

   useEffect(() => {
      if (dragTarget) {
         dockRelationships();
      }
   }, [dragTarget])

   useEffect(() => {
      if (clipboardWebLink?.uuid) {
         if (clipboardWebLink?.uuid in pins) {
            toasts.graphAlreadyContainsNodeWarning()
         } else {
            addAssetPin(clipboardWebLink?.uuid, addCoordinates?.x, addCoordinates?.y);
         }
         reset(true);
      }
   }, [clipboardWebLink, pins, addCoordinates, toasts])

   return <UseGraphQueryContext.Provider value={{
      isWorking: deleteCollectsResult.isLoading || createCollectsResult.isLoading || updateCollectsResult.isLoading,
      pins,
      wires,
      nodeWidth,
      nodeHeight,
      selection,
      setSelection,
      isCoin,
      setCoin,
      addAssetPin,
      removeAssetPins,
      addAssetWire,
      moveAssetPin,
      editRelationshipWire,
      isDragEnabled,
      enableDrag,
      disableDrag,
      isWiringEnabled,
      enableWiring,
      disableWiring,
      openRelationships,
      isOpenRelationships,
      setDragTarget : isAuthor ? setDragTarget : undefined,
      dragTarget,
      addCoordinates,
      setAddCoordinates,
      isPresentation,
      setPresentation
   }}>
      {children}
      <Slide direction="bottom" in={isOpenRelationships} style={{zIndex: "popover"}}>
         <Flex ref={ref}>
            {selection ?
                  (!hasThreads && !hasRelationships && !hasCollections) ?
                        <Center w={"100%"} h={"100%"}>
                           <Text>{t("Sorry, but I go nothing for you! :(")}</Text>
                        </Center> :
                        <Tabs variant="enclosed" colorScheme={"darken"} width={"100%"}>
                           <TabList>
                              {hasRelationships && <Tab bg={"gray.50"}>{t("Related Contents")}</Tab>}
                              {hasCollections && <Tab bg={"gray.50"}>{t("Collections")}</Tab>}
                              {hasThreads && <Tab bg={"gray.50"}>{t("Threads")}</Tab>}
                              <Flex bg={"primary.50"} roundedTop={"md"} px={2} borderTopWidth={1} borderRightWidth={1}
                                    borderColor={"gray.100"} gap={2}>
                                 <CloseButton alignSelf={"center"}
                                              onClick={toggleDockedRelationships}>{isDockedRelationships ?
                                       <FiMaximize2 size={"50%"}/> : <FiMinimize2 size={"50%"}/>}</CloseButton>
                                 <CloseButton alignSelf={"center"} onClick={closeRelationships}/>
                              </Flex>
                           </TabList>
                           <TabPanels bg={"gray.50"} maxHeight={isDockedRelationships ? 0 : "100vh"}
                                      transition={"max-height .5s ease"}>
                              {hasRelationships &&
                                    <TabPanel px={0}>
                                       <AssetRelations
                                             parentId={selection?.uuid}
                                             onChange={(offset: number) => {
                                                if (offset > 0) {
                                                   setRelationshipsPage((p) => p + offset)
                                                }
                                             }}
                                             assets={relationships.data?.contents?.map(r => [r.start, r.end]).flat().filter(a => a?.uuid !== selection?.uuid)}
                                             relationships={relationships.data?.contents}/>
                                    </TabPanel>
                              }

                              {hasCollections &&
                                    <TabPanel px={0}>
                                       <AssetRelations
                                             parentId={selection?.uuid}
                                             onChange={(offset: number) => {
                                                if (offset > 0) {
                                                   setCollectionsPage((p) => p + offset)
                                                }
                                             }}
                                             assets={collections.data?.contents?.filter(a => a.uuid !== selection?.uuid)}/>
                                    </TabPanel>
                              }
                              {hasThreads &&
                                    <TabPanel px={0}>
                                       <AssetRelations
                                             parentId={selection?.uuid}
                                             onChange={(offset: number) => {
                                                if (offset > 0) {
                                                   setThreadsPage((p) => p + offset)
                                                }
                                             }}
                                             assets={threads.data?.contents?.filter(a => a.uuid !== selection?.uuid)}/>
                                    </TabPanel>
                              }
                           </TabPanels>
                        </Tabs> :
                  <Center w={"100%"} h={"100%"}>
                     <Text>{t("Select a card to start browsing through related contents.")}</Text>
                  </Center>
            }
         </Flex>
      </Slide>
   </UseGraphQueryContext.Provider>
}

export default useGraphQueryContext;
export {GraphQueryContextProvider};
