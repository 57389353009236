const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)"
};
export const Form = {
  variants: {
    floating: {
      container: {
        input: {
          border: 0,
          color: "transparent"
        },
        _focusWithin: {
          input: {
            border: 1,
            bg: "pure-white",
            color: "black"
          },
          label: {
            ...activeLabelStyles
          }
        },
        label: {
          top: 0,
          left: 0,
          zIndex: 2,
          position: "absolute",
          bg: "transparent",
          mx: 3,
          px: 1,
          my: 2,
          transformOrigin: "left top"
        }
      }
    },
    "single-select": {
      container: {
        input: {
          border: 0,
          color: "transparent",
          ":not(:placeholder-shown)": {
            color: "black",
            "~ label": {
              ...activeLabelStyles
            }
          }
        },
        _focusWithin: {
          input: {
            border: 1,
            bg: "pure-white",
            color: "black"
          },
          label: {
            ...activeLabelStyles
          }
        },
        label: {
          top: 0,
          left: 0,
          zIndex: 2,
          position: "absolute",
          bg: "transparent",
          mx: 3,
          px: 1,
          my: 2,
          transformOrigin: "left top"
        }
      }
    },
    "single-select-slim": {
      container: {
        input: {
          border: 0,
          p: 1,
          color: "transparent",
          ":not(:placeholder-shown)": {
            color: "inherit",
            "~ label": {
              visibility: "hidden",
              ".chakra-icon": {
                visibility: "visible"
              }
            }
          }
        },
        _focusWithin: {
          input: {
            boxShadow: "none",
            border: 0,
            bg: "inherit",
            color: "inherit"
          },
          label: {
            visibility: "hidden"
          }
        },
        label: {
          top: "50%",
          left: 0,
          transform: "translate(0,-50%)",
          position: "absolute",
          bg: "transparent",
          p: 1,
          transformOrigin: "left top"
        }
      }
    }
  }
}

export default Form;