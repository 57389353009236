import * as React from "react"
import { Visibility } from "../../../api";
import { RiGitRepositoryPrivateLine, RiGroupLine, RiUserSharedLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";

export const VisibilityIcon: React.FC<any> = (props: { visibility: Visibility }) => {

  const {t} = useTranslation();

  const icon = (): JSX.Element => {

    switch(props.visibility) {
      case "PROTECTED":
        return <RiGroupLine title={t(props.visibility as string)} size="100%"/>;
      case "PRIVATE":
        return <RiGitRepositoryPrivateLine title={t(props.visibility as string)} size="100%"/>;
      //return <BsGlobe size={"100%"}/>;
      case "SHARED":
        return <RiUserSharedLine title={t(props.visibility as string)} size="100%"/>;
      case "PUBLIC":
      default:
        return <></>;
    }
  }

  return icon();
}
