/* eslint-disable react/no-unstable-nested-components */
import React, {startTransition, useCallback, useDeferredValue, useEffect, useMemo, useRef, useState} from 'react';
import {BoardProps} from "../index";
import * as d3 from "d3";
import PinComponent from "../component/PinComponent";
import WireComponent from "../component/WireComponent";
import Util from "../../../../util/utils";
import {BoardContext} from "../context/BoardContext";
import {Wire} from "./Wire";
import {Pin} from "./Pin";
import {MAX_CHANGES, MAX_SCALE, MIN_SCALE, ZOOM_SENSITIVITY} from "../constants";
import {useBoolean} from "@chakra-ui/react";

const Board = ({
                  pins,
                  wires,
                  draggable,
                  translatable, // TODO
                  scalable, // TODO
                  selectionColor,
                  color,
                  bgColor,
                  onChange,
                  onWireClick,
                  children,
                  onDropHandler,
                  onDropCoordinatesChange,
                  center
               }: BoardProps) => {

   const containerRef = useRef<HTMLDivElement>(null);

   const [lastChange, setLastChange] = useState(0);
   const [lastChangeTarget, setLastChangeTarget] = useState({
      version: 0,
      target: null
   });
   const [pinArray, setPinArray] = useState(pins);
   const [wireArray, setWireArray] = useState(wires);
   const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
   const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
   const [pinNodes, setPinNodes] = useState<any>({});
   const [selection, setSelection] = useState<string>();
   const [focus, setFocus] = useState<string>();
   const zoom = useMemo(() => d3.zoom(), []);

   const [animate] = useBoolean(false)

   const [transform, setTransform] = useState<{ x: number, y: number, k: number }>({
      x: 0,
      y: 0,
      k: 1
   })

   const defTransform = useDeferredValue(transform);

   const defPinArray = useDeferredValue(pinArray);

   useEffect(() => {
      setPinNodes((pns: any) => {
         const hashtable = Util.toHashtable(children);

         for (const k in pns) {
            if (!(k in hashtable)) {
               delete pns[k];
            }
         }

         return Object.keys(pns).length ? {...pns, ...hashtable} : hashtable;
      })
   }, [children])

   const [changes, setChanges] = useState([{
      pins: defPinArray,
      wires: wireArray
   }]);

   const onResizeHandler = useCallback(() => {
      setViewportWidth((w) => Math.max(w, window.innerWidth));
      setViewportHeight((h) => Math.max(h, window.innerHeight));
   }, []);

   const onUndoRedoHandler = useCallback((e: KeyboardEvent) => {
      setLastChange(lc => {
         let targetChangeIndex = lc;

         if ((e.key === "Undo" || (e.ctrlKey && e.key === "z")) && lc > 0) {
            targetChangeIndex = lc - 1;
         } else if ((e.key === "Redo" || (e.ctrlKey && e.key === "y") || (e.ctrlKey && e.shiftKey && (e.key === "z" || e.key === "Z"))) && lc < changes.length - 1) {
            targetChangeIndex = lc + 1;
         }

         if (targetChangeIndex !== lc) {

            const targetChange = changes[targetChangeIndex];

            setWireArray(targetChange.wires);
            setPinArray(targetChange.pins);
            onChange?.(targetChange);
         }

         return targetChangeIndex;
      })
   }, [changes, onChange]);

   useEffect(() => {
      window.addEventListener("resize", onResizeHandler);
      window.addEventListener("keydown", onUndoRedoHandler);

      return () => {
         window.removeEventListener("resize", onResizeHandler);
         window.removeEventListener("keydown", onUndoRedoHandler);
      }
   }, [onResizeHandler, onUndoRedoHandler])

   useEffect(() => {
      let mw = 0;
      let mh = 0;

      for (const pin of pins) {
         mw = Math.max(mw, pin.width + pin.x)
         mh = Math.max(mh, pin.height + pin.y)
      }

      setViewportWidth((w) => Math.max(w, mw));
      setViewportHeight((h) => Math.max(h, mh));
      setPinArray(pins);
   }, [pins])

   useEffect(() => {
      setWireArray(wires);
   }, [wires])

   useEffect(() => {

      const boardNode = containerRef.current;

      if (boardNode) {
         d3.select(boardNode).call(zoom
               .extent([[0, 0], [viewportWidth, viewportHeight]])
               .scaleExtent([MIN_SCALE, MAX_SCALE])
               .wheelDelta((e: any) => e.deltaY * -0.001 * ZOOM_SENSITIVITY)
               .translateExtent([[Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER], [Number.MAX_SAFE_INTEGER, Number.MAX_SAFE_INTEGER]])
               .touchable(true)
               .on('end', (e: any) => onDropCoordinatesChange(transformClientToBoard(e.transform)))
               .on("zoom", (e: any) => setTransform(e.transform)) as any)
      }

      return () => {
         if (boardNode) {
            d3.select(boardNode).call(zoom.on("end", null).on("zoom", null) as any);
         }
      }

   }, [containerRef, viewportWidth, viewportHeight])

   const transformClientToBoard = useCallback((transform: any, clientX?: number, clientY?: number) => {
      const bbox = containerRef?.current?.getBoundingClientRect();

      const positionX = clientX || ((bbox?.width ?? 75) / 3);
      const positionY = clientY || ((bbox?.height ?? 150) / 3);

      return {
         x: (positionX - (bbox?.x ?? 0) - transform.x) / transform.k,
         y: (positionY - (bbox?.y ?? 0) - transform.y) / transform.k
      }
   }, [containerRef]);

   // TODO: stack and process specific actions only (addPin, addWire, editWire, movePin)
   const stackChange = useCallback((change: any) => {
      setChanges((c) => {
         if (c.length < MAX_CHANGES) {
            setLastChange((lc) => lc + 1);
            return [...c.slice(0, lastChange + 1), change];
         }
         return [...c.slice(1, c.length), change]
      })
   }, [lastChange])

   const onPinChangeHandler = useCallback((e: any) => {
      setPinArray(pa => pa.map((p, idx) => {
         if (p.id === e.pinId) {
            const pin = new Pin({
               ...p,
               x: p.x + e.deltaX,
               y: p.y + e.deltaY
            }, p.data);
            // replace the new pin in the wires
            setWireArray(wa => wa.map(w => {
               if (w.source.id === pin.id) {
                  return new Wire({
                     ...w,
                     source: pin
                  }, w.data)

               } else if (w.target.id === pin.id) {
                  return new Wire({
                     ...w,
                     target: pin
                  }, w.data)
               }

               return w;
            }))
            return pin;
         } else {
            return p;
         }
      }))
   }, []);

   /* eslint-disable */
   useEffect(() => {
      if (lastChangeTarget.version > 0) {
         const state = {
            pins: defPinArray,
            wires: wireArray,
            ...lastChangeTarget
         }
         stackChange(state);
         onChange?.(state);
      }
   }, [lastChangeTarget]);
   /* eslint-enable */

   const updateFocus = useCallback((target: string) => {

      setFocus(curFocus => {

         const boardNode = containerRef.current;

         if (boardNode && target && curFocus !== target) {

            const focusedPin = pinArray.find(p => target === p.id);

            if (focusedPin) {

               const bbox = boardNode.getBoundingClientRect();

               console.log("dne")

               //zoomTransform(boardNode.querySelector("#pins"))
               d3.select(boardNode).transition().duration(750).call(
                     zoom.transform as any,
                     d3.zoomIdentity
                           .translate((bbox?.width ?? 75) / 2, (bbox?.height ?? 150) / 2)
                           .scale(1)
                           .translate(-focusedPin.getPinCenterX(), -focusedPin.getPinCenterY())
               );
               return target;
            }
         }

         return curFocus;
      })

   }, [containerRef, pinArray, transform, transformClientToBoard])

   useEffect(() => {

      if (center && center in pinNodes && focus !== center) {

         startTransition(() => {
            updateFocus(center)
         })
      }
   }, [center, pinNodes, focus, updateFocus]);

   const onChangeCallback = useCallback((id: any) => setLastChangeTarget((v) => ({
      version: v.version + 1,
      target: id,
   })), [setLastChangeTarget])

   return <BoardContext.Provider value={{
      transform: defTransform,
      setTransform,
      draggable,
      color,
      bgColor,
      selectionColor,
      selection,
      setSelection,
      focus,
      setFocus: updateFocus,
      onChange: onChangeCallback
   }}>
      <div id="board"
           onDragOver={(e) => e.preventDefault()}
           onDrop={(e) => {
              onDropHandler(transformClientToBoard(defTransform, e.clientX, e.clientY))
           }}
           ref={containerRef}
           style={{
              width: `100%`,
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflow: "hidden",
           }}>
         <div id="content" style={{
            flex: 1,
            margin: "auto",
            position: "relative",
            width: "100%",
            height: "100%",
            cursor: "grab",
            background: bgColor,
         }}>
            <svg id="wires" style={{
               position: "absolute",
               top: 0,
               left: 0,
               zIndex: 0,
               transformOrigin: "0 0",
               transition: animate ? "transform .5s ease" : "none"
            }} width={viewportWidth} height={viewportHeight}>
               <marker id="arrow-start" markerWidth="3.25" markerHeight="3.25" refX="1.625" refY="1.625"
                       viewBox="0 0 3.25 3.25" orient="auto">
                  <circle cx="1.625" cy="1.625" r="1.625" fill={color}/>
               </marker>
               <marker id="arrow-end" markerWidth="5" markerHeight="5" refX="4.25" refY="2.5" viewBox="0 0 5 5"
                       orient="auto">
                  <polygon points="0, 5 1, 2.5 0, 0 5, 2.5" fill={color}></polygon>
               </marker>
               <marker id="arrow-mid" markerWidth="5" markerHeight="5" refX="0" refY="0" viewBox="0 0 5 5"
                       orient="auto">
                  <circle cx="2.5" cy="2.5" r="2.5" fill="red">
                     <text textAnchor={"middle"} color={"white"}>x</text>
                  </circle>
               </marker>
               <g transform={`translate(${Math.round(defTransform.x)}, ${Math.round(defTransform.y)}) scale(${Math.round(defTransform.k * 1000) / 1000})`}>
                  {useMemo(() => (wireArray || []).map((w) =>
                        <WireComponent onClick={() => onWireClick(w)} key={w.id} {...w.toProps()} />
                  ), [wireArray])}
               </g>
            </svg>
            <div id="pins" style={{
               position: "relative",
               transformOrigin: "0 0",
               zIndex: 0,
               height: `${viewportHeight}px`,
               width: `${viewportWidth}px`,
               transition: animate ? "transform .5s ease" : "none",
               transform: `translate(${Math.round(defTransform.x)}px, ${Math.round(defTransform.y)}px) scale(${Math.round(defTransform.k * 1000) / 1000})`,
               pointerEvents: "none"
            }}>
               {useMemo(() => (defPinArray || []).map((p) => (
                     <PinComponent key={p.id} {...p}
                                   onChange={onPinChangeHandler}>
                        {pinNodes[p.id]}
                     </PinComponent>
               )), [defPinArray, onPinChangeHandler, pinNodes])}
            </div>
         </div>
      </div>
   </BoardContext.Provider>
};

export default Board;
