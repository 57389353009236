import * as React from "react"
import {useDeferredValue, useEffect, useState} from "react"
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Slide,
  Spinner,
  Text,
  Tooltip,
  useBoolean,
  VStack
} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import {BiPencil} from "react-icons/bi";
import {MdClear} from "react-icons/md";
import {AutoCompleteProps} from "../../../types";

export const AutoComplete: React.FC<any> = (props: AutoCompleteProps) => {

  const {t} = useTranslation();
  const [query, setQuery] = useState(props.defaultValue || "");
  const [isEditing, {on, off}] = useBoolean(false);
  const deferredQuery = useDeferredValue(query);

  const {onChange} = props;

  const labelSelector = props.labelSelector || ((e: unknown) => e);
  const suggestions = props.suggestions;
  const placeholder = t("Click here to edit the content");

  let editTimeout: any;

  const startEditing = () => {
    if(editTimeout) {
      clearTimeout(editTimeout);
      editTimeout = null;
      props.onStartEditing?.()
    }
    on();
  }

  const stopEditing = () => {
    if(!editTimeout) {
      editTimeout = setTimeout(() => {
        props.onStopEditing?.()
        off();
      }, 200);
    }
  }

  const handleChangeValue = (newValue: any) => {

    props.onSubmit?.(newValue);
  }

  const NoSuggestionsFound = React.memo(() => {
    const {t} = useTranslation();
    return <Text as="i">{props.emptySuggestionsMessage || t("Oops, I got nothing...")}</Text>
  });

  useEffect(() => {
    onChange?.(deferredQuery);
  }, [deferredQuery, onChange])


  return (
    <Box maxWidth={280}>
      <FormControl variant="floating">
        <InputGroup>
          <Input autoComplete="off"
                 onFocus={startEditing}
                 onBlur={stopEditing}
                 value={query} onChange={(e) => setQuery(e.target.value)}
                 onKeyDown={(e) => {if(e.key === "Enter" && suggestions?.length === 1) {handleChangeValue(suggestions[0])}}}/>
          {deferredQuery && isEditing &&
          <InputRightElement width='3rem' alignItems="space-between">
            <IconButton
              size="md"
              border="none"
              colorScheme="transparent"
              onClick={() => setQuery("")}
              isLoading={props.isLoading}
              icon={<MdClear/>}
              aria-label={t("Clear the current search")}/>
          </InputRightElement>
          }
          {props.label &&
          <Tooltip color="pure-white" label={placeholder} colorScheme="primary">
            <FormLabel onClick={startEditing} cursor={"pointer"}>{props.label} {!isEditing && <Icon cursor={"pointer"}><BiPencil/></Icon>}</FormLabel>
          </Tooltip>
          }
        </InputGroup>
        <Box position="relative" zIndex="dropdown">
          <Slide direction="bottom" in={isEditing && (props.isLoading || !!((props.allowEmptyQuery || deferredQuery) && suggestions))}
                 style={{position: "absolute", top: "7px", height: (isEditing ? undefined : 0)}}>
            {isEditing && (props.isLoading || !!((props.allowEmptyQuery || deferredQuery) && suggestions)) &&
            <VStack alignItems="flex-start" maxH={200} overflow="auto" bg="pure-white" rounded="lg" p={2} minW="25ch" onScroll={startEditing}
                    onBlur={stopEditing}>
              {
                (props.isLoading) ?
                  <Spinner size='sm'/>
                  :
                  suggestions?.length ?
                    suggestions.map((sug: any, i: number) =>
                      <Button key={i} bg={"pure-white"}
                              minW="25ch"
                              width={"100%"}
                              justifyContent={"flex-start"}
                              px={2}
                              title={labelSelector(sug)}
                              border={0}
                              size={"sm"}
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              onClick={() => {
                                handleChangeValue(sug)
                                stopEditing();
                              }}>{labelSelector(sug)}</Button>)
                    :
                    <NoSuggestionsFound/>
              }
            </VStack>
            }
          </Slide>
        </Box>
      </FormControl>
    </Box>
  )
}

