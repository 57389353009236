import * as React from "react"
import {useEffect, useState} from "react"
import {useToken} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const modules = {
   toolbar: [
      ['bold', 'italic', 'underline', 'blockquote'],
      [{'size': ['small', false, 'large', 'huge']}],
      [{align: ''}, {align: 'center'}, {align: 'right'}, {align: 'justify'}],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['clean']
   ],
}

const formats = [
   'size',
   'bold', 'italic', 'underline', 'blockquote',
   'align',
   'list', 'bullet', 'indent',
]

export const RichTextArea: React.FC<any> = ({
                                               onChange,
                                               placeholder,
                                               value
                                            }: { value: string, onChange?: Function, placeholder?: string }) => {

   const {t} = useTranslation();
   const [hasChangesBorderColor] = useToken("colors", ["primary.600"])

   const placeholderValue = placeholder || t("Click to edit the text");
   const [inputValue, setInputValue] = useState(value);

   useEffect(() => setInputValue(value), [value])

   return (
         (value || onChange) ?
               <ReactQuill
                     readOnly={!onChange}
                     className={onChange ? "rich-textarea" : undefined}
                     onBlur={() => onChange?.(inputValue)}
                     placeholder={placeholderValue}
                     theme={"snow"}
                     value={inputValue}
                     preserveWhitespace={true}
                     onChange={setInputValue}
                     modules={onChange ? modules : {toolbar: null}}
                     formats={onChange ? formats : []}
                     style={{border: !onChange ? "none" : inputValue === value ? "none" : `2px inset ${hasChangesBorderColor}`}}
               />
               :
               <></>
   )
}

