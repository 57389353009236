import * as React from "react";
import {ResponsiveValue, Text} from "@chakra-ui/react";
import Utils from "../../../util/utils";
import {AnyAsset} from "../../../types";
import {Highlighted} from "../../components/Highlighted";

export const AssetQuickDescription: React.FC<any> = (props: { asset: AnyAsset, highlight?: string, fontSize?: ResponsiveValue<number> | ResponsiveValue<string>, noOfLines?: ResponsiveValue<number> | false }) => {

   return (
         props.asset.description ?
               <Text fontSize={props.fontSize || {
                  base: "xs",
                  md: "md"
               }} noOfLines={props.noOfLines === false ? undefined : (props.noOfLines || 3)}>
                  {
                     props.highlight ?
                           Highlighted({
                              text: Utils.html2Text(props.asset.description),
                              highlight: props.highlight
                           })
                           : Utils.html2Text(props.asset.description)
                  }
               </Text>
               :
               <></>
   )
}
