import * as React from "react";
import {useCallback, useEffect, useState} from "react";
import {AssetCard} from "../asset/AssetCard";
import {Pagination} from "../../components/Pagination";
import {SimpleGrid, SkeletonText, Spacer, Text} from "@chakra-ui/react";
import {useTranslation} from "react-i18next";
import useSearchContext from "../../hooks/useSearchContext";
import {AnyAsset} from "../../../types";
import config from "../../../theme/config";

const PADDING = 20;
const ITEM_WIDTH = config.cardWidth + PADDING;
const ITEM_HEIGHT = config.cardHeight + PADDING;
export const SearchResults: React.FC<any> = () => {

   const {t} = useTranslation();
   const {
      assets,
      isFetching,
      isLoading,
      pageNumber,
      pageSize,
      query,
      setPageNumber,
      setPageSize,
      timeInMillis
   } = useSearchContext();

   const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
   const [viewportHeight, setViewportHeight] = useState(window.innerHeight);

   const onResizeHandler = useCallback(() => {
      setViewportWidth(w => Math.max(w, window.innerWidth));
      setViewportHeight(h => Math.max(h, window.innerHeight));
   }, [setViewportWidth, setViewportHeight]);

   useEffect(() => {
      window.addEventListener("resize", onResizeHandler);

      return () => {
         window.removeEventListener("resize", onResizeHandler);
      }
   }, [onResizeHandler])

   useEffect(() => {
      const cols = Math.max(Math.trunc((viewportWidth - 2 * PADDING) / ITEM_WIDTH), 1);
      const rows = Math.max(Math.trunc((viewportHeight - 2 * PADDING) / ITEM_HEIGHT), 1);
      const newPageSize = Math.max(rows * cols, 10)
      if (pageSize !== newPageSize) {
         setPageSize(newPageSize);
      }
   }, [setPageSize, viewportWidth, viewportHeight, pageSize]);

   return (
         !isLoading && !isFetching && (assets?.total ?? 0) > 0 ?
               <>
                  {query &&
                        <Text alignSelf={"flex-start"} px={`${PADDING}px`}>{t("Showing results for")} <Text
                              as="b">{query}</Text>.</Text>}
                  <SimpleGrid
                        mx="auto"
                        width={"full"}
                        justifyItems={"center"}
                        px={`${PADDING}px`}
                        minChildWidth={config.cardWidth}
                        spacing={`${PADDING}px`}>
                     {assets?.contents?.map((r: AnyAsset, i: number) =>
                           <AssetCard key={i} asset={r} highlight={query}/>
                     )}
                  </SimpleGrid>
                  <Pagination
                        {...assets}
                        timeInMillis={timeInMillis}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        isLoading={isLoading}
                        isFetching={isFetching}

                  />
               </>
               :
               assets?.contents ?
                     <>
                        <Spacer/>
                        <SkeletonText minHeight={"480px"} width={"80%"} maxW="930px"
                                      isLoaded={!isLoading && !isFetching}>
                           {query ?
                                 <Text size="lg">{t("Sorry, got nothing for")} <Text as="i"
                                                                                     fontWeight="bold">{query}</Text></Text>
                                 :
                                 <Text size="lg">{t("Sorry, got nothing.")}</Text>
                           }
                        </SkeletonText>
                        <Spacer mb={["20em"]}/>
                     </>
                     : <Spacer my="auto"/>
   )
}
