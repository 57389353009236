import * as React from "react"
import {useEffect, useState} from "react"
import {HStack, Stack, Tag, TagCloseButton, TagLabel, Text, Wrap, WrapItem} from "@chakra-ui/react";
import {MultiSelectProps} from "../../../types";
import {AutoComplete} from "./AutoComplete";

export const MultiSelect: React.FC<any> = ({
                                              label,
                                              labelSelector,
                                              maxElements,
                                              maxWidth,
                                              onChange: isEditable,
                                              onValueClick,
                                              queryBuilder,
                                              useQuery,
                                              valueSelector,
                                              values,
                                              justify,
                                              allowEmptyQuery,
                                              ...rest
                                           }: MultiSelectProps) => {

   const [items, setItems] = useState(values || []);
   const [query, setQuery] = useState("");

   useEffect(() => {
      setItems(values || []);
   }, [values])

   const max = maxElements || items.length;
   const isEllipsis = max && maxWidth;

   const queryResult = useQuery?.(queryBuilder ? queryBuilder(query) : {query}, {skip: !allowEmptyQuery && !query});

   const render = values?.length || isEditable;

   const toLabel = labelSelector || ((e: unknown) => e);
   const toValue = valueSelector || ((e: unknown) => e);

   const handleSubmit = (value: any, remove?: boolean) => {

      const targetValue = toValue(value);
      const newValues = remove ? items.filter(v => toValue(v) !== targetValue) : [...items, value];

      try {
         isEditable?.(newValues.map(v => toValue(v)));
         setItems(newValues)
         if (!remove) {
            setQuery("");
         }
      } catch (e) {
         console.trace("Component caught error onChange, skipping value update", e)
      }
   }

   const ViewWithEllipsis = () => <HStack maxWidth={maxWidth || "100%"} flex={0}
                                          mr={3}>{items.filter((t, i) => i < max).map((t, i) =>
         <Tag key={i} noOfLines={1} px={2} minHeight={5} lineHeight={1.5} {...rest}>{toLabel(t)}</Tag>)}
   </HStack>

   const ViewFull = () => <Wrap>
      {items.map((t, i) =>
            <WrapItem key={i}><Tag {...rest}>
               <TagLabel cursor={onValueClick ? "pointer" : "default"}
                         onClick={() => onValueClick?.(t)}>{toLabel(t)}</TagLabel>
               {isEditable &&
                     <TagCloseButton onClick={() => handleSubmit(t, true)}/>}
            </Tag>
            </WrapItem>)
      }
   </Wrap>

   const suggestions = queryResult?.data?.filter((sug: any) => !items.find(v => toValue(v) === toValue(sug))) || [];

   return (
         render ?
               <Stack>
                  {isEditable ?
                        <AutoComplete
                              label={label}
                              isLoading={queryResult?.isLoading || queryResult?.isFetching}
                              suggestions={suggestions}
                              labelSelector={toLabel}
                              defaultValue={query}
                              allowEmptyQuery
                              onChange={(newQuery: string) => setQuery(newQuery)}
                              onSubmit={(newValue: any) => {
                                 handleSubmit(newValue, false);
                                 setQuery("")
                              }}/>
                        : label && <Text fontWeight="bold">{label}</Text>
                  }
                  {isEditable || !isEllipsis ?
                        <ViewFull/>
                        :
                        <ViewWithEllipsis/>
                  }
               </Stack>
               :
               <></>
   )
}

