import * as React from "react";
import {useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {
   Box,
   IconButton,
   Modal,
   ModalBody,
   ModalContent,
   ModalFooter,
   ModalHeader,
   ModalOverlay,
   Text,
   useDisclosure,
   VStack
} from "@chakra-ui/react";
import {TbArrowBigDownLines} from "react-icons/tb";
import {IoArrowBackCircleOutline} from "react-icons/io5";

export const ModalFull: React.FC<any> = ({
                                            children,
                                            footer,
                                            header,
                                            onClose
                                         }: { header?: JSX.Element | string, footer?: JSX.Element | string, children: JSX.Element | string, onClose: Function }) => {

   const {t} = useTranslation();
   const ref = useRef<HTMLDivElement>(null);

   const {
      isOpen,
      onClose: onDisclosureClose
   } = useDisclosure({defaultIsOpen: true});

   const handleScrollToBottom = (e: React.UIEvent<HTMLElement>) => {
      if ((e.currentTarget.clientHeight + e.currentTarget.scrollTop) >= e.currentTarget.scrollHeight) {
         e.currentTarget.scrollTo({top: 0});
         setTimeout(() => handleClose(), 100);
      }
   };

   const handleClose = () => {
      onClose ? onClose() : onDisclosureClose();
   };

   useEffect(() => {
      if (ref.current) {
         ref.current.scrollTo({top: 0})
      }
   }, [children])

   return <Modal
         motionPreset="slideInBottom"
         size="full"
         onClose={handleClose}
         isOpen={isOpen}
         scrollBehavior={"inside"}
         closeOnOverlayClick={false}
         closeOnEsc={true}
   >
      <ModalOverlay
            bg='none'
            backdropBlur="2px"
            backdropFilter='auto'/>
      <ModalContent bg="transparent" height="100vh" maxHeight="100vh" minHeight="100vh">
         <ModalHeader bg="primary.500" p={0}>
            {header}
         </ModalHeader>
         <IconButton
               aria-label={t("Close and back")}
               title={t("Close and back")}
               icon={<IoArrowBackCircleOutline size={"100%"}/>}
               onClick={handleClose}
               variant={"modal-close"}
               size={"sm"}
         />
         <ModalBody ref={ref} p={0} scrollBehavior={"smooth"} onScroll={handleScrollToBottom}>
            <Box bg="pure-white" minHeight={"70vh"}>
               {children}
            </Box>
            <VStack width="100%" overflow={"auto"}
                    className={"hide-scrollbar"}
                    bgGradient="linear(to-b, blackAlpha.600, blackAlpha.100)"
                    color="pure-white">
               <Box cursor={"pointer"} onClick={handleClose} minHeight="50vh"/>
               <Text fontSize="lg">{t('Click or scroll to dismiss')}</Text>
               <IconButton
                     color="pure-white"
                     variant="toolbar"
                     border={0}
                     onClick={handleClose}
                     size={"lg"}
                     position="static"
                     aria-label={t("Click to dismiss")}
                     icon={<TbArrowBigDownLines/>}/>
               <Box cursor={"pointer"} onClick={handleClose} minHeight="50vh"/>
            </VStack>
         </ModalBody>
         <ModalFooter p={0}>
            {footer}
         </ModalFooter>
      </ModalContent>
   </Modal>
}
