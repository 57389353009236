import { ComponentStyleConfig } from "@chakra-ui/react";

const Button: ComponentStyleConfig = {
  baseStyle: {
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'primary.800'
  },
  variants: {
    solid: {
      color: 'black'
    },
    ghost: {
      border: 0
    },
    icon: {
      color: "black",
      bgColor: "pure-white",
      rounded: "full",
    },
    toolbar: {
      rounded: 0,
      color: "white",
      _active: {
        bg: "white",
        color: "black",
        _hover: {
          color: "blackAlpha.500",
        }
      },
      border: 0,
      _hover: {
        color: "whiteAlpha.500"
      }
    },
    link: {
      border: 0,
      position: "static"
    },
    "modal-close": {
      position: "absolute",
      right: 2,
      top: 2,
      color: "gray.500",
      zIndex: "popover",
      border: 0,
      boxShadow: "none"
    }
  },
  defaultProps: {
    size: ["xs", "sm", "md"],
    rounded: "md"
  },
}

export default Button;