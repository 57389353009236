import * as React from "react";
import {
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Link,
  Text,
  useDisclosure,
  VStack
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Logo } from "./icon/Logo";
import { TiThMenu } from "react-icons/ti";
import { ExternalLinks } from "./ExternalLinks";
import { NavigationLinks } from "./NavigationLinks";
import { NavLink } from "react-router-dom";


export const Navigation: React.FC<any> = (props: {}) => {

  const {t} = useTranslation();
  const disclosure = useDisclosure()
  const btnRef = React.useRef(null)

  return (<>
      <IconButton ref={btnRef} onClick={disclosure.onOpen} variant="ghost" size={"lg"} colorScheme="blackAlpha"
                  icon={<TiThMenu size={"1.75em"}/>}
                  aria-label={t("Menu")}/>
      <Drawer
        isOpen={disclosure.isOpen}
        placement='left'
        onClose={disclosure.onClose}
        size={["full", "sm", "sm", "sm", "sm"]}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay/>
        <DrawerContent bg={"blackAlpha.900"} color={"white"}>
          <DrawerCloseButton/>
          <DrawerHeader>
            <Link as={NavLink} to={"/"}>
              <HStack as={"span"} alignItems={"center"} gap={2} mb={4}>
                <Logo boxSize={8} boxShadow={"0 0 5px 2px inset black"}/>
                <Text fontWeight={"thin"} letterSpacing={4}>{t("HOME")}</Text>
              </HStack>
            </Link>
            <Divider/>
          </DrawerHeader>

          <DrawerBody>
            <NavigationLinks disclosure={disclosure} direction="column"/>
          </DrawerBody>
          <DrawerFooter>
            <VStack width={"100%"}>
              <Divider/>
              <ExternalLinks/>
            </VStack>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}
