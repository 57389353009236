import * as React from "react";
import { Heading, Text, VStack } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const NotFound: React.FC<any> = (props) => {

  const {t} = useTranslation();

  return (
    <VStack rowGap={4} minHeight="80vh" width="100%" alignItems="center" textAlign="center" justifyContent="center">
      <Heading as="h1">404</Heading>
      <Text fontSize="3xl">{t("Not Found")}</Text>
    </VStack>
  )
}