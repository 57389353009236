import * as React from "react";
import {useCallback, useEffect, useMemo, useState} from "react";
import useGraphQueryContext from "../../hooks/useGraphQueryContext";
import Board from "../board/feature/Board";
import {Button, Center, IconButton, useToken} from "@chakra-ui/react";
import {AssetCoin} from "../../features/asset/AssetCoin";
import {AssetCard} from "../../features/asset/AssetCard";
import {Pin} from "../board/feature/Pin";
import {useTranslation} from "react-i18next";
import {BiArrowFromLeft, BiArrowFromRight, BiArrowToLeft, BiArrowToRight} from "react-icons/bi";
import {Asset} from "../../../api";
import useConfirmationContext from "../../hooks/useConfirmationContext";
import {GrClose} from "react-icons/gr";
import {useParams} from "react-router-dom";
import useAsset from "../../hooks/useAsset";
import {AssetType} from "../../../types";

export const Graph: React.FC<any> = () => {

   const {t} = useTranslation();
   const [wiresColor, wiringModeWiresColor, bgColor, wiringModeBgColor, selectionColor] = useToken("colors", ["primary.700", "primary.600", "pure-white", "primary.200", "primary.500"])

   const [wiringSource, setWiringSource] = useState<Asset>();
   const [wiringTargets, setWiringTargets] = useState<{ [key: string]: Pin; }>();
   const confirm = useConfirmationContext();

   const {threadUuid} = useParams();
   const {asset: thread} = useAsset({
      assetUuid: threadUuid!,
      assetType: AssetType.Thread
   });

   const {
      pins,
      wires,
      isCoin,
      isDragEnabled,
      selection,
      setSelection,
      moveAssetPin,
      isWiringEnabled,
      disableWiring,
      editRelationshipWire,
      addAssetWire,
      addAssetPin,
      dragTarget,
      setAddCoordinates,
      isPresentation,
      addCoordinates
   } = useGraphQueryContext();

   const onChangeHandler = useCallback((e: any) => {
      if (e.target) {
         const targetPin = e.pins.find((p: Pin) => p.id === e.target)
         if (targetPin) {
            moveAssetPin(targetPin.id, targetPin.x, targetPin.y);
         }
      }
   }, [moveAssetPin]);

   useEffect(() => {
      if (isWiringEnabled && wiringSource) {
         setWiringTargets(currPins => {

            if (currPins) {
               const targets = {...currPins};

               for (const uuid in wires) {
                  if (wires[uuid].source.id === wiringSource.uuid) {
                     delete targets[wires[uuid].target.id];
                  } else if (wires[uuid].target.id === wiringSource.uuid) {
                     delete targets[wires[uuid].source.id];
                  }
               }

               delete targets[wiringSource.uuid!];
               return targets;
            }
            return currPins;
         })
      }
   }, [isWiringEnabled, wiringSource, wires]);

   useEffect(() => {
      if (isWiringEnabled) {
         setWiringTargets(pins);
      } else {
         setWiringSource(undefined);
         setWiringTargets(undefined);
      }

   }, [isWiringEnabled, pins]);

   const title = useMemo(() => t("It's all connected from here!"), [t]);
   const message = useMemo(() => t("It looks like that there is nothing left to connect to from here.\nWould you like to pick another connection source?"), [t]);

   const addTitle = useMemo(() => t("Let's increment your thread!"), [t]);
   const addTitleProblem = useMemo(() => t("Oops!!"), [t]);
   const addRelationMessage = useMemo(() => t("How would you like to proceed?"), [t]);
   const addRelationMessageProblem = useMemo(() => t("This is all already part of the thread, there is nothing to add here, try adding something else!"), [t]);

   useEffect(() => {
      if (wiringSource && wiringTargets && Object.keys(wiringTargets).length === 0) {
         confirm({
            title: title,
            message: message,
            onConfirm: () => {
               setWiringSource(undefined);
               setWiringTargets(pins);
            },
            onCancel: () => disableWiring()
         })
      }

   }, [wiringSource, wiringTargets, pins, confirm, title, message, disableWiring])

   return (useMemo(() => {
      const boardPins = Object.values(pins);
      return <Board
            center={thread?.data?.startsAt}
            bgColor={wiringTargets ? wiringModeBgColor : bgColor}
            draggable={isDragEnabled}
            color={wiringTargets ? wiringModeWiresColor : wiresColor}
            pins={boardPins}
            wires={Object.values(wires)}
            onChange={onChangeHandler}
            onDropCoordinatesChange={(e) => setAddCoordinates(e)}
            onDropHandler={(e) => {
               if (dragTarget) {

                  const options: any = [];

                  if (dragTarget.relationship && dragTarget.asset) {
                     if (!(dragTarget.relationship.uuid! in wires)) {
                        options.push({
                           action: () => addAssetWire(dragTarget.relationship.startUuid, dragTarget.relationship.endUuid, e.x, e.y),
                           label: t("Add Relationship")
                        })
                     }
                     if (!(dragTarget.asset.uuid! in pins)) {
                        options.push({
                           action: () => addAssetPin(dragTarget.asset.uuid, e.x, e.y),
                           label: options.length ? t("Add Card Only") : t("Add Card")
                        })
                     }
                  }

                  confirm({
                     title: options.length ? addTitle : addTitleProblem,
                     message: options.length ? addRelationMessage : addRelationMessageProblem,
                     confirmLabel: options[0] ? options[0].label : t("Okay"),
                     onConfirm: options[0] ? options[0].action : () => void 0,
                     cancelLabel: options[1] ? options[1].label : false,
                     onCancel: options[1] ? options[1].action : () => void 0,
                     showCloseButton: true
                  })
               }
            }}
            onWireClick={(w) => editRelationshipWire(w)}>
         {boardPins.map((a) => a.data ?
               <Center
                     key={a.data.data.uuid!}
                     height="100%"
                     width="100%"
                     onClick={() => setSelection(a.data.data)}
               >
                  {isCoin ?
                        <AssetCoin asset={a.data.data} size={"xl"}/> :
                        <AssetCard asset={a.data.data} presentation={isPresentation}/>
                  }
                  {wiringTargets && <>
                     {wiringTargets?.[a.data.data.uuid!] ?
                           <Center bg={"blackAlpha.400"} position={"absolute"} height="95%" width="95%">
                              {wiringSource ?
                                    <Button leftIcon={<BiArrowToRight/>}
                                            rightIcon={<BiArrowToLeft/>}
                                            onClick={(e) => {
                                               e.preventDefault();
                                               e.stopPropagation();
                                               addAssetWire(wiringSource.uuid, a.data.data.uuid, addCoordinates?.x, addCoordinates?.y)
                                            }}>
                                       {t("... to here!")}
                                    </Button> :
                                    <Button leftIcon={<BiArrowFromRight/>}
                                            rightIcon={<BiArrowFromLeft/>}
                                            onClick={(e) => {
                                               e.preventDefault();
                                               e.stopPropagation();
                                               setWiringSource(a.data.data);
                                            }}>
                                       {t("From here...")}
                                    </Button>
                              }
                           </Center> :
                           <Center
                                 bg={wiringSource?.uuid !== a.data.data.uuid ? "whiteAlpha.700" : `${selectionColor}55`}
                                 transition={"opacity .75s ease"}
                                 cursor={wiringSource?.uuid !== a.data.data.uuid ? "not-allowed" : undefined}
                                 position={"absolute"}
                                 h="100%"
                                 w="100%">
                              {wiringSource?.uuid === a.data.data.uuid &&
                                    <IconButton
                                          aria-label={t("Unselect this as the start.")}
                                          icon={<GrClose/>}
                                          size={"lg"}
                                          colorScheme="red"
                                          onClick={(e) => {
                                             e.preventDefault();
                                             e.stopPropagation();
                                             setWiringSource(undefined);
                                             setWiringTargets(pins);
                                          }}/>}
                           </Center>
                     }
                  </>
                  }
               </Center> :
               <React.Fragment key={a.data.data.uuid!}/>)
         }
      </Board>;
   }, [selection, isCoin, isPresentation, pins, wires, isDragEnabled, setSelection, wiringTargets, wiresColor, dragTarget, wiringSource, editRelationshipWire, setAddCoordinates, onChangeHandler, wiresColor, wiringModeWiresColor, isWiringEnabled, bgColor, wiringModeBgColor, dragTarget, addAssetPin, addAssetWire, addTitle, addTitleProblem, addRelationMessage, addRelationMessageProblem, addCoordinates]));

}
