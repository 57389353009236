import * as React from "react";
import {useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {
   Box,
   Center,
   chakra,
   Collapse,
   Flex,
   Grid,
   GridItem,
   Heading,
   IconButton,
   Spacer,
   Stack,
   Text,
   useBoolean,
   VStack,
} from "@chakra-ui/react";
import {useSize} from "@chakra-ui/react-use-size"
import {AnyAsset, AssetType} from "../../../types";
import {IoMdArrowDropdown, IoMdArrowDropup} from "react-icons/io";
import {Images} from "../../components/editables/Images";
import {InputText} from "../../components/editables/InputText";
import {MultiSelect} from "../../components/editables/MultiSelect";
import {ContentTypes} from "../../components/editables/ContentTypes";
import useAsset from "../../hooks/useAsset";
import {
   Annotation,
   Classification,
   Stages,
   useAnnotationApiResourceFindQuery,
   useClassificationApiResourceFindQuery,
   useCollectionApiResourceFetchAssetCollectionsQuery,
   useRelationshipApiResourceFetchAssetRelationshipsQuery,
   useStagesApiResourceCreateMutation,
   useStagesApiResourceDeleteMutation,
   useThreadApiResourceAssetThreadsQuery,
} from "../../../api";
import Utils from "../../../util/utils";
import {AssetRelations} from "./AssetRelations";
import useClassifications from "../../hooks/useClassifications";
import usePermissions from "../../hooks/usePermissions";
import useAnnotations from "../../hooks/useAnnotations";
import {NotFound} from "../../pages/NotFound";
import {SingleSelect} from "../../components/editables/SingleSelect";
import {Suspense} from "../../components/Suspense";
import config from "../../../theme/config";
import {RichTextArea} from "../../components/editables/RichTextArea";
import useWebLinkClipboard from "../../hooks/useWebLinkClipboard";
import useToasts from "../../hooks/useToasts";
import {BiUnlink} from "react-icons/bi";
import useConfirmationContext from "../../hooks/useConfirmationContext";

export const AssetRenderer: React.FC<any> = ({
                                                asset,
                                                color,
                                                stagesUuid
                                             }: { asset?: AnyAsset, stagesUuid?: string, color: string }) => {

   const {t} = useTranslation();
   const toasts = useToasts();
   const confirm = useConfirmationContext();
   const isRoot = useMemo(() => !asset, [asset]);

   const {
      uuid,
      assetTypePath
   } = useParams();
   const [descriptionExpanded, setDescriptionExpanded] = useState(isRoot);
   const ref = useRef(null);
   const box = useSize(ref);
   const pageSize = box?.width ? Math.ceil(box.width / config.cardWidth) : 0;

   const assetUuid = useMemo(() => asset?.uuid || uuid || "", [uuid, asset]);
   const assetType: AssetType = useMemo(() => (asset?.assetType || Utils.pathToAssetType(assetTypePath)) as AssetType, [assetTypePath, asset]);

   const [isClipboardActive, {
      on,
      off
   }] = useBoolean(false);

   const {
      webLink: clipboardWebLink,
      reset
   } = useWebLinkClipboard(isClipboardActive, "asset", t("Paste any link from the web. Pictures will be added as cover images, while everything else becomes its contents! Try it!"));

   const {
      asset: {
         data,
         isError,
         isSuccess
      },
      merger,
      AssetBody
   } = useAsset({
      asset: asset,
      assetUuid,
      assetType
   });

   const {isAuthor} = usePermissions(data)

   useEffect(() => {
      if (isAuthor) {
         on();
      } else {
         off();
      }
      return () => {
         off()
      }
   }, [on, off, isAuthor])

   const [classifier] = useClassifications({
      slugs: data?.classifier,
      assetType: data?.assetType
   });
   const categories = useClassifications({
      slugs: data?.categories,
      assetType: data?.assetType
   });
   const contentTypes = useClassifications({
      slugs: data?.contentTypes,
      assetType: data?.assetType
   });
   const tags = useAnnotations({slugs: data?.tags});
   const keywords = useAnnotations({
      slugs: data?.keywords,
      predefined: true
   });

   const skipLoadingRelatedContents = !assetUuid || !isRoot || !pageSize;

   const [relationshipsPage, setRelationshipsPage] = useState(1);
   const relationships = useRelationshipApiResourceFetchAssetRelationshipsQuery({
      asset: assetUuid,
      pageNumber: relationshipsPage,
      pageSize
   }, {
      skip: skipLoadingRelatedContents
   });

   const [collectionsPage, setCollectionsPage] = useState(1);
   const collections = useCollectionApiResourceFetchAssetCollectionsQuery({
      asset: assetUuid,
      pageNumber: collectionsPage,
      pageSize
   }, {
      skip: skipLoadingRelatedContents
   });

   const [threadsPage, setThreadsPage] = useState(1);
   const threads = useThreadApiResourceAssetThreadsQuery({
      asset: assetUuid,
      pageNumber: threadsPage,
      pageSize
   }, {
      skip: skipLoadingRelatedContents
   });

   const [deleteStages] = useStagesApiResourceDeleteMutation();
   const [createStages, createStagesResult] = useStagesApiResourceCreateMutation();

   useEffect(() => {
      if (clipboardWebLink?.uuid) {
         if (!data?.stages?.find((s: Stages) => s.endUuid === clipboardWebLink.uuid)) {
            createStages({
               stagesMutationRequest: {
                  startUuid: assetUuid,
                  endUuid: clipboardWebLink.uuid,
                  layout: clipboardWebLink.classifier?.includes("image") ? "COVER_IMAGE" : "BODY"
               }
            })
         }
         reset(true);
      }
   }, [clipboardWebLink, reset, createStages, data, assetUuid])

   useEffect(() => {
      if (createStagesResult.isError) {
         toasts.stageCreationError(createStagesResult.error);
         createStagesResult.reset();
      }
   }, [createStagesResult, toasts])

   useEffect(() => {
      setDescriptionExpanded(isRoot);
      setRelationshipsPage(1);
      setCollectionsPage(1);
      setThreadsPage(1);
   }, [isRoot])

   const toggleDescription = useMemo(() => data ? t("Toggle details about {{title}}", {title: data.title}) : "", [data]);
   const removeStage = t("Unlink");

   const url = useMemo(() => Utils.getAssetUrl(data), [data]);

   const coverImages = useMemo(() => Utils.getCoverImages(data), [data]);

   return useMemo(() => <Box ref={ref} width="100%" bg={color || "primary.500"}>
            {isError && <NotFound/>}
            <Suspense until={isSuccess}>
               {assetUuid && data &&
                     <chakra.article width="100%">
                        <Stack width="100%">
                           <VStack
                                 mt={isRoot ? [6, 10, 16, 20, 24] : [2, 4, 6, 8, 10]}
                                 mb={isRoot ? [3, 6, 9, 12, 20] : [1]}
                                 py={isRoot ? [2, 4, 6, 8, 12] : 2}
                                 px={[2, 4, 8, 12, 24]}
                                 bg="pure-black"
                                 alignItems={isRoot ? "flex-start" : "center"}>
                              <Flex columnGap={1} alignItems={"center"}
                                    justifyContent={isRoot ? "flex-start" : "center"}>
                                 {!isRoot && (isAuthor || data.description) &&
                                       <IconButton size={"sm"}
                                                   aria-label={toggleDescription}
                                                   title={toggleDescription}
                                                   variant="ghost"
                                                   rounded="full"
                                                   onClick={() => setDescriptionExpanded(expanded => !expanded)}
                                                   icon={descriptionExpanded ? <IoMdArrowDropup/> :
                                                         <IoMdArrowDropdown/>}/>
                                 }
                                 <Heading color="pure-white"
                                          size={isRoot ? ["sm", "md", "lg", "xl", "2xl"] : ["xs", "sm", "md", "lg", "xl"]}
                                          fontWeight={isRoot ? "700" : "bold"}
                                          as={isRoot ? "h1" : "h2"}>
                                    <InputText value={data.title}
                                               onChange={isAuthor && ((newVal: string) => merger("title", newVal))}/>
                                 </Heading>
                                 {!isRoot && isAuthor && stagesUuid &&
                                       <IconButton size={"sm"}
                                                   aria-label={removeStage}
                                                   title={removeStage}
                                                   variant="links"
                                                   color="pure-white"
                                                   onClick={() => confirm({
                                                      title: t("Are you sure?"),
                                                      message: t("Would you like to unlink these contents?"),
                                                      onConfirm: () => deleteStages({batchRequest: {uuids: [stagesUuid!]}})
                                                   })}
                                                   icon={<BiUnlink/>}/>
                                 }
                              </Flex>
                              {!url && (classifier || isAuthor) &&
                                    <chakra.div color="pure-white"
                                                fontSize={["xs", "sm", "md", "xl", "2xl", "3xl"]}
                                                fontWeight="thin">
                                       <SingleSelect
                                             placeholder={t(data.assetType as string || "Classifier")}
                                             isDisabled={!data.assetType}
                                             isSlim
                                             allowEmptyQuery
                                             selection={classifier}
                                             useQuery={useClassificationApiResourceFindQuery}
                                             queryBuilder={(query: string) => ({
                                                query,
                                                classifiersOnly: true,
                                                forDomains: [data.assetType?.toUpperCase()],
                                                limit: 10
                                             })}
                                             labelSelector={(cat: Classification) => cat.Name}
                                             valueSelector={(cat: Classification) => cat.Slug}
                                             onChange={isAuthor && ((newValue: Classification) => merger("classifier", newValue ? newValue.Slug : null))}/>
                                    </chakra.div>}
                              {url && <Text noOfLines={1} color="gray.400" fontWeight={"thin"} size={"xs"}>{url}</Text>}
                           </VStack>
                           <Collapse in={descriptionExpanded}>
                              <VStack width="100%" bgColor="pure-white" boxShadow={"inner"} py={8}>
                                 <Grid
                                       templateAreas={{
                                          base: `"cover"
                  "description"
                  "taxonomy"`,
                                          lg: `"cover cover"
                  "description description"
                  "taxonomy taxonomy"`
                                       }}
                                       gridTemplateRows={'auto auto auto'}
                                       gridTemplateColumns={{
                                          base: 'minmax(auto, 465px)',
                                          lg: 'minmax(auto, 465px) minmax(auto, 465px)'
                                       }}
                                       gap={6}
                                       p={[2, 2, 4]}
                                 >
                                    {(coverImages?.length > 0 || isAuthor) &&
                                          <GridItem pl={1} area="cover">
                                             <Center boxSize={"100%"}>
                                                <Images sources={coverImages}
                                                        boxSize={["2xs", "2xs", "md", "md", "md", "lg"]}
                                                        onChange={isAuthor ? ((newVal: string | any[]) => {
                                                           if (newVal instanceof Array) {
                                                              newVal.forEach((wl: any) => {
                                                                 createStages({
                                                                    stagesMutationRequest: {
                                                                       startUuid: assetUuid,
                                                                       endUuid: wl.uuid,
                                                                       layout: "COVER_IMAGE"
                                                                    }
                                                                 })

                                                              })
                                                           } else {
                                                              const target = data.stages?.find((s: Stages) => Utils.getAssetUrl(s.webLink) === newVal);

                                                              if (target) {
                                                                 confirm({
                                                                    title: t("Are you sure?"),
                                                                    message: t("Would you like to remove this cover image?"),
                                                                    onConfirm: () => deleteStages({batchRequest: {uuids: [target.uuid]}})
                                                                 })

                                                              }

                                                           }
                                                        }) : undefined}/>
                                             </Center>
                                          </GridItem>
                                    }
                                    <GridItem pl={1} area="taxonomy">
                                       {Utils.isKnot(data.assetType as AssetType) &&
                                             <MultiSelect label={t("Tags")}
                                                          colorScheme={"darken"}
                                                          allowEmptyQuery
                                                          values={tags}
                                                          valueSelector={(cat: Annotation) => cat.Slug}
                                                          labelSelector={(cat: Annotation) => cat.Value}
                                                          useQuery={useAnnotationApiResourceFindQuery}
                                                          queryBuilder={(query: string) => ({
                                                             query,
                                                             predefined: false
                                                          })}
                                                          onChange={isAuthor && ((newVal: string[]) => merger("tags", newVal))}/>
                                       }
                                       {data.assetType === AssetType.WebLink &&
                                             <ContentTypes values={contentTypes}
                                                           onChange={isAuthor && ((newVal: string[]) => merger("contentTypes", newVal))}/>

                                       }
                                    </GridItem>
                                    <GridItem pl={1} area="description" height={"max-content"}>
                                       {Utils.isKnot(data.assetType as AssetType) &&
                                             <MultiSelect label={!isAuthor ? undefined : t("Categories")}
                                                          values={categories}
                                                          colorScheme={"blackAlpha"}
                                                          allowEmptyQuery
                                                          useQuery={useClassificationApiResourceFindQuery}
                                                          queryBuilder={(query: string) => ({
                                                             query,
                                                             classifiersOnly: true,
                                                             forDomains: [data.assetType?.toUpperCase()],
                                                             limit: 10
                                                          })}
                                                          valueSelector={(cat: Classification) => cat.Slug}
                                                          labelSelector={(cat: Classification) => cat.Name}
                                                          onChange={isAuthor && ((newVal: string[]) => merger("categories", newVal))}/>
                                       }
                                       {data.assetType === AssetType.WebLink &&
                                             <MultiSelect label={!isAuthor ? undefined : t("Keywords")}
                                                          values={keywords}
                                                          useQuery={useAnnotationApiResourceFindQuery}
                                                          valueSelector={(cat: Annotation) => cat.Slug}
                                                          labelSelector={(cat: Annotation) => cat.Value}
                                                          queryBuilder={(query: string) => ({
                                                             query,
                                                             predefined: true
                                                          })}
                                                          onChange={isAuthor && ((newVal: string[]) => merger("keywords", newVal))}/>
                                       }
                                       <Spacer my={6}/>
                                       <RichTextArea value={data.description}
                                                     onChange={isAuthor && ((newVal: string) => merger("description", newVal))}/>

                                    </GridItem>
                                 </Grid>
                              </VStack>
                           </Collapse>
                        </Stack>
                        {AssetBody &&
                              <chakra.section py={4}>
                                 <AssetBody asset={data} mutator={merger}/>
                              </chakra.section>
                        }
                        {isRoot && <>
                           {((relationships.data?.contents?.length ?? 0) > 0 || relationshipsPage > 1) &&
                                 <chakra.section py={4}>
                                    <AssetRelations
                                          parentId={assetUuid}
                                          title={t("Related Contents")}
                                          onChange={(offset: number) => {
                                             if (offset > 0) {
                                                setRelationshipsPage((p) => p + offset)
                                             }
                                          }}
                                          assets={relationships.data?.contents?.map(r => [r.start, r.end]).flat().filter(a => a?.uuid !== data.uuid)}
                                          relationships={relationships.data?.contents}/>
                                 </chakra.section>
                           }
                           {((collections.data?.contents?.length ?? 0) > 0 || collectionsPage > 1) &&
                                 <chakra.section py={4}>
                                    <AssetRelations
                                          parentId={assetUuid}
                                          title={t("Collections")}
                                          onChange={(offset: number) => {
                                             if (offset > 0) {
                                                setCollectionsPage((p) => p + offset)
                                             }
                                          }}
                                          assets={collections.data?.contents?.filter(a => a.uuid !== data.uuid)}/>
                                 </chakra.section>
                           }
                           {((threads.data?.contents?.length ?? 0) > 0 || threadsPage > 1) &&
                                 <chakra.section py={4}>
                                    <AssetRelations
                                          parentId={assetUuid}
                                          title={t("Threads")}
                                          onChange={(offset: number) => {
                                             if (offset > 0) {
                                                setThreadsPage((p) => p + offset)
                                             }
                                          }}
                                          assets={threads.data?.contents?.filter(a => a.uuid !== data.uuid)}/>
                                 </chakra.section>
                           }
                        </>
                        }
                     </chakra.article>
               }
            </Suspense>
         </Box>, [t, isAuthor, uuid, isRoot, assetUuid, isSuccess, threads, threadsPage, collections, collectionsPage, relationships, relationshipsPage, data, AssetBody, merger, keywords, categories, classifier, tags, contentTypes, coverImages, descriptionExpanded]
   )
}
