import {useTranslation} from "react-i18next";
import {AccordionButton, Button, HStack, Spacer, Text} from "@chakra-ui/react";
import * as React from "react";
import useSearchContext from "../../../hooks/useSearchContext";

export const FilterHeader: React.FC<any> = (props: { name: string, params?: string[] }) => {

   const {t} = useTranslation();
   const {
      removeFilterBy,
      isFilterApplied
   } = useSearchContext();

   const filters = props.params ?? [props.name];

   return <AccordionButton>
      <HStack flex={1} textAlign="right">
         <Text fontSize="sm" as="b" casing="uppercase">{t(props.name)}</Text>
         <Spacer mx="auto"/>
         {filters.some(f => isFilterApplied(f)) &&
               <Button
                     as={"label"}
                     variant={"link"}
                     size={"sm"}
                     borderRadius='md'
                     colorScheme='secondary'
                     color="black"
                     aria-label={t("Clear filter for {{filterName}}", {filterName: props.name})}
                     onClick={(e) => {
                        e.preventDefault();
                        removeFilterBy(filters.some(f => f === "years") ? ["fromYear", "untilYear"] : filters)
                     }}
               >
                  {t("Clear")}
               </Button>
         }
      </HStack>
   </AccordionButton>;
}