import { useAnnotationApiResourceFindQuery } from "../../api";
import { UseAnnotationsParams } from "../../types";
import { useMemo } from "react";

/**
 * Helps on asset access control.
 * TODO Implement
 */
const useAnnotations = ({slugs, predefined}: UseAnnotationsParams) => {

  const slugArray = useMemo(() => slugs ? Array(slugs).flat() : [], [slugs]);

  const {data, isSuccess} = useAnnotationApiResourceFindQuery({predefined}, {skip: !slugArray.length});

  return useMemo(() => isSuccess ? data?.filter(d => slugArray.includes(d.Slug as string)) : [], [data, isSuccess, slugs]);
}

export default useAnnotations;