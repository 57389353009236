import { ApiSlice as api } from "./apiSlice";
export const addTagTypes = [
  "Asset Annotations",
  "Collection's & Thread's Contents",
  "Thread Graph",
  "Thread",
  "Collection",
  "Search",
  "Asset Staging",
  "Asset Translation",
  "Custom Attribute",
  "Custom Attribute Value",
  "Authorization Grants",
  "Classification",
  "Media Repository",
  "User Profile",
  "Suggestions",
  "Thread Relations",
  "Relationship",
  "Versioning",
  "Web Contents",
  "Web Link",
  "Web Site",
  "What",
  "When",
  "Where",
  "Who",
  "Asset Workflow",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      annotationApiResourceCreate: build.mutation<
        AnnotationApiResourceCreateApiResponse,
        AnnotationApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/annotation`,
          method: "POST",
          body: queryArg.annotation,
        }),
        invalidatesTags: ["Asset Annotations"],
      }),
      annotationApiResourceFind: build.query<
        AnnotationApiResourceFindApiResponse,
        AnnotationApiResourceFindApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/annotation/find`,
          params: {
            limit: queryArg.limit,
            locale: queryArg.locale,
            predefined: queryArg.predefined,
            query: queryArg.query,
            start: queryArg.start,
          },
        }),
        providesTags: ["Asset Annotations"],
      }),
      collectsApiResourceCreate: build.mutation<
        CollectsApiResourceCreateApiResponse,
        CollectsApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/assemblage/contents`,
          method: "POST",
          body: queryArg.collectsMutationRequest,
          params: {
            descending: queryArg.descending,
            pageNumber: queryArg.pageNumber,
            pageSize: queryArg.pageSize,
            sortBy: queryArg.sortBy,
            uuids: queryArg.uuids,
          },
        }),
        invalidatesTags: [
          "Collection's & Thread's Contents",
          "Thread Graph",
          "Thread",
          "Collection",
        ],
      }),
      collectsApiResourceDelete: build.mutation<
        CollectsApiResourceDeleteApiResponse,
        CollectsApiResourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/assemblage/contents`,
          method: "DELETE",
          body: queryArg.batchRequest,
          params: {
            descending: queryArg.descending,
            pageNumber: queryArg.pageNumber,
            pageSize: queryArg.pageSize,
            sortBy: queryArg.sortBy,
            uuids: queryArg.uuids,
          },
        }),
        invalidatesTags: [
          "Collection's & Thread's Contents",
          "Thread Graph",
          "Thread",
          "Collection",
        ],
      }),
      collectsApiResourceFind: build.query<
        CollectsApiResourceFindApiResponse,
        CollectsApiResourceFindApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/assemblage/contents/find`,
          params: {
            descending: queryArg.descending,
            pageNumber: queryArg.pageNumber,
            pageSize: queryArg.pageSize,
            sortBy: queryArg.sortBy,
            uuids: queryArg.uuids,
            asset: queryArg.asset,
            parent: queryArg.parent,
          },
        }),
        providesTags: [
          "Collection's & Thread's Contents",
          "Thread Graph",
          "Thread",
          "Collection",
        ],
      }),
      collectsApiResourceFromTo: build.query<
        CollectsApiResourceFromToApiResponse,
        CollectsApiResourceFromToApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/assemblage/contents/from/${queryArg.assemblageUuid}/to/${queryArg.assetUuid}`,
          params: {
            descending: queryArg.descending,
            pageNumber: queryArg.pageNumber,
            pageSize: queryArg.pageSize,
            sortBy: queryArg.sortBy,
            uuids: queryArg.uuids,
          },
        }),
        providesTags: [
          "Collection's & Thread's Contents",
          "Thread Graph",
          "Thread",
          "Collection",
        ],
      }),
      collectsApiResourceCollects: build.query<
        CollectsApiResourceCollectsApiResponse,
        CollectsApiResourceCollectsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/assemblage/contents/${queryArg.collectionUuid}/collects/${queryArg.assetUuid}`,
          params: {
            descending: queryArg.descending,
            pageNumber: queryArg.pageNumber,
            pageSize: queryArg.pageSize,
            sortBy: queryArg.sortBy,
            uuids: queryArg.uuids,
          },
        }),
        providesTags: [
          "Collection's & Thread's Contents",
          "Thread Graph",
          "Thread",
          "Collection",
        ],
      }),
      collectsApiResourceUpdate: build.mutation<
        CollectsApiResourceUpdateApiResponse,
        CollectsApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/assemblage/contents/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.collectsMutationRequest,
          params: {
            descending: queryArg.descending,
            pageNumber: queryArg.pageNumber,
            pageSize: queryArg.pageSize,
            sortBy: queryArg.sortBy,
            uuids: queryArg.uuids,
          },
        }),
        invalidatesTags: [
          "Collection's & Thread's Contents",
          "Thread Graph",
          "Thread",
          "Collection",
        ],
      }),
      searchApiResourceSearchScopes: build.query<
        SearchApiResourceSearchScopesApiResponse,
        SearchApiResourceSearchScopesApiArg
      >({
        query: () => ({ url: `/v1/asset/search/scopes` }),
        providesTags: ["Search"],
      }),
      searchApiResourceStats: build.query<
        SearchApiResourceStatsApiResponse,
        SearchApiResourceStatsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/asset/search/stats/${queryArg.scope}`,
        }),
        providesTags: ["Search"],
      }),
      searchApiResourceSearch: build.query<
        SearchApiResourceSearchApiResponse,
        SearchApiResourceSearchApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/asset/search/${queryArg.scope}`,
          params: {
            assetType: queryArg.assetType,
            categories: queryArg.categories,
            contentTypes: queryArg.contentTypes,
            descending: queryArg.descending,
            fromYear: queryArg.fromYear,
            grantsWriteAccess: queryArg.grantsWriteAccess,
            hosts: queryArg.hosts,
            keywords: queryArg.keywords,
            language: queryArg.language,
            latitude: queryArg.latitude,
            longitude: queryArg.longitude,
            maxDistanceInKm: queryArg.maxDistanceInKm,
            occupations: queryArg.occupations,
            organizations: queryArg.organizations,
            pageNumber: queryArg.pageNumber,
            pageSize: queryArg.pageSize,
            query: queryArg.query,
            sortBy: queryArg.sortBy,
            status: queryArg.status,
            tags: queryArg.tags,
            untilYear: queryArg.untilYear,
            uuids: queryArg.uuids,
            visibilities: queryArg.visibilities,
          },
        }),
        providesTags: ["Search"],
      }),
      stagesApiResourceCreate: build.mutation<
        StagesApiResourceCreateApiResponse,
        StagesApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/asset/stage`,
          method: "POST",
          body: queryArg.stagesMutationRequest,
        }),
        invalidatesTags: ["Asset Staging", "Search", "Thread Graph"],
      }),
      stagesApiResourceDelete: build.mutation<
        StagesApiResourceDeleteApiResponse,
        StagesApiResourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/asset/stage`,
          method: "DELETE",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["Asset Staging", "Search", "Thread Graph"],
      }),
      stagesApiResourceFetchByStartUuid: build.query<
        StagesApiResourceFetchByStartUuidApiResponse,
        StagesApiResourceFetchByStartUuidApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/asset/stage/starting/${queryArg.startUuid}`,
        }),
        providesTags: ["Asset Staging", "Search", "Thread Graph"],
      }),
      stagesApiResourceUpdate: build.mutation<
        StagesApiResourceUpdateApiResponse,
        StagesApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/asset/stage/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.stagesMutationRequest,
        }),
        invalidatesTags: ["Asset Staging", "Search", "Thread Graph"],
      }),
      translatesApiResourceCreate: build.mutation<
        TranslatesApiResourceCreateApiResponse,
        TranslatesApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/asset/translation`,
          method: "POST",
          body: queryArg.translatesMutationRequest,
        }),
        invalidatesTags: ["Asset Translation", "Thread Graph"],
      }),
      translatesApiResourceDelete: build.mutation<
        TranslatesApiResourceDeleteApiResponse,
        TranslatesApiResourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/asset/translation`,
          method: "DELETE",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["Asset Translation", "Thread Graph"],
      }),
      translatesApiResourceUpdate: build.mutation<
        TranslatesApiResourceUpdateApiResponse,
        TranslatesApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/asset/translation/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.translatesMutationRequest,
        }),
        invalidatesTags: ["Asset Translation", "Thread Graph"],
      }),
      customAttributeApiResourceCreate: build.mutation<
        CustomAttributeApiResourceCreateApiResponse,
        CustomAttributeApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/attribute`,
          method: "POST",
          body: queryArg.customAttributeMutationRequest,
        }),
        invalidatesTags: ["Custom Attribute", "Search"],
      }),
      customAttributeApiResourceDelete: build.mutation<
        CustomAttributeApiResourceDeleteApiResponse,
        CustomAttributeApiResourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/attribute`,
          method: "DELETE",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["Custom Attribute", "Search"],
      }),
      customAttributeApiResourceFetchAll: build.query<
        CustomAttributeApiResourceFetchAllApiResponse,
        CustomAttributeApiResourceFetchAllApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/attribute/some`,
          params: { uuids: queryArg.uuids },
        }),
        providesTags: ["Custom Attribute", "Search"],
      }),
      setsCustomAttributeApiResourceCreate: build.mutation<
        SetsCustomAttributeApiResourceCreateApiResponse,
        SetsCustomAttributeApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/attribute/value`,
          method: "POST",
          body: queryArg.setsCustomAttributeMutationRequest,
        }),
        invalidatesTags: ["Custom Attribute Value", "Search"],
      }),
      setsCustomAttributeApiResourceDelete: build.mutation<
        SetsCustomAttributeApiResourceDeleteApiResponse,
        SetsCustomAttributeApiResourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/attribute/value`,
          method: "DELETE",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["Custom Attribute Value", "Search"],
      }),
      setsCustomAttributeApiResourceUpdate: build.mutation<
        SetsCustomAttributeApiResourceUpdateApiResponse,
        SetsCustomAttributeApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/attribute/value/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.setsCustomAttributeMutationRequest,
        }),
        invalidatesTags: ["Custom Attribute Value", "Search"],
      }),
      customAttributeApiResourceFetch: build.query<
        CustomAttributeApiResourceFetchApiResponse,
        CustomAttributeApiResourceFetchApiArg
      >({
        query: (queryArg) => ({ url: `/v1/attribute/${queryArg.uuid}` }),
        providesTags: ["Custom Attribute", "Search"],
      }),
      customAttributeApiResourceUpdate: build.mutation<
        CustomAttributeApiResourceUpdateApiResponse,
        CustomAttributeApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/attribute/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.customAttributeMutationRequest,
        }),
        invalidatesTags: ["Custom Attribute", "Search"],
      }),
      grantsApiResourceCreate: build.mutation<
        GrantsApiResourceCreateApiResponse,
        GrantsApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/authorization/grants`,
          method: "POST",
          body: queryArg.grantsMutationRequest,
        }),
        invalidatesTags: ["Authorization Grants"],
      }),
      grantsApiResourceDelete: build.mutation<
        GrantsApiResourceDeleteApiResponse,
        GrantsApiResourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/authorization/grants`,
          method: "DELETE",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["Authorization Grants"],
      }),
      grantsApiResourceFind: build.query<
        GrantsApiResourceFindApiResponse,
        GrantsApiResourceFindApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/authorization/grants/find`,
          params: {
            descending: queryArg.descending,
            pageNumber: queryArg.pageNumber,
            pageSize: queryArg.pageSize,
            sortBy: queryArg.sortBy,
            uuids: queryArg.uuids,
          },
        }),
        providesTags: ["Authorization Grants"],
      }),
      grantsApiResourceUpdate: build.mutation<
        GrantsApiResourceUpdateApiResponse,
        GrantsApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/authorization/grants/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.grantsMutationRequest,
        }),
        invalidatesTags: ["Authorization Grants"],
      }),
      classificationApiResourceCreate: build.mutation<
        ClassificationApiResourceCreateApiResponse,
        ClassificationApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/classification`,
          method: "POST",
          body: queryArg.classification,
        }),
        invalidatesTags: ["Classification"],
      }),
      classificationApiResourceFind: build.query<
        ClassificationApiResourceFindApiResponse,
        ClassificationApiResourceFindApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/classification/find`,
          params: {
            asTree: queryArg.asTree,
            classifiersOnly: queryArg.classifiersOnly,
            forDomains: queryArg.forDomains,
            limit: queryArg.limit,
            locale: queryArg.locale,
            query: queryArg.query,
            start: queryArg.start,
          },
        }),
        providesTags: ["Classification"],
      }),
      collectionApiResourceCreate: build.mutation<
        CollectionApiResourceCreateApiResponse,
        CollectionApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/collection`,
          method: "POST",
          body: queryArg.collectionMutationRequest,
        }),
        invalidatesTags: ["Collection", "Search"],
      }),
      collectionApiResourceDelete: build.mutation<
        CollectionApiResourceDeleteApiResponse,
        CollectionApiResourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/collection`,
          method: "DELETE",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["Collection", "Search"],
      }),
      collectionApiResourceFetchAssetCollections: build.query<
        CollectionApiResourceFetchAssetCollectionsApiResponse,
        CollectionApiResourceFetchAssetCollectionsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/collection/asset`,
          params: {
            asset: queryArg.asset,
            descending: queryArg.descending,
            pageNumber: queryArg.pageNumber,
            pageSize: queryArg.pageSize,
            parent: queryArg.parent,
            sortBy: queryArg.sortBy,
            uuids: queryArg.uuids,
          },
        }),
        providesTags: ["Collection", "Search"],
      }),
      collectionApiResourceFetchAll: build.query<
        CollectionApiResourceFetchAllApiResponse,
        CollectionApiResourceFetchAllApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/collection/some`,
          params: { uuids: queryArg.uuids },
        }),
        providesTags: ["Collection", "Search"],
      }),
      collectionApiResourceFetch: build.query<
        CollectionApiResourceFetchApiResponse,
        CollectionApiResourceFetchApiArg
      >({
        query: (queryArg) => ({ url: `/v1/collection/${queryArg.uuid}` }),
        providesTags: ["Collection", "Search"],
      }),
      collectionApiResourceUpdate: build.mutation<
        CollectionApiResourceUpdateApiResponse,
        CollectionApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/collection/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.collectionMutationRequest,
        }),
        invalidatesTags: ["Collection", "Search"],
      }),
      repositoryApiResourceUpload: build.mutation<
        RepositoryApiResourceUploadApiResponse,
        RepositoryApiResourceUploadApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/media/repository/upload`,
          method: "POST",
          body: queryArg.uploadFile,
        }),
        invalidatesTags: ["Media Repository"],
      }),
      userProfileApiResourceMe: build.query<
        UserProfileApiResourceMeApiResponse,
        UserProfileApiResourceMeApiArg
      >({
        query: () => ({ url: `/v1/profile/user` }),
        providesTags: ["User Profile", "Search", "Thread Graph"],
      }),
      userProfileApiResourceUpdateMe: build.mutation<
        UserProfileApiResourceUpdateMeApiResponse,
        UserProfileApiResourceUpdateMeApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/profile/user`,
          method: "PUT",
          body: queryArg.userProfileMutationRequest,
        }),
        invalidatesTags: ["User Profile", "Search", "Thread Graph"],
      }),
      userProfileApiResourceFetchAll: build.query<
        UserProfileApiResourceFetchAllApiResponse,
        UserProfileApiResourceFetchAllApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/profile/user/some`,
          params: { uuids: queryArg.uuids },
        }),
        providesTags: ["User Profile", "Search", "Thread Graph"],
      }),
      userProfileApiResourceFetchUserProfile: build.query<
        UserProfileApiResourceFetchUserProfileApiResponse,
        UserProfileApiResourceFetchUserProfileApiArg
      >({
        query: (queryArg) => ({ url: `/v1/profile/user/${queryArg.uuid}` }),
        providesTags: ["User Profile", "Search", "Thread Graph"],
      }),
      userProfileApiResourceUpdate: build.mutation<
        UserProfileApiResourceUpdateApiResponse,
        UserProfileApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/profile/user/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.userProfileMutationRequest,
        }),
        invalidatesTags: ["User Profile", "Search", "Thread Graph"],
      }),
      suggestionResourceKnots: build.query<
        SuggestionResourceKnotsApiResponse,
        SuggestionResourceKnotsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/suggest/asset`,
          body: queryArg.suggestAsset,
        }),
        providesTags: ["Suggestions"],
      }),
      suggestionResourceTerm: build.query<
        SuggestionResourceTermApiResponse,
        SuggestionResourceTermApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/suggest/term`,
          body: queryArg.suggestSearchTerm,
        }),
        providesTags: ["Suggestions"],
      }),
      threadApiResourceCreate: build.mutation<
        ThreadApiResourceCreateApiResponse,
        ThreadApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread`,
          method: "POST",
          body: queryArg.threadMutationRequest,
        }),
        invalidatesTags: ["Thread", "Search"],
      }),
      threadApiResourceDelete: build.mutation<
        ThreadApiResourceDeleteApiResponse,
        ThreadApiResourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread`,
          method: "DELETE",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["Thread", "Search"],
      }),
      threadApiResourceAssetThreads: build.query<
        ThreadApiResourceAssetThreadsApiResponse,
        ThreadApiResourceAssetThreadsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread/asset-threads`,
          params: {
            asset: queryArg.asset,
            descending: queryArg.descending,
            pageNumber: queryArg.pageNumber,
            pageSize: queryArg.pageSize,
            parent: queryArg.parent,
            sortBy: queryArg.sortBy,
            uuids: queryArg.uuids,
          },
        }),
        providesTags: ["Thread", "Search"],
      }),
      relationshipApiResourceCreate: build.mutation<
        RelationshipApiResourceCreateApiResponse,
        RelationshipApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread/graph`,
          method: "POST",
          body: queryArg.relationshipMutationRequest,
        }),
        invalidatesTags: ["Thread Graph"],
      }),
      relationshipApiResourceDelete: build.mutation<
        RelationshipApiResourceDeleteApiResponse,
        RelationshipApiResourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread/graph`,
          method: "DELETE",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["Thread Graph"],
      }),
      relationshipApiResourceFetchAssetRelationships: build.query<
        RelationshipApiResourceFetchAssetRelationshipsApiResponse,
        RelationshipApiResourceFetchAssetRelationshipsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread/graph/asset`,
          params: {
            asset: queryArg.asset,
            descending: queryArg.descending,
            pageNumber: queryArg.pageNumber,
            pageSize: queryArg.pageSize,
            parent: queryArg.parent,
            sortBy: queryArg.sortBy,
            uuids: queryArg.uuids,
          },
        }),
        providesTags: ["Thread Graph"],
      }),
      relationshipApiResourceCountRelationshipThreads: build.query<
        RelationshipApiResourceCountRelationshipThreadsApiResponse,
        RelationshipApiResourceCountRelationshipThreadsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread/graph/count/${queryArg.uuid}`,
        }),
        providesTags: ["Thread Graph"],
      }),
      relationshipApiResourceFetchGraphAssets: build.query<
        RelationshipApiResourceFetchGraphAssetsApiResponse,
        RelationshipApiResourceFetchGraphAssetsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread/graph/find`,
          params: {
            asset: queryArg.asset,
            descending: queryArg.descending,
            pageNumber: queryArg.pageNumber,
            pageSize: queryArg.pageSize,
            parent: queryArg.parent,
            sortBy: queryArg.sortBy,
            uuids: queryArg.uuids,
          },
        }),
        providesTags: ["Thread Graph"],
      }),
      relationshipApiResourceFromTo: build.query<
        RelationshipApiResourceFromToApiResponse,
        RelationshipApiResourceFromToApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread/graph/from/${queryArg.aUuid}/to/${queryArg.anotherUuid}`,
        }),
        providesTags: ["Thread Graph"],
      }),
      relationshipApiResourceThreadGraph: build.query<
        RelationshipApiResourceThreadGraphApiResponse,
        RelationshipApiResourceThreadGraphApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread/graph/${queryArg.threadUuid}`,
        }),
        providesTags: ["Thread Graph"],
      }),
      relationshipApiResourceUpdate: build.mutation<
        RelationshipApiResourceUpdateApiResponse,
        RelationshipApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread/graph/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.relationshipMutationRequest,
        }),
        invalidatesTags: ["Thread Graph"],
      }),
      threadsApiResourceCreate: build.mutation<
        ThreadsApiResourceCreateApiResponse,
        ThreadsApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread/relation`,
          method: "POST",
          body: queryArg.threadsMutationRequest,
        }),
        invalidatesTags: [
          "Thread Relations",
          "Thread Graph",
          "Thread",
          "Relationship",
        ],
      }),
      threadsApiResourceDelete: build.mutation<
        ThreadsApiResourceDeleteApiResponse,
        ThreadsApiResourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread/relation`,
          method: "DELETE",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: [
          "Thread Relations",
          "Thread Graph",
          "Thread",
          "Relationship",
        ],
      }),
      threadsApiResourceFetchAll: build.query<
        ThreadsApiResourceFetchAllApiResponse,
        ThreadsApiResourceFetchAllApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread/relation/some`,
          params: { uuids: queryArg.uuids },
        }),
        providesTags: [
          "Thread Relations",
          "Thread Graph",
          "Thread",
          "Relationship",
        ],
      }),
      threadsApiResourceFromTo: build.query<
        ThreadsApiResourceFromToApiResponse,
        ThreadsApiResourceFromToApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread/relation/th/${queryArg.threadUuid}/from/${queryArg.aUuid}/to/${queryArg.anotherUuid}`,
        }),
        providesTags: [
          "Thread Relations",
          "Thread Graph",
          "Thread",
          "Relationship",
        ],
      }),
      threadsApiResourceFetch: build.query<
        ThreadsApiResourceFetchApiResponse,
        ThreadsApiResourceFetchApiArg
      >({
        query: (queryArg) => ({ url: `/v1/thread/relation/${queryArg.uuid}` }),
        providesTags: [
          "Thread Relations",
          "Thread Graph",
          "Thread",
          "Relationship",
        ],
      }),
      threadsApiResourceUpdate: build.mutation<
        ThreadsApiResourceUpdateApiResponse,
        ThreadsApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread/relation/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.threadsMutationRequest,
        }),
        invalidatesTags: [
          "Thread Relations",
          "Thread Graph",
          "Thread",
          "Relationship",
        ],
      }),
      threadApiResourceFetchAll: build.query<
        ThreadApiResourceFetchAllApiResponse,
        ThreadApiResourceFetchAllApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread/some`,
          params: { uuids: queryArg.uuids },
        }),
        providesTags: ["Thread", "Search"],
      }),
      threadApiResourceFetch: build.query<
        ThreadApiResourceFetchApiResponse,
        ThreadApiResourceFetchApiArg
      >({
        query: (queryArg) => ({ url: `/v1/thread/${queryArg.uuid}` }),
        providesTags: ["Thread", "Search"],
      }),
      threadApiResourceUpdate: build.mutation<
        ThreadApiResourceUpdateApiResponse,
        ThreadApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/thread/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.threadMutationRequest,
        }),
        invalidatesTags: ["Thread", "Search"],
      }),
      versionApiResourceGetVersion: build.query<
        VersionApiResourceGetVersionApiResponse,
        VersionApiResourceGetVersionApiArg
      >({
        query: () => ({ url: `/v1/version` }),
        providesTags: ["Versioning"],
      }),
      webContentApiResourceFetch: build.query<
        WebContentApiResourceFetchApiResponse,
        WebContentApiResourceFetchApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/web/content/${queryArg.id}`,
          params: { locale: queryArg.locale, populate: queryArg.populate },
        }),
        providesTags: ["Web Contents"],
      }),
      webLinkApiResourceCreate: build.mutation<
        WebLinkApiResourceCreateApiResponse,
        WebLinkApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/web/link`,
          method: "POST",
          body: queryArg.webLinkMutationRequest,
        }),
        invalidatesTags: ["Web Link", "Search", "Thread Graph"],
      }),
      webLinkApiResourceDelete: build.mutation<
        WebLinkApiResourceDeleteApiResponse,
        WebLinkApiResourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/web/link`,
          method: "DELETE",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["Web Link", "Search", "Thread Graph"],
      }),
      webLinkApiResourceFetchAll: build.query<
        WebLinkApiResourceFetchAllApiResponse,
        WebLinkApiResourceFetchAllApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/web/link/some`,
          params: { uuids: queryArg.uuids },
        }),
        providesTags: ["Web Link", "Search", "Thread Graph"],
      }),
      webLinkApiResourceFindByUrl: build.query<
        WebLinkApiResourceFindByUrlApiResponse,
        WebLinkApiResourceFindByUrlApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/web/link/url`,
          params: { url: queryArg.url },
        }),
        providesTags: ["Web Link", "Search", "Thread Graph"],
      }),
      webLinkApiResourceFetch: build.query<
        WebLinkApiResourceFetchApiResponse,
        WebLinkApiResourceFetchApiArg
      >({
        query: (queryArg) => ({ url: `/v1/web/link/${queryArg.uuid}` }),
        providesTags: ["Web Link", "Search", "Thread Graph"],
      }),
      webLinkApiResourceUpdate: build.mutation<
        WebLinkApiResourceUpdateApiResponse,
        WebLinkApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/web/link/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.webLinkMutationRequest,
        }),
        invalidatesTags: ["Web Link", "Search", "Thread Graph"],
      }),
      webSiteApiResourceCreate: build.mutation<
        WebSiteApiResourceCreateApiResponse,
        WebSiteApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/web/site`,
          method: "POST",
          body: queryArg.webSiteMutationRequest,
        }),
        invalidatesTags: ["Web Site", "Search", "Thread Graph"],
      }),
      webSiteApiResourceDelete: build.mutation<
        WebSiteApiResourceDeleteApiResponse,
        WebSiteApiResourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/web/site`,
          method: "DELETE",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["Web Site", "Search", "Thread Graph"],
      }),
      webSiteApiResourceFetchAll: build.query<
        WebSiteApiResourceFetchAllApiResponse,
        WebSiteApiResourceFetchAllApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/web/site/some`,
          params: { uuids: queryArg.uuids },
        }),
        providesTags: ["Web Site", "Search", "Thread Graph"],
      }),
      webSiteApiResourceFindByUrl: build.query<
        WebSiteApiResourceFindByUrlApiResponse,
        WebSiteApiResourceFindByUrlApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/web/site/url`,
          params: { url: queryArg.url },
        }),
        providesTags: ["Web Site", "Search", "Thread Graph"],
      }),
      webSiteApiResourceFetch: build.query<
        WebSiteApiResourceFetchApiResponse,
        WebSiteApiResourceFetchApiArg
      >({
        query: (queryArg) => ({ url: `/v1/web/site/${queryArg.uuid}` }),
        providesTags: ["Web Site", "Search", "Thread Graph"],
      }),
      webSiteApiResourceUpdate: build.mutation<
        WebSiteApiResourceUpdateApiResponse,
        WebSiteApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/web/site/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.webSiteMutationRequest,
        }),
        invalidatesTags: ["Web Site", "Search", "Thread Graph"],
      }),
      whatApiResourceCreate: build.mutation<
        WhatApiResourceCreateApiResponse,
        WhatApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/what`,
          method: "POST",
          body: queryArg.whatMutationRequest,
        }),
        invalidatesTags: ["What", "Search", "Thread Graph"],
      }),
      whatApiResourceDelete: build.mutation<
        WhatApiResourceDeleteApiResponse,
        WhatApiResourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/what`,
          method: "DELETE",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["What", "Search", "Thread Graph"],
      }),
      whatApiResourceFetchAll: build.query<
        WhatApiResourceFetchAllApiResponse,
        WhatApiResourceFetchAllApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/what/some`,
          params: { uuids: queryArg.uuids },
        }),
        providesTags: ["What", "Search", "Thread Graph"],
      }),
      whatApiResourceFetch: build.query<
        WhatApiResourceFetchApiResponse,
        WhatApiResourceFetchApiArg
      >({
        query: (queryArg) => ({ url: `/v1/what/${queryArg.uuid}` }),
        providesTags: ["What", "Search", "Thread Graph"],
      }),
      whatApiResourceUpdate: build.mutation<
        WhatApiResourceUpdateApiResponse,
        WhatApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/what/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.whatMutationRequest,
        }),
        invalidatesTags: ["What", "Search", "Thread Graph"],
      }),
      whenApiResourceCreate: build.mutation<
        WhenApiResourceCreateApiResponse,
        WhenApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/when`,
          method: "POST",
          body: queryArg.whenMutationRequest,
        }),
        invalidatesTags: ["When", "Search", "Thread Graph"],
      }),
      whenApiResourceDelete: build.mutation<
        WhenApiResourceDeleteApiResponse,
        WhenApiResourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/when`,
          method: "DELETE",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["When", "Search", "Thread Graph"],
      }),
      whenApiResourceFetchAll: build.query<
        WhenApiResourceFetchAllApiResponse,
        WhenApiResourceFetchAllApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/when/some`,
          params: { uuids: queryArg.uuids },
        }),
        providesTags: ["When", "Search", "Thread Graph"],
      }),
      whenApiResourceFetch: build.query<
        WhenApiResourceFetchApiResponse,
        WhenApiResourceFetchApiArg
      >({
        query: (queryArg) => ({ url: `/v1/when/${queryArg.uuid}` }),
        providesTags: ["When", "Search", "Thread Graph"],
      }),
      whenApiResourceUpdate: build.mutation<
        WhenApiResourceUpdateApiResponse,
        WhenApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/when/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.whenMutationRequest,
        }),
        invalidatesTags: ["When", "Search", "Thread Graph"],
      }),
      whereApiResourceCreate: build.mutation<
        WhereApiResourceCreateApiResponse,
        WhereApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/where`,
          method: "POST",
          body: queryArg.whereMutationRequest,
        }),
        invalidatesTags: ["Where", "Search", "Thread Graph"],
      }),
      whereApiResourceDelete: build.mutation<
        WhereApiResourceDeleteApiResponse,
        WhereApiResourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/where`,
          method: "DELETE",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["Where", "Search", "Thread Graph"],
      }),
      whereApiResourceFetchAll: build.query<
        WhereApiResourceFetchAllApiResponse,
        WhereApiResourceFetchAllApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/where/some`,
          params: { uuids: queryArg.uuids },
        }),
        providesTags: ["Where", "Search", "Thread Graph"],
      }),
      whereApiResourceFetch: build.query<
        WhereApiResourceFetchApiResponse,
        WhereApiResourceFetchApiArg
      >({
        query: (queryArg) => ({ url: `/v1/where/${queryArg.uuid}` }),
        providesTags: ["Where", "Search", "Thread Graph"],
      }),
      whereApiResourceUpdate: build.mutation<
        WhereApiResourceUpdateApiResponse,
        WhereApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/where/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.whereMutationRequest,
        }),
        invalidatesTags: ["Where", "Search", "Thread Graph"],
      }),
      whoApiResourceCreate: build.mutation<
        WhoApiResourceCreateApiResponse,
        WhoApiResourceCreateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/who`,
          method: "POST",
          body: queryArg.whoMutationRequest,
        }),
        invalidatesTags: ["Who", "Search", "Thread Graph"],
      }),
      whoApiResourceDelete: build.mutation<
        WhoApiResourceDeleteApiResponse,
        WhoApiResourceDeleteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/who`,
          method: "DELETE",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["Who", "Search", "Thread Graph"],
      }),
      whoApiResourceFetchAll: build.query<
        WhoApiResourceFetchAllApiResponse,
        WhoApiResourceFetchAllApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/who/some`,
          params: { uuids: queryArg.uuids },
        }),
        providesTags: ["Who", "Search", "Thread Graph"],
      }),
      whoApiResourceFetch: build.query<
        WhoApiResourceFetchApiResponse,
        WhoApiResourceFetchApiArg
      >({
        query: (queryArg) => ({ url: `/v1/who/${queryArg.uuid}` }),
        providesTags: ["Who", "Search", "Thread Graph"],
      }),
      whoApiResourceUpdate: build.mutation<
        WhoApiResourceUpdateApiResponse,
        WhoApiResourceUpdateApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/who/${queryArg.uuid}`,
          method: "PUT",
          body: queryArg.whoMutationRequest,
        }),
        invalidatesTags: ["Who", "Search", "Thread Graph"],
      }),
      accessControlApiResourceApprove: build.mutation<
        AccessControlApiResourceApproveApiResponse,
        AccessControlApiResourceApproveApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/workflow/approve`,
          method: "PATCH",
          body: queryArg.commentedBatchRequest,
          params: { final: queryArg.final },
        }),
        invalidatesTags: ["Asset Workflow", "Search", "Thread Graph"],
      }),
      accessControlApiResourceDeny: build.mutation<
        AccessControlApiResourceDenyApiResponse,
        AccessControlApiResourceDenyApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/workflow/deny`,
          method: "PATCH",
          body: queryArg.commentedBatchRequest,
        }),
        invalidatesTags: ["Asset Workflow", "Search", "Thread Graph"],
      }),
      accessControlApiResourceExpose: build.mutation<
        AccessControlApiResourceExposeApiResponse,
        AccessControlApiResourceExposeApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/workflow/expose`,
          method: "PATCH",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["Asset Workflow", "Search", "Thread Graph"],
      }),
      accessControlApiResourceHide: build.mutation<
        AccessControlApiResourceHideApiResponse,
        AccessControlApiResourceHideApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/workflow/hide`,
          method: "PATCH",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["Asset Workflow", "Search", "Thread Graph"],
      }),
      accessControlApiResourceLock: build.mutation<
        AccessControlApiResourceLockApiResponse,
        AccessControlApiResourceLockApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/workflow/lock`,
          method: "PATCH",
          body: queryArg.commentedBatchRequest,
        }),
        invalidatesTags: ["Asset Workflow", "Search", "Thread Graph"],
      }),
      accessControlApiResourceMarkAsAbuse: build.mutation<
        AccessControlApiResourceMarkAsAbuseApiResponse,
        AccessControlApiResourceMarkAsAbuseApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/workflow/mark-as-abuse`,
          method: "PATCH",
          body: queryArg.commentedBatchRequest,
        }),
        invalidatesTags: ["Asset Workflow", "Search", "Thread Graph"],
      }),
      accessControlApiResourceProtect: build.mutation<
        AccessControlApiResourceProtectApiResponse,
        AccessControlApiResourceProtectApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/workflow/protect`,
          method: "PATCH",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["Asset Workflow", "Search", "Thread Graph"],
      }),
      accessControlApiResourcePublish: build.mutation<
        AccessControlApiResourcePublishApiResponse,
        AccessControlApiResourcePublishApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/workflow/publish`,
          method: "PATCH",
          body: queryArg.commentedBatchRequest,
          params: { final: queryArg.final },
        }),
        invalidatesTags: ["Asset Workflow", "Search", "Thread Graph"],
      }),
      accessControlApiResourceShare: build.mutation<
        AccessControlApiResourceShareApiResponse,
        AccessControlApiResourceShareApiArg
      >({
        query: (queryArg) => ({
          url: `/v1/workflow/share`,
          method: "PATCH",
          body: queryArg.batchRequest,
        }),
        invalidatesTags: ["Asset Workflow", "Search", "Thread Graph"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as API };
export type AnnotationApiResourceCreateApiResponse =
  /** status 200 OK */ Annotation;
export type AnnotationApiResourceCreateApiArg = {
  annotation: Annotation;
};
export type AnnotationApiResourceFindApiResponse =
  /** status 200 OK */ Annotation[];
export type AnnotationApiResourceFindApiArg = {
  limit?: number;
  locale?: string;
  predefined?: boolean;
  query?: string;
  start?: number;
};
export type CollectsApiResourceCreateApiResponse =
  /** status 200 OK */ Collects;
export type CollectsApiResourceCreateApiArg = {
  descending?: boolean;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string;
  uuids?: string[];
  collectsMutationRequest: CollectsMutationRequest;
};
export type CollectsApiResourceDeleteApiResponse = unknown;
export type CollectsApiResourceDeleteApiArg = {
  descending?: boolean;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string;
  uuids?: string[];
  batchRequest: BatchRequest;
};
export type CollectsApiResourceFindApiResponse =
  /** status 200 OK */ PagedResultsAsset;
export type CollectsApiResourceFindApiArg = {
  descending?: boolean;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string;
  uuids?: string[];
  asset?: string;
  parent?: string;
};
export type CollectsApiResourceFromToApiResponse =
  /** status 200 OK */ Collects;
export type CollectsApiResourceFromToApiArg = {
  descending?: boolean;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string;
  uuids?: string[];
  assemblageUuid: string;
  assetUuid: string;
};
export type CollectsApiResourceCollectsApiResponse =
  /** status 200 OK */ Collects;
export type CollectsApiResourceCollectsApiArg = {
  descending?: boolean;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string;
  uuids?: string[];
  assetUuid: string;
  collectionUuid: string;
};
export type CollectsApiResourceUpdateApiResponse =
  /** status 200 OK */ Collects;
export type CollectsApiResourceUpdateApiArg = {
  descending?: boolean;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string;
  uuids?: string[];
  uuid: string;
  collectsMutationRequest: CollectsMutationRequest;
};
export type SearchApiResourceSearchScopesApiResponse = /** status 200 OK */ {
  [key: string]: {
    [key: string]: object;
  };
};
export type SearchApiResourceSearchScopesApiArg = void;
export type SearchApiResourceStatsApiResponse = /** status 200 OK */ {
  [key: string]: number;
};
export type SearchApiResourceStatsApiArg = {
  scope: SearchScope;
};
export type SearchApiResourceSearchApiResponse =
  /** status 200 OK */ PagedAssetResultsAssetEdgeObjectObject;
export type SearchApiResourceSearchApiArg = {
  scope: SearchScope;
  assetType?: string;
  categories?: string[];
  contentTypes?: string[];
  descending?: boolean;
  fromYear?: number;
  grantsWriteAccess?: boolean;
  hosts?: string[];
  keywords?: string[];
  language?: SupportedLanguage;
  latitude?: number;
  longitude?: number;
  maxDistanceInKm?: number;
  occupations?: string[];
  organizations?: string[];
  pageNumber?: number;
  pageSize?: number;
  query?: string;
  sortBy?: string;
  status?: Status[];
  tags?: string[];
  untilYear?: number;
  uuids?: string[];
  visibilities?: Visibility[];
};
export type StagesApiResourceCreateApiResponse = /** status 200 OK */ Stages;
export type StagesApiResourceCreateApiArg = {
  stagesMutationRequest: StagesMutationRequest;
};
export type StagesApiResourceDeleteApiResponse = unknown;
export type StagesApiResourceDeleteApiArg = {
  batchRequest: BatchRequest;
};
export type StagesApiResourceFetchByStartUuidApiResponse =
  /** status 200 OK */ Stages[];
export type StagesApiResourceFetchByStartUuidApiArg = {
  startUuid: string;
};
export type StagesApiResourceUpdateApiResponse = /** status 200 OK */ Stages;
export type StagesApiResourceUpdateApiArg = {
  uuid: string;
  stagesMutationRequest: StagesMutationRequest;
};
export type TranslatesApiResourceCreateApiResponse =
  /** status 200 OK */ Translates;
export type TranslatesApiResourceCreateApiArg = {
  translatesMutationRequest: TranslatesMutationRequest;
};
export type TranslatesApiResourceDeleteApiResponse = unknown;
export type TranslatesApiResourceDeleteApiArg = {
  batchRequest: BatchRequest;
};
export type TranslatesApiResourceUpdateApiResponse =
  /** status 200 OK */ Translates;
export type TranslatesApiResourceUpdateApiArg = {
  uuid: string;
  translatesMutationRequest: TranslatesMutationRequest;
};
export type CustomAttributeApiResourceCreateApiResponse =
  /** status 200 OK */ CustomAttribute;
export type CustomAttributeApiResourceCreateApiArg = {
  customAttributeMutationRequest: CustomAttributeMutationRequest;
};
export type CustomAttributeApiResourceDeleteApiResponse = unknown;
export type CustomAttributeApiResourceDeleteApiArg = {
  batchRequest: BatchRequest;
};
export type CustomAttributeApiResourceFetchAllApiResponse =
  /** status 200 OK */ UserProfile[];
export type CustomAttributeApiResourceFetchAllApiArg = {
  uuids: string[];
};
export type SetsCustomAttributeApiResourceCreateApiResponse =
  /** status 200 OK */ SetsCustomAttribute;
export type SetsCustomAttributeApiResourceCreateApiArg = {
  setsCustomAttributeMutationRequest: SetsCustomAttributeMutationRequest;
};
export type SetsCustomAttributeApiResourceDeleteApiResponse = unknown;
export type SetsCustomAttributeApiResourceDeleteApiArg = {
  batchRequest: BatchRequest;
};
export type SetsCustomAttributeApiResourceUpdateApiResponse =
  /** status 200 OK */ SetsCustomAttribute;
export type SetsCustomAttributeApiResourceUpdateApiArg = {
  uuid: string;
  setsCustomAttributeMutationRequest: SetsCustomAttributeMutationRequest;
};
export type CustomAttributeApiResourceFetchApiResponse =
  /** status 200 OK */ UserProfile;
export type CustomAttributeApiResourceFetchApiArg = {
  uuid: string;
};
export type CustomAttributeApiResourceUpdateApiResponse =
  /** status 200 OK */ CustomAttribute;
export type CustomAttributeApiResourceUpdateApiArg = {
  uuid: string;
  customAttributeMutationRequest: CustomAttributeMutationRequest;
};
export type GrantsApiResourceCreateApiResponse = /** status 200 OK */ Grants;
export type GrantsApiResourceCreateApiArg = {
  grantsMutationRequest: GrantsMutationRequest;
};
export type GrantsApiResourceDeleteApiResponse = unknown;
export type GrantsApiResourceDeleteApiArg = {
  batchRequest: BatchRequest;
};
export type GrantsApiResourceFindApiResponse =
  /** status 200 OK */ PagedResultsGrants;
export type GrantsApiResourceFindApiArg = {
  descending?: boolean;
  pageNumber?: number;
  pageSize?: number;
  sortBy?: string;
  uuids?: string[];
};
export type GrantsApiResourceUpdateApiResponse = /** status 200 OK */ Grants;
export type GrantsApiResourceUpdateApiArg = {
  uuid: string;
  grantsMutationRequest: GrantsMutationRequest;
};
export type ClassificationApiResourceCreateApiResponse =
  /** status 200 OK */ Classification;
export type ClassificationApiResourceCreateApiArg = {
  classification: Classification;
};
export type ClassificationApiResourceFindApiResponse =
  /** status 200 OK */ Classification[];
export type ClassificationApiResourceFindApiArg = {
  asTree?: boolean;
  classifiersOnly?: boolean;
  forDomains?: Domain[];
  limit?: number;
  locale?: string;
  query?: string;
  start?: number;
};
export type CollectionApiResourceCreateApiResponse =
  /** status 200 OK */ Collection;
export type CollectionApiResourceCreateApiArg = {
  collectionMutationRequest: CollectionMutationRequest;
};
export type CollectionApiResourceDeleteApiResponse = unknown;
export type CollectionApiResourceDeleteApiArg = {
  batchRequest: BatchRequest;
};
export type CollectionApiResourceFetchAssetCollectionsApiResponse =
  /** status 200 OK */ PagedResultsCollection;
export type CollectionApiResourceFetchAssetCollectionsApiArg = {
  asset?: string;
  descending?: boolean;
  pageNumber?: number;
  pageSize?: number;
  parent?: string;
  sortBy?: string;
  uuids?: string[];
};
export type CollectionApiResourceFetchAllApiResponse =
  /** status 200 OK */ UserProfile[];
export type CollectionApiResourceFetchAllApiArg = {
  uuids: string[];
};
export type CollectionApiResourceFetchApiResponse =
  /** status 200 OK */ UserProfile;
export type CollectionApiResourceFetchApiArg = {
  uuid: string;
};
export type CollectionApiResourceUpdateApiResponse =
  /** status 200 OK */ Collection;
export type CollectionApiResourceUpdateApiArg = {
  uuid: string;
  collectionMutationRequest: CollectionMutationRequest;
};
export type RepositoryApiResourceUploadApiResponse =
  /** status 200 OK */ WebLink;
export type RepositoryApiResourceUploadApiArg = {
  uploadFile: UploadFile;
};
export type UserProfileApiResourceMeApiResponse =
  /** status 200 OK */ UserProfile;
export type UserProfileApiResourceMeApiArg = void;
export type UserProfileApiResourceUpdateMeApiResponse =
  /** status 200 OK */ UserProfile;
export type UserProfileApiResourceUpdateMeApiArg = {
  userProfileMutationRequest: UserProfileMutationRequest;
};
export type UserProfileApiResourceFetchAllApiResponse =
  /** status 200 OK */ UserProfile[];
export type UserProfileApiResourceFetchAllApiArg = {
  uuids: string[];
};
export type UserProfileApiResourceFetchUserProfileApiResponse =
  /** status 200 OK */ UserProfile;
export type UserProfileApiResourceFetchUserProfileApiArg = {
  uuid: string;
};
export type UserProfileApiResourceUpdateApiResponse =
  /** status 200 OK */ UserProfile;
export type UserProfileApiResourceUpdateApiArg = {
  uuid: string;
  userProfileMutationRequest: UserProfileMutationRequest;
};
export type SuggestionResourceKnotsApiResponse =
  /** status 200 OK */ Suggestions;
export type SuggestionResourceKnotsApiArg = {
  suggestAsset: SuggestAsset;
};
export type SuggestionResourceTermApiResponse =
  /** status 200 OK */ SearchTermSuggestions;
export type SuggestionResourceTermApiArg = {
  suggestSearchTerm: SuggestSearchTerm;
};
export type ThreadApiResourceCreateApiResponse = /** status 200 OK */ Thread;
export type ThreadApiResourceCreateApiArg = {
  threadMutationRequest: ThreadMutationRequest;
};
export type ThreadApiResourceDeleteApiResponse = unknown;
export type ThreadApiResourceDeleteApiArg = {
  batchRequest: BatchRequest;
};
export type ThreadApiResourceAssetThreadsApiResponse =
  /** status 200 OK */ PagedResultsThread;
export type ThreadApiResourceAssetThreadsApiArg = {
  asset?: string;
  descending?: boolean;
  pageNumber?: number;
  pageSize?: number;
  parent?: string;
  sortBy?: string;
  uuids?: string[];
};
export type RelationshipApiResourceCreateApiResponse =
  /** status 200 OK */ Relationship;
export type RelationshipApiResourceCreateApiArg = {
  relationshipMutationRequest: RelationshipMutationRequest;
};
export type RelationshipApiResourceDeleteApiResponse = unknown;
export type RelationshipApiResourceDeleteApiArg = {
  batchRequest: BatchRequest;
};
export type RelationshipApiResourceFetchAssetRelationshipsApiResponse =
  /** status 200 OK */ PagedResultsRelationship;
export type RelationshipApiResourceFetchAssetRelationshipsApiArg = {
  asset?: string;
  descending?: boolean;
  pageNumber?: number;
  pageSize?: number;
  parent?: string;
  sortBy?: string;
  uuids?: string[];
};
export type RelationshipApiResourceCountRelationshipThreadsApiResponse =
  /** status 200 OK */ SimpleNumberResult;
export type RelationshipApiResourceCountRelationshipThreadsApiArg = {
  uuid: string;
};
export type RelationshipApiResourceFetchGraphAssetsApiResponse =
  /** status 200 OK */ PagedResultsAsset;
export type RelationshipApiResourceFetchGraphAssetsApiArg = {
  asset?: string;
  descending?: boolean;
  pageNumber?: number;
  pageSize?: number;
  parent?: string;
  sortBy?: string;
  uuids?: string[];
};
export type RelationshipApiResourceFromToApiResponse =
  /** status 200 OK */ Relationship[];
export type RelationshipApiResourceFromToApiArg = {
  aUuid: string;
  anotherUuid: string;
};
export type RelationshipApiResourceThreadGraphApiResponse =
  /** status 200 OK */ Graph;
export type RelationshipApiResourceThreadGraphApiArg = {
  threadUuid: string;
};
export type RelationshipApiResourceUpdateApiResponse =
  /** status 200 OK */ Relationship;
export type RelationshipApiResourceUpdateApiArg = {
  uuid: string;
  relationshipMutationRequest: RelationshipMutationRequest;
};
export type ThreadsApiResourceCreateApiResponse = /** status 200 OK */ Threads;
export type ThreadsApiResourceCreateApiArg = {
  threadsMutationRequest: ThreadsMutationRequest;
};
export type ThreadsApiResourceDeleteApiResponse = unknown;
export type ThreadsApiResourceDeleteApiArg = {
  batchRequest: BatchRequest;
};
export type ThreadsApiResourceFetchAllApiResponse =
  /** status 200 OK */ Entity[];
export type ThreadsApiResourceFetchAllApiArg = {
  uuids: string[];
};
export type ThreadsApiResourceFromToApiResponse = /** status 200 OK */ Threads;
export type ThreadsApiResourceFromToApiArg = {
  aUuid: string;
  anotherUuid: string;
  threadUuid: string;
};
export type ThreadsApiResourceFetchApiResponse =
  /** status 200 OK */ UserProfile;
export type ThreadsApiResourceFetchApiArg = {
  uuid: string;
};
export type ThreadsApiResourceUpdateApiResponse = /** status 200 OK */ Threads;
export type ThreadsApiResourceUpdateApiArg = {
  uuid: string;
  threadsMutationRequest: ThreadsMutationRequest;
};
export type ThreadApiResourceFetchAllApiResponse =
  /** status 200 OK */ UserProfile[];
export type ThreadApiResourceFetchAllApiArg = {
  uuids: string[];
};
export type ThreadApiResourceFetchApiResponse =
  /** status 200 OK */ UserProfile;
export type ThreadApiResourceFetchApiArg = {
  uuid: string;
};
export type ThreadApiResourceUpdateApiResponse = /** status 200 OK */ Thread;
export type ThreadApiResourceUpdateApiArg = {
  uuid: string;
  threadMutationRequest: ThreadMutationRequest;
};
export type VersionApiResourceGetVersionApiResponse =
  /** status 200 OK */ ApiVersion;
export type VersionApiResourceGetVersionApiArg = void;
export type WebContentApiResourceFetchApiResponse = /** status 200 OK */ {
  [key: string]: object;
};
export type WebContentApiResourceFetchApiArg = {
  id: WebContent;
  locale?: string;
  populate?: string[];
};
export type WebLinkApiResourceCreateApiResponse = /** status 200 OK */ WebLink;
export type WebLinkApiResourceCreateApiArg = {
  webLinkMutationRequest: WebLinkMutationRequest;
};
export type WebLinkApiResourceDeleteApiResponse = unknown;
export type WebLinkApiResourceDeleteApiArg = {
  batchRequest: BatchRequest;
};
export type WebLinkApiResourceFetchAllApiResponse =
  /** status 200 OK */ UserProfile[];
export type WebLinkApiResourceFetchAllApiArg = {
  uuids: string[];
};
export type WebLinkApiResourceFindByUrlApiResponse =
  /** status 200 OK */ WebLink;
export type WebLinkApiResourceFindByUrlApiArg = {
  url?: string;
};
export type WebLinkApiResourceFetchApiResponse =
  /** status 200 OK */ UserProfile;
export type WebLinkApiResourceFetchApiArg = {
  uuid: string;
};
export type WebLinkApiResourceUpdateApiResponse = /** status 200 OK */ WebLink;
export type WebLinkApiResourceUpdateApiArg = {
  uuid: string;
  webLinkMutationRequest: WebLinkMutationRequest;
};
export type WebSiteApiResourceCreateApiResponse = /** status 200 OK */ WebSite;
export type WebSiteApiResourceCreateApiArg = {
  webSiteMutationRequest: WebSiteMutationRequest;
};
export type WebSiteApiResourceDeleteApiResponse = unknown;
export type WebSiteApiResourceDeleteApiArg = {
  batchRequest: BatchRequest;
};
export type WebSiteApiResourceFetchAllApiResponse =
  /** status 200 OK */ UserProfile[];
export type WebSiteApiResourceFetchAllApiArg = {
  uuids: string[];
};
export type WebSiteApiResourceFindByUrlApiResponse =
  /** status 200 OK */ WebSite;
export type WebSiteApiResourceFindByUrlApiArg = {
  url?: string;
};
export type WebSiteApiResourceFetchApiResponse =
  /** status 200 OK */ UserProfile;
export type WebSiteApiResourceFetchApiArg = {
  uuid: string;
};
export type WebSiteApiResourceUpdateApiResponse = /** status 200 OK */ WebSite;
export type WebSiteApiResourceUpdateApiArg = {
  uuid: string;
  webSiteMutationRequest: WebSiteMutationRequest;
};
export type WhatApiResourceCreateApiResponse = /** status 200 OK */ What;
export type WhatApiResourceCreateApiArg = {
  whatMutationRequest: WhatMutationRequest;
};
export type WhatApiResourceDeleteApiResponse = unknown;
export type WhatApiResourceDeleteApiArg = {
  batchRequest: BatchRequest;
};
export type WhatApiResourceFetchAllApiResponse =
  /** status 200 OK */ UserProfile[];
export type WhatApiResourceFetchAllApiArg = {
  uuids: string[];
};
export type WhatApiResourceFetchApiResponse = /** status 200 OK */ UserProfile;
export type WhatApiResourceFetchApiArg = {
  uuid: string;
};
export type WhatApiResourceUpdateApiResponse = /** status 200 OK */ What;
export type WhatApiResourceUpdateApiArg = {
  uuid: string;
  whatMutationRequest: WhatMutationRequest;
};
export type WhenApiResourceCreateApiResponse = /** status 200 OK */ When;
export type WhenApiResourceCreateApiArg = {
  whenMutationRequest: WhenMutationRequest;
};
export type WhenApiResourceDeleteApiResponse = unknown;
export type WhenApiResourceDeleteApiArg = {
  batchRequest: BatchRequest;
};
export type WhenApiResourceFetchAllApiResponse =
  /** status 200 OK */ UserProfile[];
export type WhenApiResourceFetchAllApiArg = {
  uuids: string[];
};
export type WhenApiResourceFetchApiResponse = /** status 200 OK */ UserProfile;
export type WhenApiResourceFetchApiArg = {
  uuid: string;
};
export type WhenApiResourceUpdateApiResponse = /** status 200 OK */ When;
export type WhenApiResourceUpdateApiArg = {
  uuid: string;
  whenMutationRequest: WhenMutationRequest;
};
export type WhereApiResourceCreateApiResponse = /** status 200 OK */ Where;
export type WhereApiResourceCreateApiArg = {
  whereMutationRequest: WhereMutationRequest;
};
export type WhereApiResourceDeleteApiResponse = unknown;
export type WhereApiResourceDeleteApiArg = {
  batchRequest: BatchRequest;
};
export type WhereApiResourceFetchAllApiResponse =
  /** status 200 OK */ UserProfile[];
export type WhereApiResourceFetchAllApiArg = {
  uuids: string[];
};
export type WhereApiResourceFetchApiResponse = /** status 200 OK */ UserProfile;
export type WhereApiResourceFetchApiArg = {
  uuid: string;
};
export type WhereApiResourceUpdateApiResponse = /** status 200 OK */ Where;
export type WhereApiResourceUpdateApiArg = {
  uuid: string;
  whereMutationRequest: WhereMutationRequest;
};
export type WhoApiResourceCreateApiResponse = /** status 200 OK */ Who;
export type WhoApiResourceCreateApiArg = {
  whoMutationRequest: WhoMutationRequest;
};
export type WhoApiResourceDeleteApiResponse = unknown;
export type WhoApiResourceDeleteApiArg = {
  batchRequest: BatchRequest;
};
export type WhoApiResourceFetchAllApiResponse =
  /** status 200 OK */ UserProfile[];
export type WhoApiResourceFetchAllApiArg = {
  uuids: string[];
};
export type WhoApiResourceFetchApiResponse = /** status 200 OK */ UserProfile;
export type WhoApiResourceFetchApiArg = {
  uuid: string;
};
export type WhoApiResourceUpdateApiResponse = /** status 200 OK */ Who;
export type WhoApiResourceUpdateApiArg = {
  uuid: string;
  whoMutationRequest: WhoMutationRequest;
};
export type AccessControlApiResourceApproveApiResponse = unknown;
export type AccessControlApiResourceApproveApiArg = {
  final?: boolean;
  commentedBatchRequest: CommentedBatchRequest;
};
export type AccessControlApiResourceDenyApiResponse = unknown;
export type AccessControlApiResourceDenyApiArg = {
  commentedBatchRequest: CommentedBatchRequest;
};
export type AccessControlApiResourceExposeApiResponse = unknown;
export type AccessControlApiResourceExposeApiArg = {
  batchRequest: BatchRequest;
};
export type AccessControlApiResourceHideApiResponse = unknown;
export type AccessControlApiResourceHideApiArg = {
  batchRequest: BatchRequest;
};
export type AccessControlApiResourceLockApiResponse = unknown;
export type AccessControlApiResourceLockApiArg = {
  commentedBatchRequest: CommentedBatchRequest;
};
export type AccessControlApiResourceMarkAsAbuseApiResponse = unknown;
export type AccessControlApiResourceMarkAsAbuseApiArg = {
  commentedBatchRequest: CommentedBatchRequest;
};
export type AccessControlApiResourceProtectApiResponse = unknown;
export type AccessControlApiResourceProtectApiArg = {
  batchRequest: BatchRequest;
};
export type AccessControlApiResourcePublishApiResponse = unknown;
export type AccessControlApiResourcePublishApiArg = {
  final?: boolean;
  commentedBatchRequest: CommentedBatchRequest;
};
export type AccessControlApiResourceShareApiResponse = unknown;
export type AccessControlApiResourceShareApiArg = {
  batchRequest: BatchRequest;
};
export type Annotation = {
  Slug?: string;
  Value?: string;
};
export type Date = string;
export type Collects = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  ownerUuid?: string;
  startUuid?: string;
  endUuid?: string;
  type?: string;
  x?: number;
  y?: number;
  clustered?: boolean;
};
export type CollectsMutationRequest = {
  uuid?: string;
  version?: number;
  startUuid?: string;
  endUuid?: string;
  x?: number;
  y?: number;
  clustered?: boolean;
  start?: boolean;
};
export type BatchRequest = {
  uuids: string[];
};
export type Translates = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  ownerUuid?: string;
  startUuid?: string;
  endUuid?: string;
  type?: string;
};
export type StageLayout = "COVER_IMAGE" | "BODY";
export type Visibility = "PRIVATE" | "PROTECTED" | "SHARED" | "PUBLIC";
export type Status =
  | "DRAFT"
  | "MARKED_AS_ABUSE"
  | "SEVERED"
  | "PENDING_APPROVAL"
  | "PENDING_FINAL_APPROVAL"
  | "APPROVED"
  | "DENIED"
  | "FINAL";
export type WebSite = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  classifier?: string;
  ownerUuid?: string;
  title?: string;
  description?: string;
  language?: string;
  translates?: Translates;
  stages?: Stages[];
  visibility?: Visibility;
  status?: Status;
  lastStatusChangeComments?: string;
  lastStatusModifiedOn?: Date;
  assetType?: string;
  lastCrawledOn?: Date;
  blacklisted?: boolean;
  host?: string;
  port?: number;
  protocol?: string;
  root?: WebSite;
  url?: string;
  uri?: string;
};
export type MediaType = {
  type?: string;
  subtype?: string;
  parameters?: {
    [key: string]: string;
  };
  wildcardType?: boolean;
  wildcardSubtype?: boolean;
};
export type WebLink = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  classifier?: string;
  ownerUuid?: string;
  title?: string;
  description?: string;
  language?: string;
  translates?: Translates;
  stages?: Stages[];
  visibility?: Visibility;
  status?: Status;
  lastStatusChangeComments?: string;
  lastStatusModifiedOn?: Date;
  assetType?: string;
  lastCrawledOn?: Date;
  blacklisted?: boolean;
  contentTypes?: string[];
  keywords?: string[];
  path?: string;
  query?: string;
  hash?: string;
  iframeAllowed?: boolean;
  repositoryFileUrl?: string;
  since?: Date;
  webSite?: WebSite;
  mediaType?: MediaType;
  url?: string;
};
export type Stages = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  ownerUuid?: string;
  startUuid?: string;
  endUuid?: string;
  type?: string;
  layout?: StageLayout;
  theme?: string;
  styles?: string;
  order?: number;
  webLink?: WebLink;
};
export type Asset = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  classifier?: string;
  ownerUuid?: string;
  title?: string;
  description?: string;
  language?: string;
  translates?: Translates;
  stages?: Stages[];
  visibility?: Visibility;
  status?: Status;
  lastStatusChangeComments?: string;
  lastStatusModifiedOn?: Date;
  assetType?: string;
};
export type PagedResultsAsset = {
  pageNumber?: number;
  pageSize?: number;
  contents?: Asset[];
  total?: number;
  filters?: {
    [key: string]: {
      [key: string]: object;
    };
  };
};
export type SearchScope = "GLOBAL" | "DEFAULT" | "HOME" | "WORKSPACE";
export type EdgeObjectObject = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  ownerUuid?: string;
  startUuid?: string;
  endUuid?: string;
  type?: string;
};
export type PagedResultsEdgeObjectObject = {
  pageNumber?: number;
  pageSize?: number;
  contents?: EdgeObjectObject[];
  total?: number;
  filters?: {
    [key: string]: {
      [key: string]: object;
    };
  };
};
export type PagedAssetResultsAssetEdgeObjectObject = {
  queriedAssets?: Asset[];
  assets?: PagedResultsAsset;
  relationships?: PagedResultsEdgeObjectObject;
};
export type SupportedLanguage = "ENGLISH";
export type StagesMutationRequest = {
  uuid?: string;
  version?: number;
  startUuid?: string;
  endUuid?: string;
  layout?: StageLayout;
  theme?: string;
  styles?: string;
  order?: number;
};
export type TranslatesMutationRequest = {
  uuid?: string;
  version?: number;
  startUuid?: string;
  endUuid?: string;
};
export type CustomAttribute = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  classifier?: string;
  name?: string;
};
export type CustomAttributeMutationRequest = {
  uuid?: string;
  version?: number;
  classifier?: string;
  name?: string;
};
export type UserProfile = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  classifier?: string;
  ownerUuid?: string;
  title?: string;
  description?: string;
  language?: string;
  translates?: Translates;
  stages?: Stages[];
  visibility?: Visibility;
  status?: Status;
  lastStatusChangeComments?: string;
  lastStatusModifiedOn?: Date;
  assetType?: string;
  emailAddress?: string;
  actorUuid?: string;
  occupations?: string[];
  organizations?: string[];
  firstName?: string;
  lastName?: string;
  birthdate?: Date;
  gender?: string;
  preferredContentLanguage?: string;
};
export type SetsCustomAttribute = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  ownerUuid?: string;
  startUuid?: string;
  endUuid?: string;
  type?: string;
  value?: string;
};
export type SetsCustomAttributeMutationRequest = {
  uuid?: string;
  version?: number;
  startUuid?: string;
  endUuid?: string;
  value?: string;
};
export type Grants = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  ownerUuid?: string;
  startUuid?: string;
  endUuid?: string;
  type?: string;
  writeAccess?: boolean;
};
export type GrantsMutationRequest = {
  uuid?: string;
  version?: number;
  startUuid?: string;
  endUuid?: string;
  writeAccess?: boolean;
};
export type PagedResultsGrants = {
  pageNumber?: number;
  pageSize?: number;
  contents?: Grants[];
  total?: number;
  filters?: {
    [key: string]: {
      [key: string]: object;
    };
  };
};
export type Domain =
  | "ALL"
  | "USER_PROFILE"
  | "GROUP_PROFILE"
  | "RELATIONSHIPS"
  | "WHAT"
  | "WHERE"
  | "WHO"
  | "WHEN"
  | "WEBLINK"
  | "WEBSITE"
  | "THREAD"
  | "COLLECTION";
export type Classification = {
  Slug: string;
  Name: string;
  Abstract?: boolean;
  SubclassOf?: Classification;
  Domain: Domain;
};
export type Collection = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  classifier?: string;
  ownerUuid?: string;
  title?: string;
  description?: string;
  language?: string;
  translates?: Translates;
  stages?: Stages[];
  visibility?: Visibility;
  status?: Status;
  lastStatusChangeComments?: string;
  lastStatusModifiedOn?: Date;
  assetType?: string;
};
export type CollectionMutationRequest = {
  uuid?: string;
  version?: number;
  classifier?: string;
  title?: string;
  description?: string;
  language?: string;
};
export type PagedResultsCollection = {
  pageNumber?: number;
  pageSize?: number;
  contents?: Collection[];
  total?: number;
  filters?: {
    [key: string]: {
      [key: string]: object;
    };
  };
};
export type UploadFile = {
  file?: Blob;
};
export type UserProfileMutationRequest = {
  uuid?: string;
  version?: number;
  classifier?: string;
  title?: string;
  description?: string;
  language?: string;
  emailAddress?: string;
  gender?: string;
  preferredContentLanguage?: string;
  firstName?: string;
  lastName?: string;
  birthdate?: Date;
  organizations?: string[];
  occupations?: string[];
};
export type Knot = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  classifier?: string;
  ownerUuid?: string;
  title?: string;
  description?: string;
  language?: string;
  translates?: Translates;
  stages?: Stages[];
  visibility?: Visibility;
  status?: Status;
  lastStatusChangeComments?: string;
  lastStatusModifiedOn?: Date;
  assetType?: string;
  attributes?: SetsCustomAttribute[];
  tags?: string[];
  categories?: string[];
};
export type Suggestions = {
  list?: Knot[];
};
export type SuggestAsset = {
  threadUuid?: string;
  knotUuid?: string;
  maxSuggestions?: number;
};
export type SearchTermSuggestions = {
  list?: string[];
};
export type SuggestSearchTerm = {
  term?: string;
  maxSuggestions?: number;
};
export type Thread = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  classifier?: string;
  ownerUuid?: string;
  title?: string;
  description?: string;
  language?: string;
  translates?: Translates;
  stages?: Stages[];
  visibility?: Visibility;
  status?: Status;
  lastStatusChangeComments?: string;
  lastStatusModifiedOn?: Date;
  assetType?: string;
  startsAt?: string;
};
export type ThreadMutationRequest = {
  uuid?: string;
  version?: number;
  classifier?: string;
  title?: string;
  description?: string;
  language?: string;
  startsAt?: string;
};
export type PagedResultsThread = {
  pageNumber?: number;
  pageSize?: number;
  contents?: Thread[];
  total?: number;
  filters?: {
    [key: string]: {
      [key: string]: object;
    };
  };
};
export type Relationship = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  classifier?: string;
  ownerUuid?: string;
  start?: Asset;
  end?: Asset;
  startUuid?: string;
  endUuid?: string;
};
export type RelationshipMutationRequest = {
  uuid?: string;
  version?: number;
  classifier?: string;
  fromUuid?: string;
  toUuid?: string;
};
export type PagedResultsRelationship = {
  pageNumber?: number;
  pageSize?: number;
  contents?: Relationship[];
  total?: number;
  filters?: {
    [key: string]: {
      [key: string]: object;
    };
  };
};
export type SimpleNumberResult = {
  value?: number;
};
export type NodeRecord = {
  uuid?: string;
  version?: number;
  x?: number;
  y?: number;
  isClustered?: boolean;
  data?: Asset;
  clustered?: boolean;
};
export type EdgeRecord = {
  uuid?: string;
  version?: number;
  relationshipUuid?: string;
  relationshipVersion?: number;
  startUuid?: string;
  endUuid?: string;
  label?: string;
  inverted?: boolean;
};
export type Graph = {
  nodes?: NodeRecord[];
  edges?: EdgeRecord[];
};
export type Threads = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  ownerUuid?: string;
  startUuid?: string;
  endUuid?: string;
  type?: string;
  inverted?: boolean;
};
export type ThreadsMutationRequest = {
  uuid?: string;
  version?: number;
  startUuid?: string;
  endUuid?: string;
  inverted?: boolean;
};
export type Entity = {
  id?: number;
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  new?: boolean;
  visited?: boolean;
};
export type ApiServicesVersion = {
  quarkus?: string;
  neo4j?: string;
  strapi?: string;
  elasticsearch?: string;
  keycloak?: string;
  mysql?: string;
};
export type ApiVersion = {
  env?: string;
  name?: string;
  major?: number;
  minor?: number;
  patch?: number;
  title?: string;
  releaseDate?: Date;
  buildNumber?: string;
  services?: ApiServicesVersion;
};
export type WebContent = "HOME_PAGE" | "COOKIE_CONSENT";
export type WebLinkMutationRequest = {
  uuid?: string;
  version?: number;
  classifier?: string;
  title?: string;
  description?: string;
  language?: string;
  url?: string;
  blacklisted?: boolean;
  uRI?: string;
  contentTypes?: string[];
  keywords?: string[];
  iframeAllowed?: boolean;
  webSiteUuid?: string;
};
export type WebSiteMutationRequest = {
  uuid?: string;
  version?: number;
  classifier?: string;
  title?: string;
  description?: string;
  language?: string;
  url?: string;
  blacklisted?: boolean;
  uRI?: string;
  rootUuid?: string;
};
export type What = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  classifier?: string;
  ownerUuid?: string;
  title?: string;
  description?: string;
  language?: string;
  translates?: Translates;
  stages?: Stages[];
  visibility?: Visibility;
  status?: Status;
  lastStatusChangeComments?: string;
  lastStatusModifiedOn?: Date;
  assetType?: string;
  attributes?: SetsCustomAttribute[];
  tags?: string[];
  categories?: string[];
};
export type WhatMutationRequest = {
  uuid?: string;
  version?: number;
  classifier?: string;
  title?: string;
  description?: string;
  language?: string;
  categories?: string[];
  tags?: string[];
};
export type When = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  classifier?: string;
  ownerUuid?: string;
  title?: string;
  description?: string;
  language?: string;
  translates?: Translates;
  stages?: Stages[];
  visibility?: Visibility;
  status?: Status;
  lastStatusChangeComments?: string;
  lastStatusModifiedOn?: Date;
  assetType?: string;
  attributes?: SetsCustomAttribute[];
  tags?: string[];
  categories?: string[];
  value?: number;
};
export type WhenType =
  | "UNCLASSIFIED"
  | "EPOCH"
  | "PERIOD"
  | "ERA"
  | "EON"
  | "SECOND"
  | "MINUTE"
  | "HOUR"
  | "DAY"
  | "MONTH"
  | "YEAR"
  | "DECADE"
  | "CENTURY"
  | "MILLENNIUM";
export type WhenMutationRequest = {
  uuid?: string;
  version?: number;
  classifier?: string;
  title?: string;
  description?: string;
  language?: string;
  categories?: string[];
  tags?: string[];
  value?: number;
  clearValue?: boolean;
  whenType?: WhenType;
};
export type Where = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  classifier?: string;
  ownerUuid?: string;
  title?: string;
  description?: string;
  language?: string;
  translates?: Translates;
  stages?: Stages[];
  visibility?: Visibility;
  status?: Status;
  lastStatusChangeComments?: string;
  lastStatusModifiedOn?: Date;
  assetType?: string;
  attributes?: SetsCustomAttribute[];
  tags?: string[];
  categories?: string[];
  longitude?: number;
  latitude?: number;
};
export type WhereType =
  | "PLACE"
  | "COORDINATES"
  | "STREET_WITH_NUMBER"
  | "STREET"
  | "ZIP_CODE"
  | "CITY"
  | "STATE"
  | "COUNTRY"
  | "REGION"
  | "CONTINENT";
export type WhereMutationRequest = {
  uuid?: string;
  version?: number;
  classifier?: string;
  title?: string;
  description?: string;
  language?: string;
  categories?: string[];
  tags?: string[];
  latitude?: number;
  longitude?: number;
  clearGeoPosition?: boolean;
  whereType?: WhereType;
};
export type Who = {
  uuid?: string;
  createdOn?: Date;
  lastModifiedOn?: Date;
  version?: number;
  classifier?: string;
  ownerUuid?: string;
  title?: string;
  description?: string;
  language?: string;
  translates?: Translates;
  stages?: Stages[];
  visibility?: Visibility;
  status?: Status;
  lastStatusChangeComments?: string;
  lastStatusModifiedOn?: Date;
  assetType?: string;
  attributes?: SetsCustomAttribute[];
  tags?: string[];
  categories?: string[];
};
export type WhoMutationRequest = {
  uuid?: string;
  version?: number;
  classifier?: string;
  title?: string;
  description?: string;
  language?: string;
  categories?: string[];
  tags?: string[];
};
export type CommentedBatchRequest = {
  uuids: string[];
  comments: string;
};
export const {
  useAnnotationApiResourceCreateMutation,
  useAnnotationApiResourceFindQuery,
  useCollectsApiResourceCreateMutation,
  useCollectsApiResourceDeleteMutation,
  useCollectsApiResourceFindQuery,
  useCollectsApiResourceFromToQuery,
  useCollectsApiResourceCollectsQuery,
  useCollectsApiResourceUpdateMutation,
  useSearchApiResourceSearchScopesQuery,
  useSearchApiResourceStatsQuery,
  useSearchApiResourceSearchQuery,
  useStagesApiResourceCreateMutation,
  useStagesApiResourceDeleteMutation,
  useStagesApiResourceFetchByStartUuidQuery,
  useStagesApiResourceUpdateMutation,
  useTranslatesApiResourceCreateMutation,
  useTranslatesApiResourceDeleteMutation,
  useTranslatesApiResourceUpdateMutation,
  useCustomAttributeApiResourceCreateMutation,
  useCustomAttributeApiResourceDeleteMutation,
  useCustomAttributeApiResourceFetchAllQuery,
  useSetsCustomAttributeApiResourceCreateMutation,
  useSetsCustomAttributeApiResourceDeleteMutation,
  useSetsCustomAttributeApiResourceUpdateMutation,
  useCustomAttributeApiResourceFetchQuery,
  useCustomAttributeApiResourceUpdateMutation,
  useGrantsApiResourceCreateMutation,
  useGrantsApiResourceDeleteMutation,
  useGrantsApiResourceFindQuery,
  useGrantsApiResourceUpdateMutation,
  useClassificationApiResourceCreateMutation,
  useClassificationApiResourceFindQuery,
  useCollectionApiResourceCreateMutation,
  useCollectionApiResourceDeleteMutation,
  useCollectionApiResourceFetchAssetCollectionsQuery,
  useCollectionApiResourceFetchAllQuery,
  useCollectionApiResourceFetchQuery,
  useCollectionApiResourceUpdateMutation,
  useRepositoryApiResourceUploadMutation,
  useUserProfileApiResourceMeQuery,
  useUserProfileApiResourceUpdateMeMutation,
  useUserProfileApiResourceFetchAllQuery,
  useUserProfileApiResourceFetchUserProfileQuery,
  useUserProfileApiResourceUpdateMutation,
  useSuggestionResourceKnotsQuery,
  useSuggestionResourceTermQuery,
  useThreadApiResourceCreateMutation,
  useThreadApiResourceDeleteMutation,
  useThreadApiResourceAssetThreadsQuery,
  useRelationshipApiResourceCreateMutation,
  useRelationshipApiResourceDeleteMutation,
  useRelationshipApiResourceFetchAssetRelationshipsQuery,
  useRelationshipApiResourceCountRelationshipThreadsQuery,
  useRelationshipApiResourceFetchGraphAssetsQuery,
  useRelationshipApiResourceFromToQuery,
  useRelationshipApiResourceThreadGraphQuery,
  useRelationshipApiResourceUpdateMutation,
  useThreadsApiResourceCreateMutation,
  useThreadsApiResourceDeleteMutation,
  useThreadsApiResourceFetchAllQuery,
  useThreadsApiResourceFromToQuery,
  useThreadsApiResourceFetchQuery,
  useThreadsApiResourceUpdateMutation,
  useThreadApiResourceFetchAllQuery,
  useThreadApiResourceFetchQuery,
  useThreadApiResourceUpdateMutation,
  useVersionApiResourceGetVersionQuery,
  useWebContentApiResourceFetchQuery,
  useWebLinkApiResourceCreateMutation,
  useWebLinkApiResourceDeleteMutation,
  useWebLinkApiResourceFetchAllQuery,
  useWebLinkApiResourceFindByUrlQuery,
  useWebLinkApiResourceFetchQuery,
  useWebLinkApiResourceUpdateMutation,
  useWebSiteApiResourceCreateMutation,
  useWebSiteApiResourceDeleteMutation,
  useWebSiteApiResourceFetchAllQuery,
  useWebSiteApiResourceFindByUrlQuery,
  useWebSiteApiResourceFetchQuery,
  useWebSiteApiResourceUpdateMutation,
  useWhatApiResourceCreateMutation,
  useWhatApiResourceDeleteMutation,
  useWhatApiResourceFetchAllQuery,
  useWhatApiResourceFetchQuery,
  useWhatApiResourceUpdateMutation,
  useWhenApiResourceCreateMutation,
  useWhenApiResourceDeleteMutation,
  useWhenApiResourceFetchAllQuery,
  useWhenApiResourceFetchQuery,
  useWhenApiResourceUpdateMutation,
  useWhereApiResourceCreateMutation,
  useWhereApiResourceDeleteMutation,
  useWhereApiResourceFetchAllQuery,
  useWhereApiResourceFetchQuery,
  useWhereApiResourceUpdateMutation,
  useWhoApiResourceCreateMutation,
  useWhoApiResourceDeleteMutation,
  useWhoApiResourceFetchAllQuery,
  useWhoApiResourceFetchQuery,
  useWhoApiResourceUpdateMutation,
  useAccessControlApiResourceApproveMutation,
  useAccessControlApiResourceDenyMutation,
  useAccessControlApiResourceExposeMutation,
  useAccessControlApiResourceHideMutation,
  useAccessControlApiResourceLockMutation,
  useAccessControlApiResourceMarkAsAbuseMutation,
  useAccessControlApiResourceProtectMutation,
  useAccessControlApiResourcePublishMutation,
  useAccessControlApiResourceShareMutation,
} = injectedRtkApi;
