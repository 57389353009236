import * as React from "react";
import { Badge, Center, Container, Flex, Heading, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const Blog: React.FC<any> = (props) => {

  const {t} = useTranslation()

  return (
    <Container>
      <Center boxSize={"100%"}>
        <Flex flexDir={"column"} gap={4} flex={1} bg={"pure-white"} boxShadow={"md"} rounded="base" my={6} p={4}>
          <Heading as={"h1"}>{t("Blog")}<Badge ml={2} colorScheme={"red"} variant={"solid"}>{t("Coming Soon!")}</Badge></Heading>
          <Text>{t("Labirinto is not only a website, it is a philosophy and a new way to deliver knowledge!")}</Text>
          <Text>{t("Our blog will tell the stories about how it's being developed and describe exciting use-cases to present our new digital platform in a more practical way.")}</Text>
          <Text>{t("Come back soon to read, be amused and become part of the Labirinto community!")}</Text>
        </Flex>
      </Center>
    </Container>
  )
}