/**
 * Integration of Labirinto font face KlarheitGrotesk into Chakra-UI.
 *
 * @author Otavio Biasutti, PRODYNA AG
 * @since 2022-07-19
 */
import { Global } from '@emotion/react'

// Definitions to build the font-faces
const formats: string[] = ['eot', 'ttf', 'woff', 'woff2'];
const name: string = "KlarheitGrotesk";
const weights: { [key: number]: string } = {
  200: 'Extralight',
  400: 'Regular',
  500: 'Medium',
  700: 'Bold'
}
const styles: string[] = ['', 'Italic'];

// Result to be calculated through combinations
const fontFaces: string[] = [];

for(const weight in weights) {
  for(const style of styles) {

    const src = [];

    for(const format of formats) {
      src.push(`url('/fonts/${format}/${name}-${weights[weight]}${style}.${format}') format('${format}')`);
    }
//
    fontFaces.push(`
      @font-face {
        font-family: '${name}';
        font-style: ${style ? style.toLowerCase() : 'normal'};
        font-weight: ${weight ? weight : 400};
        font-display: swap;
        src: ${src.join(', ')};
      }
      `);
  }
}

const FontFaces = () => (
  <Global styles={fontFaces.join('')}/>
)

export default FontFaces;