import * as React from "react";
import {Logo} from "./icon/Logo";
import {Flex, Heading, HStack, Link} from "@chakra-ui/react";
import usePermissions from "../hooks/usePermissions";
import {Navigation} from "./Navigation";
import {AssetSearchBar} from "../features/asset/AssetSearchBar";
import {AssetCreator} from "../features/asset/AssetCreator";
import {NavLink} from "react-router-dom";

export const HeaderCanvas: React.FC<any> = ({title}: { title?: string }) => {

   // TODO
   const {isAuthor} = usePermissions();

   return (
         <Flex p={2} width="100%" gap={[2, 2, 6]} bg="primary.500" overflow="hidden" boxShadow={"md"} dropShadow={"xl"}
               alignItems={"center"}>
            <Link as={NavLink} to={"/"}>
               <Logo/>
            </Link>
            <Navigation/>
            <HStack gap={1}>
               <AssetSearchBar/>
               {isAuthor && <AssetCreator/>}
            </HStack>
            <Heading ml="auto" whiteSpace={"nowrap"} noOfLines={1} title={title}
                     fontSize={["xs", "md", "lg", "xl"]}>{title}</Heading>
         </Flex>
   )
}
