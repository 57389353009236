import * as React from "react";
import { chakra, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const DateFormat = (props: { value: number | Date | string | undefined }) => {

  const {i18n} = useTranslation();

  if(!props.value) {
    return <></>;
  }

  const format = new Intl.DateTimeFormat(i18n.language, {month: '2-digit', day: '2-digit', year: 'numeric'}).format;

  return <Text {...props}>{format(new Date(props.value))}</Text>
}


export default chakra(DateFormat);